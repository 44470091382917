import { notification } from 'antd';
import { getZonesApi, updateZonesApi } from '../../services/zonesServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const requestZonesAction = {
  type: ACTION_TYPES.GET_ZONES,
};

export const failGetZonesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ZONES_FAIL,
  };
};

export const successZonesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ZONES_SUCCESS,
    listZones: data
  };
};


export const getListZones = (page_size) => {
  return (dispatch) => {
    dispatch(requestZonesAction);
    const body = {query: `{ zones(page_size: ${page_size}){id name state}}`}
    return getZonesApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successZonesAction(data?.zones));
            return data?.zones;
          } else {
            openNotificationWithIcon('Update Zones Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failGetZonesAction('Call api get zone fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetZonesAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

export const failUpdateZonesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ZONES_FAIL,
    error: data,
  };
};

const requestUpdateExperiencesAction = {
  type: ACTION_TYPES.UPDATE_ZONES,
};

export const successUpdateZonesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ZONES_SUCCESS,
    updateZones: data
  };
};


export const updateZones = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateExperiencesAction);
    const body = {query: `mutation{updateZone(id: ${params.id}, name: "${params.name}", state: "${params.state}") {id, name state}}`};
    updateZonesApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successUpdateZonesAction(data?.updateZone));
          } else {
            openNotificationWithIcon('Update Zones Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateZonesAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateZonesAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};