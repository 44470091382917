import { ACTION_TYPES } from './reducer';
import { notification } from 'antd';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../../common/constant';
import { getCommentsAPI, postCommentAPI, getExperienceAttendanceApi, changeRatingApi } from '../../../services/experiencesServices';

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const openNotificationWithIcon = (description, title, type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetCommentsAction = {
  type: ACTION_TYPES.GET_COMMENTS,
};

export const failGetCommentAction = (data) => {
  return {
    type: ACTION_TYPES.GET_COMMENTS_FAIL,
    error: data,
  };
};

export const successGetCommentAction = (data) => {
  return {
    type: ACTION_TYPES.GET_COMMENTS_SUCCESS,
    comments: data,
  };
};

const requestAddComment = {
  type: ACTION_TYPES.POST_COMMENT,
};

export const successPostCommentAction = (data) => {
  return {
    type: ACTION_TYPES.POST_COMMENT_SUCCESS,
    data: data,
  };
};

export const failPostCommentAction = (data) => {
  return {
    type: ACTION_TYPES.GET_COMMENTS_FAIL,
    error: data,
  };
};

export const getComments = (userID) => {
  return (dispatch) => {
    dispatch(requestGetCommentsAction);
    getCommentsAPI(userID).then(
      (dataJson) => {
        const data = dataJson.data;
        dispatch(successGetCommentAction(data?.experience_comments));
      },
      () => notifyFail(failGetCommentAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export const addComment = (params) => {
  return (dispatch) => {
    dispatch(requestAddComment);
    let body = {
      query: `mutation{addExperienceComment(user_id: ${params.user_id}, experience_id: ${params.experience_id}, comment: "${params.comment}") {id, comment, experience_id}}`,
    };
    postCommentAPI(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successPostCommentAction(data));
        }
      },
      () => {
        notifyFail(failPostCommentAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestGetExperienceAttendance = {
  type: ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE,
};

export const failGetExperienceAttendanceAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE_FAIL,
  };
};

export const successGetExperienceAttendanceAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE_SUCCESS,
    items: data,
  };
};

export const getExperienceAttendance = (exId, proId)=> {
  return (dispatch) => {
    dispatch(requestGetExperienceAttendance);
    let body = `?query={ experience_attendance(experience_id: ${exId})}`;
    if (proId) {
      body = `?query={ experience_attendance(experience_id: ${exId}, program_id: ${proId})}`;
    }
    return getExperienceAttendanceApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          const items = data?.experience_attendance?.items;
          dispatch(successGetExperienceAttendanceAction(items));
          return items;
        } else {
          openNotificationWithIcon('Get experience attendance Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetExperienceAttendanceAction('Get experience attendance Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetExperienceAttendanceAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestChangeRating = {
  type: ACTION_TYPES.CHANGE_RATING,
};

export const failChangeRatingAction = (data) => {
  return {
    type: ACTION_TYPES.CHANGE_RATING_FAIL,
  };
};

export const successChangeRatingAction = (data) => {
  return {
    type: ACTION_TYPES.CHANGE_RATING_SUCCESS,
    items: data,
  };
};

export const changeRating = (userId, exId, rating)=> {
  return (dispatch) => {
    dispatch(requestChangeRating);
    let body = {
      query: `mutation { addExperienceComment(user_id: ${userId}, experience_id: ${exId}, rating: ${rating}) {id, comment}}`
    };
    return changeRatingApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successChangeRatingAction(data));
          return data;
        } else {
          openNotificationWithIcon('Change Rating Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failChangeRatingAction('Change Rating Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failChangeRatingAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};