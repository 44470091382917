import { notification } from 'antd';
import { getPlansApi, updatePlansApi, duplicatePlanApi, importDataApi, getSeasonalTemplateApi, generatePDFFlyerApi, previewPDFFlyerApi, publishPDFFlyerApi} from '../../services/planServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetPlansAction = {
  type: ACTION_TYPES.GET_PLANS,
};

export const failGetPlansAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLANS_FAIL,
    error: data,
  };
};

export const successGetPlansAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLANS_SUCCESS,
    listPlan: data,
  };
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

export const getListPlan = () => {
  return (dispatch) => {
    dispatch(requestGetPlansAction);
    const body = {query: `{plans{id name status start_date end_date created_date modified_date css_hourly_rate fee_ref is_importing_fully_booked is_importing_est_number}}`}
    return getPlansApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetPlansAction(data.plans));
          } else {
            notifyFail(failGetPlansAction('Get Plans Fail'), dispatch);
          }
        },
        () => notifyFail(failGetPlansAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestUpdatePlansAction = {
  type: ACTION_TYPES.UPDATE_PLAN,
};

export const failUpdatePlansAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PLAN_FAIL,
  };
};

export const successUpdatePlansAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PLAN_SUCCESS,
    updatePlan: data
  };
};

export const updatePlan = (params) => {
  return (dispatch) => {
    dispatch(requestUpdatePlansAction);
    const body = {query: `mutation{updatePlan(id: "${params.id}", name: "${params.name}", status: ${params.status}) {id name status start_date end_date created_date modified_date}}`}
    return updatePlansApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Update Plan Success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successUpdatePlansAction(data.updatePlan));
            return data;
          } else {
            notifyFail(failUpdatePlansAction('Get Plans Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdatePlansAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};


const requestDuplicatePlanAction = {
  type: ACTION_TYPES.DUPLICATE_PLAN,
};

export const failDuplicatePlanAction = (data) => {
  return {
    type: ACTION_TYPES.DUPLICATE_PLAN_FAIL,
  };
};

export const successDuplicatePlanAction = () => {
  return {
    type: ACTION_TYPES.DUPLICATE_PLAN_SUCCESS,
  };
};

export const duplicatePlan = (props, params) => {
  return (dispatch) => {
    dispatch(requestDuplicatePlanAction);
    const body = {query: `mutation{duplicatePlanV2(plan_id: ${params.id}, name: "${params.planName}", start_date: "${params.startDate}", end_date: "${params.endDate}", css_hourly_rate: ${params.cssHourlyRate}) {id, name, status}}`}
    return duplicatePlanApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Duplicate Plan Success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successDuplicatePlanAction(data.updatePlan));
            window.location.reload();
          } else {
            notifyFail(failDuplicatePlanAction('Duplicate Plan Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failDuplicatePlanAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestImportDataAction = {
  type: ACTION_TYPES.IMPORT_DATA,
};

export const failImportDataAction = (data) => {
  return {
    type: ACTION_TYPES.IMPORT_DATA_FAIL,
  };
};

export const successImportDataAction = () => {
  return {
    type: ACTION_TYPES.IMPORT_DATA_SUCCESS,
  };
};

export const importMasterData = () => {
  return (dispatch) => {
    dispatch(requestImportDataAction);
    const body = '?query={sync_data}'
    return importDataApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Master data import success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successImportDataAction(data.updatePlan));
            return dataJson.success;
          } else {
            notifyFail(failImportDataAction('Import Data Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failImportDataAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestGetSeasonalTemplateAction = {
  type: ACTION_TYPES.GET_SEASONAL_TEMPLATE,
};

export const failGetSeasonalTemplateAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SEASONAL_TEMPLATE_FAIL,
    error: data,
  };
};

export const successGetSeasonalTemplateAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SEASONAL_TEMPLATE_SUCCESS,
    template: data,
  };
};

export const getSeasonalTemplate = () => {
  return (dispatch) => {
    dispatch(requestGetSeasonalTemplateAction);
    const body = {query: `{seasonal_template}`}
    return getSeasonalTemplateApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetSeasonalTemplateAction(data.seasonal_template && data.seasonal_template[0]));
          } else {
            notifyFail(failGetSeasonalTemplateAction('Get Seasonal Template Fail'), dispatch);
          }
        },
        () => notifyFail(failGetSeasonalTemplateAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestGeneratePDFFlyerAction = {
  type: ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF,
};

export const failGeneratePDFFlyerAction = (data) => {
  return {
    type: ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF_FAIL,
    error: data,
  };
};

export const successGeneratePDFFlyerAction = (url) => {
  return {
    type: ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF_SUCCESS,
    previewUrl: url || ''
  };
};

export const generatePDFFlyer = (planId, programId = [], action = 1) => {
  return (dispatch) => {    
    dispatch(requestGeneratePDFFlyerAction);
    if (programId.includes(0)) {
      programId = []
    }
    const body = {query: `{generate_flyers_pdf(plan_id: ${planId}, program_id: [${programId}], action: ${action})}`}
    if (action == 2){
      openNotificationWithIcon('Generate Flyer PDF process is still working. Please wait in few mins to complete', 'Notification', ALERT_TYPE.SUCCESS);
    }
    return generatePDFFlyerApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            if (action == 2){
              openNotificationWithIcon('Generate Flyer PDF Success', 'Notification', ALERT_TYPE.SUCCESS);
            }
            if (action == 1){
              dispatch(successGeneratePDFFlyerAction(data.generate_flyers_pdf && data.generate_flyers_pdf.path));
              if((!data.generate_flyers_pdf || !data.generate_flyers_pdf.path)){
                openNotificationWithIcon('Something went wrong, please try again later.', 'Notification', ALERT_TYPE.ERROR);
              }
              return data.generate_flyers_pdf && data.generate_flyers_pdf.path;
            }
          } else {
            notifyFail(failGeneratePDFFlyerAction('Generate Flyer PDF Fail'), dispatch);
          }
        },
        () => notifyFail(failGeneratePDFFlyerAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestPreviewPDFFlyerAction = {
  type: ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF,
};

export const failPreviewPDFFlyerAction = (data) => {
  return {
    type: ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF_FAIL,
    error: data,
  };
};

export const successPreviewPDFFlyerAction = (files) => {
  return {
    type: ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF_SUCCESS,
    pdfFiles: files || []
  };
};

export const previewPDFFlyer = (planId) => {
  return (dispatch) => {
    dispatch(requestPreviewPDFFlyerAction);
    const body = {query: `{preview_flyers_pdf(plan_id: ${planId})}`}
    return previewPDFFlyerApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successPreviewPDFFlyerAction(data.preview_flyers_pdf))
            if(data.preview_flyers_pdf && data.preview_flyers_pdf.length == 0){
              openNotificationWithIcon('You have not created a draft flyer PDF or the process of generating flyer PDFs is still ongoing.', 'Notification', ALERT_TYPE.ERROR);
            }
            return data.preview_flyers_pdf
          } else {
            notifyFail(failPreviewPDFFlyerAction('Get Flyer PDF Fail'), dispatch);
          }
        },
        () => notifyFail(failPreviewPDFFlyerAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestPublishPDFFlyerAction = {
  type: ACTION_TYPES.PUBLISH_FLYER_PDF,
};

export const failPublishPDFFlyerAction = (data) => {
  return {
    type: ACTION_TYPES.PUBLISH_FLYER_PDF_FAIL,
    error: data,
  };
};

export const successPublishPDFFlyerAction = (files) => {
  return {
    type: ACTION_TYPES.PUBLISH_FLYER_PDF_SUCCESS
  };
};

export const publishPDFFlyer = (planId) => {
  return (dispatch) => {
    dispatch(requestPublishPDFFlyerAction);
    const body = {query: `{publish_flyers_pdf(plan_id: ${planId})}`}
    return publishPDFFlyerApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Publish Flyer PDF Success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successPublishPDFFlyerAction(data))
            return data;
          } else {
            notifyFail(failPublishPDFFlyerAction('Publish Flyer PDF Fail'), dispatch);
          }
        },
        () => notifyFail(failPublishPDFFlyerAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};