import { notification } from 'antd';
import {
  getTagsApi
} from '../../services/experiencesServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

export function showLoading() {
  return {
    type: ACTION_TYPES.SHOW_LOADING,
  };
}

export function offLoading() {
  return {
    type: ACTION_TYPES.OFF_LOADING,
  };
}

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFail, dispatch) => {
  dispatch(funcFail);
};

export const failGetTagsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_TAGS_FAIL,
    error: data,
  };
};

const requestGetTagsAction = {
  type: ACTION_TYPES.GET_TAGS,
};

export const successTagsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_TAGS_SUCCESS,
    listTags: data
  };
};


export const getTags = () => {
  return (dispatch) => {
    dispatch(requestGetTagsAction);
    return getTagsApi().then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successTagsAction(data?.tags));
            return data;
          } else {
            dispatch(failGetTagsAction('Call api tags fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetTagsAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

export function hideMenu(showMenu) {
  return {
    type: ACTION_TYPES.STATUS_MENU,
    showMenu: showMenu,
  };
}

export function backFromScreen(screen) {
  return {
    type: ACTION_TYPES.BACK_FROM_SCREEN,
    screen: screen,
  };
}

export function saveListFilterExperiences(experiencesShortListFilter) {
  return {
    type: ACTION_TYPES.FILTER_EXPERIENCES_SORT_LIST,
    experiencesShortListFilter: experiencesShortListFilter,
  };
}

export function saveListFilterActivities(activitiesShortListFilter) {
  return {
    type: ACTION_TYPES.FILTER_ACTIVITIES_SORT_LIST,
    activitiesShortListFilter: activitiesShortListFilter,
  };
}

export function addEditFilterPlan(listFilter) {
  return {
    type: ACTION_TYPES.FILTER_PLAN,
    listFilter,
  };
}

