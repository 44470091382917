import {api} from './api';
import Config from '../common/config';

const getUserAdd = `${Config.HOST}?query={ users{id name email group_id}}` ;
const getUserGroups = `${Config.HOST}?query={ userGroups{id name}}`;
const resetPassword = `${Config.HOST}`;


export const getUserAddApi = () => {
  return api.get(getUserAdd);
};

export const getUserGroupsApi = () => {
  return api.get(getUserGroups);
}

export const resetPasswordApi = (params) => {
  return api.postLogin(resetPassword, params);
}

export const removeUsersAddApi = (params) => {
  return api.post(resetPassword, params);
}

export const updateUsersAddApi = (params) => {
  return api.post(resetPassword, params)
}

export const addUserAddApi = (params) => {
  return api.post(resetPassword, params)
}