import _ from 'lodash';

export const ACTION_TYPES = {
  GET_SUPPLIERS: 'GET_SUPPLIERS',
  GET_SUPPLIERS_SUCCESS: 'GET_SUPPLIERS_SUCCESS',
  GET_SUPPLIERS_FAIL: 'GET_SUPPLIERS_FAIL',

  UPDATE_SUPPLIERS: 'UPDATE_SUPPLIERS',
  UPDATE_SUPPLIERS_SUCCESS: 'UPDATE_SUPPLIERS_SUCCESS',
  UPDATE_SUPPLIERS_FAIL: 'UPDATE_SUPPLIERS_FAIL',

  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',
};

const initialState = {
  isLoading: false,
  suppliers: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_SUPPLIERS:
    case ACTION_TYPES.UPDATE_SUPPLIERS:
    case ACTION_TYPES.UPLOAD_IMAGE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPLOAD_IMAGE_SUCCESS:
    case ACTION_TYPES.UPLOAD_IMAGE_FAIL:
    case ACTION_TYPES.UPDATE_SUPPLIERS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_SUPPLIERS_FAIL:
      return {
        ...state,
        isLoading: false,
        suppliers: [],
      };
    case ACTION_TYPES.GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suppliers: action.suppliers,
      };
    case ACTION_TYPES.UPDATE_SUPPLIERS_SUCCESS: 
      const list = _.forEach(state.suppliers, item => {
          if(item.id === action.updateSupplier.id) {
            item.name = action.updateSupplier.name
            item.address = action.updateSupplier.address
            item.phone = action.updateSupplier.phone
            item.email = action.updateSupplier.email
          }
      })
      return {
        ...state,
        isLoading: false,
        suppliers: list,
      };
    default:
      return state;
  }
}
