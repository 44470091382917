import _ from 'lodash';

export const ACTION_TYPES = {
  GET_USER_ADD: 'GET_USER_ADD',
  GET_USER_ADD_SUCCESS: 'GET_USER_ADD_SUCCESS',
  GET_USER_ADD_FAIL: 'GET_USER_ADD_FAIL',

  GET_USER_GROUPS: 'GET_USER_GROUPS',
  GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',
  GET_USER_GROUPS_FAIL: 'GET_USER_GROUPS_FAIL',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',

  REMOVE_USER_ADD: 'REMOVE_USER_ADD',
  REMOVE_USER_ADD_SUCCESS: 'REMOVE_USER_ADD_SUCCESS',
  REMOVE_USER_ADD_FAIL: 'REMOVE_USER_ADD_FAIL',

  UPDATE_USER_ADD: 'UPDATE_USER_ADD',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAIL: 'ADD_USER_FAIL'

};

const initialState = {
  isLoading: false,
  listUserAdd: [],
  listUserGroups: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.ADD_USER:
    case ACTION_TYPES.UPDATE_USER_ADD:
    case ACTION_TYPES.REMOVE_USER_ADD:
    case ACTION_TYPES.RESET_PASSWORD:
    case ACTION_TYPES.GET_USER_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.ADD_USER_FAIL:
    case ACTION_TYPES.UPDATE_USER_FAIL:
    case ACTION_TYPES.REMOVE_USER_ADD_FAIL:
    case ACTION_TYPES.RESET_PASSWORD_FAIL:
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_USER_ADD_FAIL:
      return {
        ...state,
        isLoading: false,
        listUserAdd: [],
      };
    case ACTION_TYPES.GET_USER_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUserAdd: action.listUserAdd,
      };
    case ACTION_TYPES.GET_USER_GROUPS_FAIL:
      return {
        ...state,
        isLoading: false,
        listUserGroups: [],
      };
    case ACTION_TYPES.GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUserGroups: action.listUserGroups,
      };
    case ACTION_TYPES.REMOVE_USER_ADD_SUCCESS:
      const index = _.findIndex(state.listUserAdd, item => {
        return item.id === action.idUserRemove;
      })
      if (index !== -1) {
        state.listUserAdd.splice(index, 1);
      }
      return {
        ...state,
        isLoading: false,
        listUserAdd: state.listUserAdd,
      };
    case ACTION_TYPES.UPDATE_USER_SUCCESS: 
      const list = _.forEach(state.listUserAdd, item => {
          if(item.id === action.dataUpdateRowUser.id) {
            item.name = action.dataUpdateRowUser.name;
            item.email = action.dataUpdateRowUser.email;
          }
      })
      return {
        ...state,
        isLoading: false,
        listUserAdd: list,
      };
    case ACTION_TYPES.ADD_USER_SUCCESS:
      state.listUserAdd.push(action.addUser)
      return {
        ...state,
        isLoading: false,
        listUserAdd: state.listUserAdd
      };
    default:
      return state;
  }
}
