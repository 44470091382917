import _ from 'lodash';
import { notification } from 'antd';
import { exportDataApi, importDataApi, updateSeasonalTemplateApi } from '../../services/importExportServices';
import { ACTION_TYPES } from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE, MENU_ITEMS } from '../../common/constant';

const openNotificationWithIcon = (description, title, type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const requestExportAction = {
  type: ACTION_TYPES.EXPORT_DATA,
};

export const failToExportData = (data) => {
  return {
    type: ACTION_TYPES.EXPORT_DATA_FAIL,
  };
};

export const exportDataSuccess = (title, data) => {
  return {
    type: ACTION_TYPES.EXPORT_DATA_SUCCESS,
    exportData: getDataResponse(title, data),
  };
};

export const getParamsExportData = (title, planId) => {
  switch (title) {
    case MENU_ITEMS.SUPPLIERS:
      return `?query={ supplierExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.ZONES:
      return `?query={ zoneExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.PROGRAMS:
      return `?query={ programExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.EXPERIENCES:
      return `?query={ experienceExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.ACTIVITIES:
      return `?query={ activityExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.ACTIVITY_RESOURCES:
      return `?query={ activityResourceExportCsv { id, filename, mimetype, path }}`;
    case MENU_ITEMS.HOLIDAY_PERIOD_PLAN:
      return `?query={ export_plan(plan_id: ${planId}) { id, filename, mimetype, path }}`;
    case MENU_ITEMS.HOLIDAY_PERIOD_PLAN_V2:
      return `?query={ export_plan_v2(plan_id: ${planId}) { id, filename, mimetype, path }}`;
      case MENU_ITEMS.FULLYBOOKED:
        return `?query={ export_fullybooked(plan_id: ${planId}) { id, filename, mimetype, path }}`;
    default:
      break;
  }
};

export const getDataResponse = (title, data) => {
  switch (title) {
    case MENU_ITEMS.SUPPLIERS:
      return data.supplierExportCsv;
    case MENU_ITEMS.ZONES:
      return data.zoneExportCsv;
    case MENU_ITEMS.PROGRAMS:
      return data.programExportCsv;
    case MENU_ITEMS.EXPERIENCES:
      return data.experienceExportCsv;
    case MENU_ITEMS.ACTIVITIES:
      return data.activityExportCsv;
    case MENU_ITEMS.ACTIVITY_RESOURCES:
      return data.activityResourceExportCsv;
    case MENU_ITEMS.HOLIDAY_PERIOD_PLAN_V2:
      return data.export_plan_v2;
    default:
      break;
  }
};

export const exportData = (title, planId) => {
  return (dispatch) => {
    dispatch(requestExportAction);
    const param = getParamsExportData(title, planId);
    return exportDataApi(param).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson.success) {
          dispatch(exportDataSuccess(title, data));
          return data;
        }
        openNotificationWithIcon('Export data Failed!', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failToImportData(dataJson.errors[0].message), dispatch);
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failToExportData(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

export const getParamsImportData = (title, fileZip, planId) => {
  const formData = new FormData();
  switch (title) {
    case MENU_ITEMS.EXPERIENCES:
      formData.append(
        'operations',
        `{"query": "mutation UploadFile($uploadFile: Upload!){importExperience(upload: $uploadFile) { id, filename, mimetype, path }}"}`
      );
      formData.append('map', `{"0": ["variables.uploadFile"]}`);
      formData.append('0', fileZip.originFileObj);
      return formData;
    case MENU_ITEMS.SUPPLIERS:
      formData.append(
        'operations',
        `{"query": "mutation UploadFile($uploadFile: Upload!){importSupplier(upload: $uploadFile) { id, filename, mimetype, path }}"}`
      );
      formData.append('map', `{"0": ["variables.uploadFile"]}`);
      formData.append('0', fileZip.originFileObj);
      return formData;
    case MENU_ITEMS.ACTIVITIES:
      formData.append(
        'operations',
        `{"query": "mutation UploadFile($uploadFile: Upload!){importActivity(upload: $uploadFile) { id, filename, mimetype, path }}"}`
      );
      formData.append('map', `{"0": ["variables.uploadFile"]}`);
      formData.append('0', fileZip.originFileObj);
      return formData;
    case MENU_ITEMS.ACTIVITY_RESOURCES:
      formData.append(
        'operations',
        `{"query": "mutation UploadFile($uploadFile: Upload!){importActivityResource(upload: $uploadFile) { id, filename, mimetype, path }}"}`
      );
      formData.append('map', `{"0": ["variables.uploadFile"]}`);
      formData.append('0', fileZip.originFileObj);
      return formData;
    case MENU_ITEMS.PROGRAMS:
      formData.append(
        'operations',
        `{"query": "mutation UploadFile($uploadFile: Upload!){importProgram(upload: $uploadFile) { id, filename, mimetype, path }}"}`
      );
      formData.append('map', `{"0": ["variables.uploadFile"]}`);
      formData.append('0', fileZip.originFileObj);
      return formData;
    case MENU_ITEMS.ZONES:
        formData.append(
          'operations',
          `{"query": "mutation UploadFile($uploadFile: Upload!){importZone(upload: $uploadFile) { id, filename, mimetype, path }}"}`
        );
        formData.append('map', `{"0": ["variables.uploadFile"]}`);
        formData.append('0', fileZip.originFileObj);
        return formData;
    case MENU_ITEMS.IMPORT:
        formData.append(
          'operations',
          `{"query": "mutation UploadFile($uploadFile: Upload!){importFullyBooked(plan_id: ${planId}, upload: $uploadFile) { id, filename, mimetype, path }}"}`
        );
        formData.append('map', `{"0": ["variables.uploadFile"]}`);
        formData.append('0', fileZip);
        return formData;
    case MENU_ITEMS.IMPORT_EST_NUMBER:
        formData.append(
          'operations',
          `{"query": "mutation UploadFile($uploadFile: Upload!){importEstimatedNumber(plan_id: ${planId}, upload: $uploadFile) { id, filename, mimetype, path }}"}`
        );
        formData.append('map', `{"0": ["variables.uploadFile"]}`);
        formData.append('0', fileZip);
        return formData;
    case MENU_ITEMS.IMPORT_EXP_INFO:
        formData.append(
          'operations',
          `{"query": "mutation UploadFile($uploadFile: Upload!){importExperienceInfo(plan_id: ${planId}, upload: $uploadFile) { id, filename, mimetype, path }}"}`
        );
        formData.append('map', `{"0": ["variables.uploadFile"]}`);
        formData.append('0', fileZip);
        return formData;
    default:
      break;
  }
};

export const getParamsUpdateTemplate = (data) => {
  const formData = new FormData();
  let query = '';  
  let fields = '';
  let mapping = '';
  let updateImage = '';
  let updateImage2 = '';
  let ind = 0;
  _.forEach(data, (i, n) => {
      if(n.indexOf("_image") != -1 && i[0]?.originFileObj){
        query += `${query ? ',' : ''} $${n}_upload: Upload!`
        mapping += `${mapping ? ',' : ''} "${ind}": ["variables.${n}_upload"]`
        fields += `${n}_upload: $${n}_upload, `
        ind++;
      }
      if(n.indexOf("_image") != -1 && i[0]?.url){
        updateImage += `${n}: "${i[0]?.name}", `
        updateImage2 += `${n}: \\"${i[0]?.name}\\", `
      }
  })
  if(ind == 0){
    return {query: `mutation {updateSeasonalTemplate(${updateImage} header_bg_color: "${data.header_bg_color}", inhouse_bg_color: "${data.inhouse_bg_color}", incursion_bg_color: "${data.incursion_bg_color}", excursion_bg_color: "${data.excursion_bg_color}", theme_1_bg_color: "${data.theme_1_bg_color}", theme_2_bg_color: "${data.theme_2_bg_color}", theme_3_bg_color: "${data.theme_3_bg_color}", footer_bg_color: "${data.footer_bg_color}", sub_footer_bg_color: "${data.sub_footer_bg_color}", header_font_color: "${data.header_font_color}", heading_1_font_color: "${data.heading_1_font_color}", heading_2_font_color: "${data.heading_2_font_color}", heading_3_font_color: "${data.heading_3_font_color}", theme_1_font_color: "${data.theme_1_font_color}", theme_2_font_color: "${data.theme_2_font_color}", theme_3_font_color: "${data.theme_3_font_color}", footer_font_color: "${data.footer_font_color}", sub_footer_font_color: "${data.sub_footer_font_color}", note_section: "${data.note_section}", header_slash_color: "${data.header_slash_color}") { id}}`}
  }
  
  formData.append(
    'operations',
    `{"query": "mutation ${query ? `UploadFile(${query})` : ''}{updateSeasonalTemplate(${fields} ${updateImage2} header_bg_color: \\"${data.header_bg_color}\\", inhouse_bg_color: \\"${data.inhouse_bg_color}\\", incursion_bg_color: \\"${data.incursion_bg_color}\\", excursion_bg_color: \\"${data.excursion_bg_color}\\", theme_1_bg_color: \\"${data.theme_1_bg_color}\\", theme_2_bg_color: \\"${data.theme_2_bg_color}\\", theme_3_bg_color: \\"${data.theme_3_bg_color}\\", footer_bg_color: \\"${data.footer_bg_color}\\", sub_footer_bg_color: \\"${data.sub_footer_bg_color}\\", header_font_color: \\"${data.header_font_color}\\", heading_1_font_color: \\"${data.heading_1_font_color}\\", heading_2_font_color: \\"${data.heading_2_font_color}\\", heading_3_font_color: \\"${data.heading_3_font_color}\\", theme_1_font_color: \\"${data.theme_1_font_color}\\", theme_2_font_color: \\"${data.theme_2_font_color}\\", theme_3_font_color: \\"${data.theme_3_font_color}\\", footer_font_color: \\"${data.footer_font_color}\\", sub_footer_font_color: \\"${data.sub_footer_font_color}\\", note_section: \\"${data.note_section}\\", header_slash_color: \\"${data.header_slash_color}\\") { id}}"}`
  );
  
  if(mapping){
    formData.append('map', `{${mapping}}`);
  }

  ind = 0;
  _.forEach(data, (i, n) => {
    if(n.indexOf("_image") != -1 && i[0]?.originFileObj){
      formData.append(ind, i[0]?.originFileObj)
      ind++
    }
  })

  return formData;
};

const requestImportAction = {
  type: ACTION_TYPES.IMPORT_DATA,
};

export const failToImportData = (data) => {
  return {
    type: ACTION_TYPES.IMPORT_DATA_FAIL,
  };
};

export const importDataSuccess = (title, data) => {
  return {
    type: ACTION_TYPES.IMPORT_DATA_SUCCESS,
  };
};

export const importData = (title, fileZip, planId, importType) => {
  return (dispatch) => {
    dispatch(requestImportAction);
    const param = getParamsImportData(title, fileZip, planId);
    return importDataApi(param).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson.success) {
          openNotificationWithIcon('Update Seasonal Template success', 'Notification', ALERT_TYPE.SUCCESS);
          dispatch(importDataSuccess(title, data));
          return dataJson.success;
        }
        openNotificationWithIcon('Import data Failed!', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failToImportData(dataJson.errors[0].message), dispatch);
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failToImportData(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestUpdateTemplateAction = {
  type: ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE,
};

export const failToUpdateTemplate = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE_FAIL,
  };
};

export const updateTemplateSuccess = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE_SUCCESS,
  };
};

export const updateTemplate = (data) => {
  return (dispatch) => {
    dispatch(requestUpdateTemplateAction);
    const param = getParamsUpdateTemplate(data);
    return updateSeasonalTemplateApi(param).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson.success) {
          openNotificationWithIcon('Update process is still working. Please wait in few mins to complete', 'Notification', ALERT_TYPE.SUCCESS);
          dispatch(updateTemplateSuccess(data));
          return dataJson.success;
        }
        openNotificationWithIcon('Update Seasonal Template Failed!', 'Notification', ALERT_TYPE.ERROR);
        //notifyFail(failToUpdateTemplate(dataJson.errors[0].message), dispatch);
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failToUpdateTemplate(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};