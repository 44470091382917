import React, { Component } from 'react';
import { Layout, Form, Icon, Select, Button, Row, Col, Tabs } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { PLAN_ITEMS_TYPE, ROUTE, SCREEN, PAGE_SIZE, PLAN_ITEM_TYPE } from '../../../common/constant';
import { getPlanItemTypeName } from '../../../common/utils';
import ActivitiesAndExperiencesItem from '../../../components/activityAndExperiencesItem';
import moment from 'moment';
import Filter from '../filter';
import { getListZones } from '../../zones/actions';
import StorageKey from '../../../common/storage/Key.js';
import { saveToShortlist, getPlanDetail, getPlanPrograms, getPlanItems, filterPlanItems, getPlanItemDetail } from './actions';
import { generatePDFFlyer } from '../actions'

import {
  savePlanItems,
  savePlanPrograms,
  saveEditCell,
  savePlanItemType,
  savePlanItemDragDrop,
} from '../addPlan/actions';
import { getListUserAdd } from '../../users/actions';
import { getPrograms } from '../../programs/actions';
import { hideMenu, backFromScreen, showLoading, offLoading, addEditFilterPlan } from '../../appState/actions';
import EditPlanItem from '../editPlanItem';
import ViewPlanItemInfo from '../viewPlanItemInfo'
import LoadingOverlay from 'react-loading-overlay';
import ExperiencesDetail from '../../researchPan/detailExperiencesAndActivities';
import ResearchPanDetailActivities from '../../researchPan';
import SettingPlan from '../../../components/planSettings/index';

import './styles.css';
import { isNumber, filter } from 'lodash';

const { Content, Sider, Header } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = [];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = source;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getListPlanItemStyle = () => ({
  background: 'white',
  width: '90%',
  height: `105px`,
  margin: 'auto',
  border: '2px dashed gray',
  marginTop: '10px',
});

const getItemMenuStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  display: 'flex',
  background: isDragging ? 'white' : 'grey',
  ...draggableStyle,
});

const getListStyle = (listLength, planContentHeight) => ({
  background: 'white',
  width: '90%',
  minHeight: 'calc(100vh - 220px)',
  margin: 'auto',
  border: '2px dashed gray',
  marginTop: '10px',
});

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      isShowPIInfo: false,
      collapsed: true,
      selectedTabs: '1',
      experiences: [],
      activities: [],
      arrayPlanDate: [],
      arrayPrograms: [],
      arrayProgramsTmp: [],
      showPopupAddFilter: false,
      showPopupDetailActivities: false,
      disableExperienceShortlist: false,
      disableActivityShortlist: true,
      itemResearchPan: {},
      itemDetailExperiences: {},
      showDetailExperiences: false,
      isLoading: false,
      filterProgram: localStorage.getItem(StorageKey.FILTERED_ITEMS) ? JSON.parse(localStorage.getItem(StorageKey.FILTERED_ITEMS)) : [],
      filterPlan: localStorage.getItem(StorageKey.FILTERED_ITEMS) ? JSON.parse(localStorage.getItem(StorageKey.FILTERED_ITEMS)) : [],
      showPopupSettings: false,
      editFilter: {},
      pageNum: 1,
      totalItems: 0,
      planContentHeight: 0,
      programNameLeft: '0px',
      programButtonLeft: `${document.documentElement.offsetWidth - 400}px`
    };

    this.handleZoom = this.handleZoom.bind(this);
    this.handleScrollLeft = this.handleScrollLeft.bind(this);
    
    this.scrollRef = React.createRef();
  }
  

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    experiences: 'experiences',
    activities: 'activities',
  };

  getList = (id) => {
    if (id === 'experiences' || id === 'activities') {
      return this.state[this.id2List[id]];
    } else {
      let droppableItem = [];
      this.state.arrayPrograms.some((program, programIndex) => {
        var found = false;
        program.planDates.some((plan, planDateIndex) => {
          if (plan.hasOwnProperty(id)) {
            droppableItem = { programIndex, planDateIndex };
            found = true;
            return true;
          }
          return false;
        });
        if (found) {
          return true;
        }
        return false;
      });
      return this.state.arrayPrograms[droppableItem.programIndex].planDates[droppableItem.planDateIndex][id];
    }
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId !== 'experiences' && destination.droppableId !== 'activities') {
        return;
      }
      const items = reorder(this.getList(source.droppableId), source.index, destination.index);

      let state = { items };

      if (source.droppableId === 'items') {
        state = { items: items };
      } else if (source.droppableId === 'experiences') {
        state = { experiences: items };
      } else if (source.droppableId === 'activities') {
        state = { activities: items };
      }

      this.setState(state);
    } else {
      const droppableSource = source.droppableId;
      const droppableDestination = typeof destination.droppableId === 'string' ? destination.droppableId : destination.droppableId.experience;

      if (
        (droppableSource === 'experiences' && !droppableDestination?.includes('experience')) ||
        (droppableSource === 'activities' && !droppableDestination?.includes('activity'))
      ) {
        return;
      }
      const listSource = this.getList(droppableSource);
      const listDestination = this.getList(droppableDestination);

      if (
        listDestination?.length > 2 &&
        (droppableDestination.includes('experience') || droppableDestination.includes('activity'))
      ) {
        return;
      }
      const result = move(listSource, listDestination, source, destination);
      this.props.showLoading();
      const type = destination.droppableId.split('-')[0];
      const pro = this.state.arrayPrograms.find((program) => {
        return program.planDates.some((item) => item[type] === destination.droppableId);
      });
      const item = pro.planDates.find((pl) => pl[type] === destination.droppableId);

      this.props
        .savePlanItemDragDrop(item.id, result[droppableDestination][0].id, listDestination[0]?.id || null, droppableSource)
        .then((res) => {
          if (!res) {
            this.setState({
              arrayPrograms: this.state.arrayPrograms,
            });
            this.props.offLoading();
            return;
          }
          const arrayPrograms = this.state.arrayPrograms.map((program, index) => {
            program.planDates.forEach((item, i) => {
              if (item.hasOwnProperty(droppableDestination)) {
                item[droppableDestination] = result[droppableDestination];
              }

              if (item[item.experience].length > 0 && item[item.activity].length > 0 && item.id > 0) {
                item.disabledEdit = false;
              }
            });
            return program;
          });
          this.setState({
            arrayPrograms,
          });
          this.props.offLoading();
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      }
    });
  };

  collapseMenu = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    const { appState } = this.props;
    this.props.hideMenu(!appState.showMenu);
    //set position of select Co-Ordinator
    let prLeftBtnPos = Number.parseFloat(this.state.programButtonLeft.replace('px', ''));
    let menuWidth = 365 * (appState.showMenu ? -1 : 1);
    if(isNumber(prLeftBtnPos)){
      this.setState({
        programButtonLeft: `${prLeftBtnPos + menuWidth}px`
      });
    }
  };

  componentDidMount() {
    this.props.showLoading();
    const promiseList = [];
    promiseList.push(this.props.getListUserAdd());
    // promiseList.push(this.props.getPrograms(100, 1));
    promiseList.push(this.props.getPlanDetail(this.props.match?.params?.planId));
    promiseList.push(this.props.getListZones(100));
    if(localStorage.getItem(StorageKey.FILTERED_ITEMS)){
      let filterProgram = JSON.parse(localStorage.getItem(StorageKey.FILTERED_ITEMS));
      const nameSearchFilter = filterProgram.find((item) => item.type === 'name')?.nameSearchFilter;
      const listId = filterProgram.find((item) => item.type === 'zone')?.listId;
      promiseList.push(this.props.filterPlanItems(this.props.match?.params?.planId, nameSearchFilter, listId, 1));
    } else {
      promiseList.push(this.props.getPlanItems(this.props.match?.params?.planId, this.state.pageNum));
    }
    Promise.all(promiseList)
      .then((values) => {
        this.initData(values);
      })
      .catch((reason) => {
        this.props.offLoading();
      });
    window.addEventListener("resize", this.handleZoom);
    window.addEventListener("scroll", this.handleScrollLeft, true);
  }

  previewPDFFlyer = (programId) => {
    this.props.showLoading();
    let planId = this.props.plan?.planInfo?.id
    this.props.generatePDFFlyer(planId, [programId]).then((data) => {
      if(data)
        window.open(data, '_blank', 'noreferrer');
      this.props.offLoading();
    })
  }

  checkShowPreviewFlyerButton = (program) => {
    let items = filter(program?.planDates, item => item.planItemType == 2 || item.planItemType == 3)
    return items.length == 0
  }

  handleScrollLeft = (event) => {
    if(event.target.className === 'ant-layout'){
      let scrollLeft = event.target.scrollLeft;
      if (this.state.programNameLeft != scrollLeft) {
        let maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        this.setState({
          programNameLeft: `${scrollLeft}px`,
          programButtonLeft: `${scrollLeft + maxWidth - 400}px`,
        });
      }
    }
  }


  handleZoom() {
    let scrollableDivWidth = document.getElementById('scrollableDiv').offsetWidth;
    let maxViewHeightWidth = document.documentElement.offsetWidth;
    this.setState({
      planContentHeight: this.state.arrayPrograms && this.state.arrayPrograms.length > 1 ? `${document.documentElement.offsetHeight - 100}px` : 'auto',
      programButtonLeft: `${(maxViewHeightWidth < scrollableDivWidth ? maxViewHeightWidth : scrollableDivWidth) - 400}px`
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleZoom);
    window.removeEventListener("scroll", this.handleScrollLeft);
  }

  initData = (data) => {
    const { plan } = this.props;
    const countDate = moment(plan.planInfo.end_date).diff(moment(plan.planInfo.start_date), 'days');
    let arrayPlanDate = [];
    for (let i = 0; i < countDate + 1; i++) {
      const dayOfWeek = moment(plan.planInfo.start_date).add(i, 'days').format('dddd');
      const objectPlan = {
        planItemType: dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday' ? 0 : 1,
        planItemDate: moment(plan.planInfo.start_date).add(i, 'days').format('DD MMM YYYY'),
        dayOfWeek,
      };
      arrayPlanDate.push(objectPlan);
    }

    const arrayProgramsTmp = data[3];
    for (const items of arrayProgramsTmp.items) {
      for (const item of items.planDates) {
        item.disabledEdit = item[item.activity]?.length && item[item.experience]?.length ? false : true
      }
    }
    const experiences = this.getShortlistByUser()?.experiencesShortlist || [];
    const activities = this.getShortlistByUser()?.activitiesShortlist || [];
    this.setState({
      arrayPrograms: arrayProgramsTmp.items,
      arrayPlanDate,
      experiences,
      activities,
      totalItems: arrayProgramsTmp.total_item,
      planContentHeight: arrayProgramsTmp.items && arrayProgramsTmp.items.length > 1 ? `${document.documentElement.offsetHeight - 100}px` : 'auto'
    });
    this.props.offLoading();
  };

  saveAndExit = () => {
    this.props.showLoading();
    const { shortlistByUser } = this.props.plan;
    const { objectUser } = this.props.userLogin;

    const shortlist = {
      userId: objectUser.id,
      experiencesShortlist: this.getShortlistByUser()?.experiencesShortlist || [],
      activitiesShortlist: this.getShortlistByUser()?.activitiesShortlist || [],
    };
    let list = shortlistByUser !== undefined ? shortlistByUser : [];
    if (list.some((item) => item.userId === shortlist.userId)) {
      list = list.map((item) => {
        if (item.userId === shortlist.userId) {
          item = shortlist;
        }
        return item;
      });
    } else {
      list.push(shortlist);
    }
    this.props.saveToShortlist(list);
    this.props.addEditFilterPlan([]);
    this.props.history.push(ROUTE.LAYOUT + ROUTE.HOLIDAY_PERIOD_PLAN);
  };

  handlePlanItemType = (value, items, planItemIndex) => {
    this.props.showLoading();
    const typeName = getPlanItemTypeName(value);
    this.props.savePlanItemType(typeName, items).then((res) => {
      if (!res) {
        this.setState({
          arrayPrograms: this.state.arrayPrograms,
        });
        this.props.offLoading();
        return;
      }
      const experience = items.experience;
      const activity = items.activity;
      items.planItemType = value;
      items.planItemTypeName = typeName;
      if (value === 5 || value === 1) {
        items[experience] = [];
        items[activity] = [];
      } else {
        items.disabledEdit = true
      }
      this.setState({
        arrayPrograms: this.state.arrayPrograms,
      });
      this.props.offLoading();
    });
  };

  handleOwnerChange = (value, programId, userIdOld) => {
    const paramPlanPrograms = {
      planId: this.props.plan?.planInfo?.id,
      program_id: programId,
      user_id: value,
      user_id_old: userIdOld
    };

    this.props.savePlanPrograms(paramPlanPrograms).then((res) => {
      if (!res) {
        return;
      }
      this.setState({
        arrayPrograms: this.state.arrayPrograms.map((item, index) => {
          if (item.programId === programId) {
            item.userId = value;
          }
          return item;
        }),
      });
    });
  };

  addShortList = () => {
    const { shortlistByUser } = this.props.plan;
    const { objectUser } = this.props.userLogin;
    const shortlist = {
      userId: objectUser.id,
      experiencesShortlist: this.getShortlistByUser()?.experiencesShortlist || [],
      activitiesShortlist: this.getShortlistByUser()?.activitiesShortlist || [],
    };
    let list = shortlistByUser !== undefined ? shortlistByUser : [];
    if (list.some((item) => item.userId === shortlist.userId)) {
      list = list.map((item) => {
        if (item.userId === shortlist.userId) {
          item = shortlist;
        }
        return item;
      });
    } else {
      list.push(shortlist);
    }
    this.props.saveToShortlist(list);
    this.props.addEditFilterPlan([]);
    this.props.history.push(ROUTE.LAYOUT + ROUTE.ADD_SHORT_LIST);
  };

  lockPlanItem = (planItem, planItemIndex) => {
    const experience = planItem.experience;
    const activity = planItem.activity;
    this.setState({
      arrayPrograms: this.state.arrayPrograms.map((item, index) => {
        if (item.programId == planItem.programId) {
          item.planDates[planItemIndex].statusLockPlanItem = !!!+item.planDates[planItemIndex].statusLockPlanItem;
          item.planDates[planItemIndex].disabledEdit = false;
          if (
            item.planDates[planItemIndex].statusLockPlanItem ||
            (item.planDates[planItemIndex][experience].length === 0 &&
              item.planDates[planItemIndex][activity].length === 0)
          ) {
            item.planDates[planItemIndex].disabledEdit = true;
          }
        }
        return item;
      }),
    });
  };

  applyAll = (planItem) => {
    const { arrayPrograms } = this.state;
    const experience = planItem[planItem.experience];
    const activity = planItem[planItem.activity];
    const programs = arrayPrograms.map((item, index) => {
      item.planDates.forEach((cell, cellIndex) => {
        if (experience && experience.length > 0 && experience[0].id == cell[`experience-${index}${cellIndex}`][0]?.id) {
          this.props
            .savePlanItemDragDrop(cell.id, activity[0].id, cell[`activity-${index}${cellIndex}`][0]?.id || null, 'activities')
            .then((res) => {
              if (!res) {
                return;
              }
            });
          cell[`activity-${index}${cellIndex}`] = activity;
        }
      });
      return item;
    });

    this.setState({
      arrayPrograms: programs,
      // arrayProgramsTmp: programsTmp,
    });
  };

  renderPlanItem = (planItem, planItemIndex, programName) => {
    planItem.programName = programName;
    const type = planItem.planItemType;
    switch (type) {
      case 1:
      case 5:
        return (
          <div className="plan-type-item">
            <div className="plan-type-public-closed">
              {type === 1 ? 'Closed' : 'Public holiday'}
              <br />
              There are no experiences or activities planned at this program today
            </div>
          </div>
        );
      case 2:
      case 3:
      case 4:
        return (
          <div className={planItem.statusLockPlanItem ? 'disabled-plan-type-item' : 'plan-type-item'}>
            <Droppable droppableId={planItem.experience} isDropDisabled={!!+planItem.statusLockPlanItem}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListPlanItemStyle()}>
                  {planItem[planItem.experience]?.map((item, index) => (
                    <ActivitiesAndExperiencesItem
                      typeItem="Experiences"
                      key={index}
                      item={item}
                      hideApplyAll={true}
                      closeButton={true}
                      showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                      planItem={planItem}
                      removeItem={(item, planItem) => this.removeItem(item, planItem, "experiences")}
                      planInfo={this.props.plan.planInfo}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId={planItem.activity} isDropDisabled={!!+planItem.statusLockPlanItem}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListPlanItemStyle()}>
                  {planItem[planItem.activity]?.map((item, index) => (
                    <ActivitiesAndExperiencesItem
                      typeItem="Activities"
                      key={index}
                      item={item}
                      hideApplyAll={false}
                      applyAll={() => this.applyAll(planItem)}
                      showPopupResearchPan={() => this.showPopupDetailActivities(item)}
                      planItem={planItem}
                      removeItem={(item, planItem) => this.removeItem(item, planItem, "activities")}
                      planInfo={this.props.plan.planInfo}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Row>
              <Col span={8}>
                <div>
                  <span className="capacity-title">Capacity: {planItem.capacity || ''}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className="capacity-title">Estimated: {planItem.estimated_number || ''}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className='capacity-title'>Actual: {planItem.actual_number || ''}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className='capacity-title'>Start: {planItem.exp_start_time || ''}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className='capacity-title'>Dept: {planItem.bus_departure || ''}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className='capacity-title'>LOR: {planItem.leave_on_return || ''}</span>
                </div>
              </Col>
            </Row>
            <Row className="parent-fee-container">
              <Col style={{ marginTop: 'inherit' }} span={12}>
                {/* <div className={planItem.planItemTypeName === PLAN_ITEM_TYPE.POOL_DAY ? '' : 'hide'}>
                  <span className="capacity-title">Pool time: {planItem.pool_time || ''}</span>
                </div> */}
              </Col>
              <Col style={{ marginR: '5px' }} span={4}>
                <Button
                  className={`${planItem.statusLockPlanItem ? 'disabled-button-unlock' : 'button-unlock'} ${this.props.userLogin.objectUser.group_id === 4 ? 'hide' : 'show'}`}
                  onClick={() => this.lockPlanItem(planItem, planItemIndex)}
                >
                  <Icon
                    type={planItem.statusLockPlanItem ? 'lock' : 'unlock'}
                    style={{ fontSize: 18, color: 'white' }}
                  />
                </Button>
              </Col>
              <Col style={{ width: 'fit-content' }} span={8}>
                <Button
                  className={`button-edit ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || this.props.plan.planInfo?.status === 'Archived' ? 'hide' : 'show'}`}
                  onClick={() => this.editPlanItem(planItem)}
                  disabled={planItem.disabledEdit}
                >
                  <Icon type="edit" /> Edit
                </Button>
                <Button
                  className={`button-edit ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || this.props.plan.planInfo?.status === 'Archived' ? 'show' : 'hide'}`}
                  onClick={() => this.viewPlanItem(planItem)}
                  disabled={planItem.disabledEdit}
                >
                  <Icon type="eye" /> Info
                </Button>
              </Col>
            </Row>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  getShortlistByUser = () => {
    return this.props.plan.shortlistByUser?.find((item) => {
      return item.userId === this.props.userLogin.objectUser.id;
    });
  };

  onChangTabs = (key) => {
    let disabled = true;
    let activities = [];
    let experiences = [];
    if (key === '1') {
      disabled = false;
      experiences = this.getShortlistByUser()?.experiencesShortlist || [];
      activities = [];
    } else {
      activities = this.getShortlistByUser()?.activitiesShortlist || [];
      experiences = [];
    }
    this.setState({
      selectedTabs: key,
      disableExperienceShortlist: disabled,
      disableActivityShortlist: !disabled,
      experiences,
      activities,
    });
  };

  showPopupAddFilter = () => {
    this.setState({ showPopupAddFilter: true, filterPlan: [], editFilter: false });
  };

  updateFilterPlan = (item) => {
    this.setState({ showPopupAddFilter: true, filterPlan: item, editFilter: { type: item.type } });
  };

  hidePopupAddFilter = () => {
    this.setState({ showPopupAddFilter: false });
  };

  showPopupDetailActivities = (item) => {
    this.setState({ showPopupDetailActivities: true, itemResearchPan: item });
  };

  removeItem = (item, planItem, type) => {
    this.props.savePlanItemDragDrop(planItem.id, 0, item.id, type)
      .then((res) => {
        if (!res) {
          return;
        }
        this.setState({
          arrayPrograms: this.state.arrayPrograms.map((item, index) => {
            if (item.programId == planItem.programId) {
              const planItemIndex = item.planDates.findIndex((pl) => pl.id === planItem.id);
              const field = type === 'activities' ? 'activity' : 'experience';
              planItem[planItem] = [];
              item.planDates[planItemIndex][item.planDates[planItemIndex][field]] = [];
              item.planDates[planItemIndex].disabledEdit = true;
            }

            return item;
          }),
        });
      });
  };

  hidePopupResearchPan = () => {
    this.setState({ showPopupDetailActivities: false });
  };

  showPopupDetailExperiences = (item) => {
    this.setState({ showDetailExperiences: true, itemDetailExperiences: item });
  };

  hidePopupDetailExperiences = () => {
    this.setState({ showDetailExperiences: false });
  };

  handleFilterPlan = () => {
    const { appState } = this.props;
    const filterProgram = appState?.filterPlan;
    localStorage.setItem(StorageKey.FILTERED_ITEMS, JSON.stringify(filterProgram));
    const nameSearchFilter = filterProgram.find((item) => item.type === 'name')?.nameSearchFilter;
    const listId = filterProgram.find((item) => item.type === 'zone')?.listId;
    this.props.showLoading();
    this.props.filterPlanItems(this.props.match?.params?.planId, nameSearchFilter, listId, 1).then((data) => {
      if (data) {
        this.scrollRef.current.scrollTop = 0;
        this.scrollRef.current.isScroll = false;
        this.setState({
          filterProgram,
          arrayPrograms: data.items,
          totalItems: data.total_item,
          pageNum: 1
        });
      }
      this.props.offLoading();
    });
  };

  editPlanItem = (item) => {
    const { plan } = this.props;
    const planName = plan?.planInfo?.name;
    const programName = item.programName;
    const planItemDate = moment(item.planItemDate).format('DD-MMM-YYYY');
    this.setState({ isShow: true, editPlanItem: { ...item, planName, planItemDate, programName } });

  };

  viewPlanItem = (item) => {
    const { plan } = this.props;
    const planName = plan?.planInfo?.name;
    const programName = item.programName;
    const planItemDate = moment(item.planItemDate).format('DD-MMM-YYYY');
    this.setState({ isShowPIInfo: true, editPlanItem: { ...item, planName, planItemDate, programName } });
  };

  closeModalEditPlanItem = () => {
    this.setState({ isShow: false });
  };

  closeModalViewPlanItem = () => {
    this.setState({ isShowPIInfo: false });
  };

  saveEditCell = (planItem, cellItem) => {
    cellItem.planItemDate = moment(cellItem.planItemDate).format('YYYY-MM-DD hh:mm:ss');
    this.props.saveEditCell(planItem, cellItem).then((planItemId) => {
      if (!planItemId) {
        return;
      }
      this.setState({
        isShow: false,
        arrayPrograms: this.state.arrayPrograms.map((item, index) => {
          const planDates = item.planDates.map((cell, cellIndex) => {
            if (cell.id === cellItem.id) {
              cell = {
                ...cell,
                id: planItemId,
                pool_time: cellItem.pool_time,
                exp_start_time: cellItem.exp_start_time,
                duration: cellItem.duration,
                reference_no: cellItem.reference_no,
                additional_info: cellItem.additional_info,
                supplier_note: cellItem.supplier_note,
                bus_departure: cellItem.bus_departure,
                travel_time: cellItem.travel_time,
                leave_on_return: cellItem.leave_on_return,
                bus_arrival: cellItem.bus_arrival,
                bus_size: cellItem.bus_size,
                staff_to_kids_ratio: cellItem.staff_to_kids_ratio,
                exp_fee: cellItem.exp_fee,
                activity_fee: cell.activity_fee,
                base_fee: cellItem.base_fee,
                bus_fee: cellItem.bus_fee,
                pool_fee: cellItem.pool_fee,
                parent_fee: cellItem.parent_fee
              };
            }
            return cell;
          });
          item.planDates = planDates;
          return item;
        }),
      });
    });
  };

  showPopupSettings = () => {
    this.setState({ showPopupSettings: true });
  };

  closePopupSettings = () => {
    this.setState({ showPopupSettings: false });
  };

  refreshPage = () => {
    window.location.reload(true);
  };

  nodeRef = (node) => {
    this.widthContent += node?.offsetWidth;
  };
  widthContent = 0;



  fetchMoreData = (scrollTop) => {
    const { appState } = this.props;
    const filterProgram = appState?.filterPlan;

    const nameSearchFilter = filterProgram.find((item) => item.type === 'name')?.nameSearchFilter;
    const listId = filterProgram.find((item) => item.type === 'zone')?.listId;
    const pageNum = this.state.pageNum + 1;
    const total = this.state.totalItems;
    if (pageNum < total / PAGE_SIZE + 1) {
      this.props.showLoading();
      const promiseList = [];
      promiseList.push(this.props.filterPlanItems(this.props.match?.params?.planId, nameSearchFilter, listId, pageNum));
      Promise.all(promiseList)
        .then((values) => {
          this.scrollRef.current.scrollTop = scrollTop;
          if (values.length && values[0].items.length) {
            let newArrayPrograms = this.state.arrayPrograms.concat(values[0].items)
            let filteredArr = newArrayPrograms.reduce((tmp, current) => {
              const x = tmp.find(item => item.programId === current.programId);
              if (!x) {
                return tmp.concat([current]);
              } else {
                return tmp;
              }
            }, []);
            
            this.setState({
              arrayPrograms: filteredArr,
              pageNum,
            });
          }
          this.props.offLoading();
        })
        .catch((reason) => {
          this.props.offLoading();
        });
    }
  };

  onScroll = () => {
    const scrollTop = this.scrollRef.current.scrollTop;
    const scrollHeight = this.scrollRef.current.scrollHeight;
    // console.log(`scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}, offsetHeight: ${document.documentElement.offsetHeight}, condition: ${scrollHeight + 95 - document.documentElement.offsetHeight}`)
    if (!this.scrollRef.current.isScroll && scrollTop >= (scrollHeight + 95 - document.documentElement.offsetHeight)) {
      this.fetchMoreData(scrollTop);
    }
  }

  handleZoneName = (str) => {
    if(!str)
      return str;
    let newString = '';
    for (var i = 0; i < str.length; i++) { 
      newString += str[i] + '<br/>';
    }
    return newString;
  }

  /* eslint-disable */
  render() {
    const {
      editPlanItem,
      arrayPlanDate,
      arrayPrograms,
      showPopupAddFilter,
      experiences,
      activities,
      showPopupDetailActivities,
      disableActivityShortlist,
      disableExperienceShortlist,
      isShow,
      isShowPIInfo,
      itemResearchPan,
      showDetailExperiences,
      itemDetailExperiences,
      selectedTabs,
      filterProgram,
      filterPlan,
      showPopupSettings,
      editFilter,
      planContentHeight
    } = this.state;

    const saveListFilter = filterProgram || [];
    const widthContentPlanProgram = this.widthContent + 40;

    const planProgramWithContent = arrayPlanDate.length > 0 ? widthContentPlanProgram : 'auto';
    // const planContentHeight = arrayPrograms && arrayPrograms.length > 1 ? `${document.documentElement.offsetHeight - 100}px` : 'auto';
    const { userAdd, appState, plan } = this.props;
    const userProgramList = userAdd?.listUserAdd ? userAdd?.listUserAdd.filter((item) => item.group_id === 4) : [];
    userProgramList?.unshift({
      id: 0,
      name: 'select Co-Ordinator',
    });
    return (
      <LoadingOverlay active={appState.isLoading} spinner>
        <Layout style={{ height: '100%' }}>
          {isShow && <EditPlanItem
            planItem={editPlanItem}
            isShow={isShow}
            closeModalEditPlanItem={this.closeModalEditPlanItem}
            saveEditCell={this.saveEditCell}
          />}
          {isShowPIInfo && <ViewPlanItemInfo
            planItem={editPlanItem}
            isShow={isShowPIInfo}
            userLogin={this.props.userLogin}
            closeModalEditPlanItem={this.closeModalViewPlanItem}
            // saveEditCell={this.saveEditCell}
          />}
          <Header style={{ paddingLeft: 18, background: '#ffffff', borderBottom: '1px solid #dddddd', height: '50px' }}>
            <div className="header-create-plan">
              <div className="title-page-create-plan">
                {plan.planInfo?.name} &nbsp;
                <span className="plan-status">{plan.planInfo?.status}</span>
              </div>
              <div className="header-button">
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    <Button className="button-save-and-exit" onClick={this.refreshPage}>
                      <Icon type="reload" /> refresh
                    </Button>
                    &nbsp;
                    <Button className={this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || plan.planInfo?.status === 'Archived' ? 'hide' : 'show'} onClick={this.showPopupSettings}>
                      <Icon type="setting" /> setting
                    </Button>
                    &nbsp;
                    <Button className="button-save-and-exit" onClick={this.saveAndExit}>
                      <Icon type="upload" /> back to list
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </div>
          </Header>
          <div className="plan-status-container">
            <span className="plan-status_date">{plan.planInfo?.start_date}</span>
            <span className="plan-status_date"> to </span>
            <span className="plan-status_date">{plan.planInfo?.end_date}</span>
          </div>
          <Layout style={{ height: '100%' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="slider-on-plan">
                <Sider
                  className={appState?.showMenu ? '' : 'slider-show'}
                  style={{ background: 'white' }}
                  trigger={null}
                  collapsible
                  collapsed={appState?.showMenu}
                >
                  <div className="logo"></div>
                  {appState?.showMenu ? (
                    ''
                  ) : (
                    <div className="card-container">
                      <Tabs type="card" onChange={this.onChangTabs}>
                        <TabPane tab="Experiences" key="1">
                          <Button className="button-save-and-exit" onClick={this.addShortList}>
                            <Icon type="plus" /> Add to short list
                          </Button>
                          <div className="shortlist">Experiences shortlist</div>
                          <div style={{ height: 'calc(100vh - 220px)' }}>
                            <Droppable isDropDisabled={disableExperienceShortlist} droppableId="experiences">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(experiences?.length, planContentHeight)}
                                >
                                  {experiences?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem="Experiences"
                                            typeCss="ItemShortList"
                                            item={item}
                                            hideApplyAll={true}
                                            planInfo={plan.planInfo}
                                            showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </TabPane>
                        <TabPane tab="Activities" key="2">
                          <Button className="button-save-and-exit" onClick={this.addShortList}>
                            <Icon type="plus" /> Add to short list
                          </Button>
                          <div className="shortlist">Activities shortlist</div>
                          <div style={{ height: 'calc(100vh - 220px)' }}>
                            <Droppable isDropDisabled={disableActivityShortlist} droppableId="activities">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(activities?.length, planContentHeight)}
                                >
                                  {activities?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem="Activities"
                                            typeCss="ItemShortList"
                                            showPopupResearchPan={(item) => this.showPopupDetailActivities(item)}
                                            item={item}
                                            hideApplyAll={true}
                                            planInfo={plan.planInfo}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  )}
                </Sider>
              </div>
              <Layout style={{ height: '100%', background: '#2F4F4F', overflow: 'auto', width: '800px' }}>
                <Content style={{ width: planProgramWithContent }}>
                  <div className={`slider-collapse-button ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || plan.planInfo?.status === 'Archived' ? 'hide' : 'show'}`}>
                    <Button style={{ background: '#2F4F4F', border: 'none' }} onClick={this.collapseMenu}>
                      <Icon style={{ color: 'white' }} type="double-right" />
                    </Button>
                  </div>
                  <div className="filter-container">
                    <div className="filter">
                      <div className="filter-underline">
                        <Icon type="filter" style={{ fontSize: 20, color: '#ffffff' }} />
                        <div className="title-filter">
                          <span>Filter</span>
                        </div>
                      </div>
                    </div>
                    <div className="container-add-filter-and-new-filter">
                      {saveListFilter?.map((item) => (
                        <div className="add-filter">
                          <div className="state-filter">
                            <div>
                              <span className="state-title">{item.type}:</span>
                            </div>
                            <div style={{ width: '125px', whiteSpace: 'nowrap' }}>
                              <span className="content-filter ellipsis-text">
                                {item?.type === 'name' ? item?.nameSearchFilter : item?.listName?.join()}
                              </span>
                            </div>
                          </div>
                          <Button type="link" className="icon-filter" onClick={() => this.updateFilterPlan(item)}>
                            <Icon type="form" style={{ fontSize: 20, color: '#ffffff' }} />
                          </Button>
                        </div>
                      ))}
                      {saveListFilter?.length <= 1 && (
                        <Button className="new-add-filter" onClick={this.showPopupAddFilter}>
                          <Icon type="plus" style={{ fontSize: 20, color: '#ffffff' }} />
                          Add Filter
                        </Button>
                      )}
                    </div>
                    <div className="plan-date">
                      {
                        arrayPlanDate && arrayPlanDate[0]?.planItemType !== 0 ? (
                          <div ref={this.nodeRef} className="first-column-date-plan"></div>
                        ) : <div ref={this.nodeRef} span={1}></div>
                      }
                      {arrayPlanDate?.map((item, i) =>
                        item.planItemType !== 0 ? (
                          <div ref={this.nodeRef} key={i} span={6} className="column-date-plan">
                            <div className="text-plan-label">
                              <div>
                                <span className="text-plan-item">{item.dayOfWeek}</span>
                              </div>
                              <span className="text-plan-item">{item.planItemDate}</span>
                            </div>
                          </div>
                        ) : (
                          <div ref={this.nodeRef} key={i} span={1} className="column-date-plan-weekend"></div>
                        )
                      )}
                    </div>
                  </div>
                  <div style={{ height: 'calc(100vh - 280px)'}} className="plan-content-table">
                    <div
                      id="scrollableDiv"
                      style={{
                        height: `${planContentHeight}`,
                        overflow: 'auto',
                      }}
                      ref={this.scrollRef}
                      onScroll={this.onScroll}
                    >
                      {arrayPrograms?.map((program, programIndex) => (
                        <div key={programIndex}>
                          <Row style={{ height: '50px', backgroundColor: program.zoneColor }}>
                            <div 
                              className="programs-header-name"
                              style={{ left: `${this.state.programNameLeft}` }}
                            >
                              {program.programName}
                            </div>
                            <div className="programs-header-button">

                              <Button 
                                style={{ left: `${this.state.programButtonLeft}`, marginRight:10 }} 
                                onClick={() => this.previewPDFFlyer(program.programId)}
                                disabled={this.props.userLogin.objectUser.group_id === 4 || this.checkShowPreviewFlyerButton(program)}
                              >
                                Preview Flyer
                              </Button>
                              <Select
                                value={program.userId}
                                defaultValue={program.userId}
                                style={{ left: `${this.state.programButtonLeft}`, width: 200 }}
                                onChange={(value) => this.handleOwnerChange(value, program.programId, program.userId)}
                                disabled={this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || plan.planInfo?.status === 'Archived'}
                              >
                                {userProgramList?.map((item) => (
                                  <Option key={item.id} value={item.id} label={item.name}>
                                    <div>{item.name}</div>
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </Row>
                          <Row style={{ width: 'auto', display: 'flex' }}>
                            {
                              program && program.planDates && program.planDates[0].planItemType !== 0 ? (<div span={2} className="column-zone" style={{backgroundColor: `${program.zoneColor}`}}> <span dangerouslySetInnerHTML={{ __html: this.handleZoneName(`${program.stateName}-${program.zoneName}`) }} /></div>) : ''
                            }
                            {program?.planDates?.map((items, index) =>
                              items.planItemType !== 0 ? (
                                <div key={index} span={6} className="column-type-plan">
                                  <Select
                                    value={items.planItemType}
                                    defaultValue={items.planItemType}
                                    style={{ marginTop: '10px', width: '90%' }}
                                    onChange={(value) => this.handlePlanItemType(value, items, index)}
                                    disabled={this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || plan.planInfo?.status === 'Archived'}
                                  >
                                    {PLAN_ITEMS_TYPE?.map((item) => (
                                      <Option key={item.id} value={item.id} label={item.name}>
                                        <div>{item.name}</div>
                                      </Option>
                                    ))}
                                  </Select>
                                  {this.renderPlanItem(items, index, program.programName)}
                                </div>
                              ) : (
                                index === 0 || program.planDates[index - 1].planItemType !== 0 ? (
                                  <div key={index} span={2} className="column-zone" style={{backgroundColor: `${program.zoneColor}`}}><span dangerouslySetInnerHTML={{ __html: this.handleZoneName(`${program.stateName}-${program.zoneName}`) }} /></div>
                                ) : ('')
                              )
                            )}
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                  {showPopupAddFilter && (
                    <Filter
                      showPopupAddFilter={showPopupAddFilter}
                      onClosePopUpFilter={this.hidePopupAddFilter}
                      handleFilterPlan={this.handleFilterPlan}
                      selectedTabs={selectedTabs}
                      editFilter={editFilter}
                      updateFilterPlan={filterPlan}
                      screen={SCREEN.ADD_EDIT_PLAN}
                    />
                  )}
                  {showPopupDetailActivities && (
                    <ResearchPanDetailActivities
                      onClosePopUpReSearchPan={this.hidePopupResearchPan}
                      item={itemResearchPan}
                    />
                  )}
                  {showDetailExperiences && (
                    <ExperiencesDetail
                      item={itemDetailExperiences}
                      onClosePopUpDetailExperiencesAndActivities={this.hidePopupDetailExperiences}
                    />
                  )}
                  {showPopupSettings && (
                    <SettingPlan
                      context={this.props}
                      showPopupSettings={showPopupSettings}
                      closePopupSettings={this.closePopupSettings}
                    />
                  )}
                </Content>
              </Layout>
            </DragDropContext>
          </Layout>
        </Layout>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // programs: state.programs,
    userAdd: state.userAdd,
    userLogin: state.userLogin,
    plan: state.plan,
    appState: state.appState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideMenu: (showMenu) => dispatch(hideMenu(showMenu)),
    backFromScreen: (screen) => dispatch(backFromScreen(screen)),
    getPlanDetail: (planId) => dispatch(getPlanDetail(planId)),
    getPlanItems: (planId, pageNum) => dispatch(getPlanItems(planId, pageNum)),
    getPlanPrograms: (planId) => dispatch(getPlanPrograms(planId)),
    getListUserAdd: () => dispatch(getListUserAdd()),
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    saveToShortlist: (body) => dispatch(saveToShortlist(body)),
    addEditFilterPlan: (body) => dispatch(addEditFilterPlan(body)),
    savePlanItems: (props, body) => dispatch(savePlanItems(props, body)),
    savePlanPrograms: (body) => dispatch(savePlanPrograms(body)),
    saveEditCell: (planItem, body) => dispatch(saveEditCell(planItem, body)),
    getPrograms: (page_size, page_index) => dispatch(getPrograms(page_size, page_index)),
    savePlanItemType: (type, item) => dispatch(savePlanItemType(type, item)),
    savePlanItemDragDrop: (itemId, newId, oldId, type) => dispatch(savePlanItemDragDrop(itemId, newId, oldId, type)),
    filterPlanItems: (planId, nameSearch, listId, pageNum) =>
    dispatch(filterPlanItems(planId, nameSearch, listId, pageNum)),
    showLoading: () => dispatch(showLoading()),
    offLoading: () => dispatch(offLoading()),
    getPlanItemDetail: (plan_id, plan_item_id) => dispatch(getPlanItemDetail(plan_id, plan_item_id)),
    generatePDFFlyer: (planId, programId) => dispatch(generatePDFFlyer(planId, programId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
