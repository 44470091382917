import { notification } from 'antd';
import { getSettingsApi, updateSettingsApi } from '../../services/settingService';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const requestSettingsAction = {
  type: ACTION_TYPES.GET_SETTINGS,
};

export const failGetSettingsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SETTINGS_FAIL,
  };
};

export const successSettingsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SETTINGS_SUCCESS,
    listSettings: data
  };
};


export const getListSettings = () => {
  return (dispatch) => {
    dispatch(requestSettingsAction);
    const body = {query: `{ settings{id keyword content}}`}
    return getSettingsApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successSettingsAction(data?.settings));
            return data?.settings;
          } else {
            openNotificationWithIcon('Update Settings Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failGetSettingsAction('Call api get setting fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetSettingsAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

export const failUpdateSettingsAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SETTINGS_FAIL,
    error: data,
  };
};

const requestUpdateExperiencesAction = {
  type: ACTION_TYPES.UPDATE_SETTINGS,
};

export const successUpdateSettingsAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SETTINGS_SUCCESS,
    updateSettings: data
  };
};


export const updateSettings = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateExperiencesAction);
    const body = {query: `mutation{updateSetting(id: ${params.id}, keyword: "${params.keyword}", content: "${params.content}") {id, keyword content}}`};
    updateSettingsApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successUpdateSettingsAction(data?.updateSetting));
          } else {
            openNotificationWithIcon('Update Settings Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateSettingsAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateSettingsAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};