import Config from '../common/config';
import { api } from './api';

const url = `${Config.HOST}`;

export const getListExperiencesApi = (params) => {
  return api.get(url + params);
};

export const updateExperiencesApi = (params) => {
  return api.post(url, params);
};

export const getTagsApi = () => {
  return api.get(url + `?query={ tags{id name}}`);
};

export const uploadImageApi = (params) => {
  return api.postMultiplePart(url, params);
};

export const getCommentsAPI = (experience_id) => {
  return api.get(url + `?query={ experience_comments(experience_id: ${experience_id}){ user{id name email} experience_id comment created_date}}`);
};

export const postCommentAPI = (params) => {
  return api.post(url, params);
};

export const getExperienceAttendanceApi = (params) => {
  return api.get(url + params);
};

export const changeRatingApi = (params) => {
  return api.post(url, params);
};
