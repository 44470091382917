import { notification } from 'antd';
import { loginApi } from '../../services/loginServices';
import { ACTION_TYPES } from './reducer';
import { INTERNAL_SERVER_ERROR } from '../../common/constant';
import StorageKey from '../../common/storage/Key';
import { ROUTE, ALERT_TYPE } from '../../common/constant';

const requestLoginAction = {
  type: ACTION_TYPES.LOGIN_START,
};

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};
export const failLoginAction = (data) => {
  return {
    type: ACTION_TYPES.LOGIN_FAIL,
    error: data,
  };
};

export const successLoginAction = (data) => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
    objectUser: data,
  };
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

export const doLogin = (param) => {
  return (dispatch) => {
    dispatch(requestLoginAction);
    const paramsLogin = {
      query: `mutation{login(email: "${param.email}", password: "${param.password}") {id, email, password, name, access_token, group_id, is_active, is_admin}}`,
    };
    loginApi(paramsLogin).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          localStorage.setItem(StorageKey.TOKEN_KEY, data.login.access_token);
          dispatch(successLoginAction(data?.login));
          window.location.replace(ROUTE.LAYOUT + ROUTE.HOLIDAY_PERIOD_PLAN);
        } else {
          openNotificationWithIcon(dataJson.errors[0].message, 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failLoginAction('Login Fail'), dispatch);
        }
      },
      () => notifyFail(failLoginAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};
