import { ROUTE, MENU_ITEMS } from './common/constant';
import Home from './views/home';
import Login from './views/login';
import Users from './views/users';
import ForgotPassword from './views/forgotPassword';
// import Suppliers from './views/suppliers';
import Zones from './views/zones';
// import Programs from './views/programs';
// import Experiences from './views/experiences';
// import Activities from './views/activities';
// import ActivitiesResources from './views/activitiesResources';
import AddPlan from './views/home/addPlan';
import AddShortList from './views/home/addShortList';
import EditPlan from './views/home/editPlan';
import Settings from './views/settings'

const dashboardRoutes = [
  {
    path: ROUTE.LOGIN,
    name: 'Login',
    isShow: false,
    component: Login,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.LOGIN],
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    name: 'Forgot Password',
    isShow: false,
    component: ForgotPassword,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.FORGOT_PASSWORD],
  },
  {
    path: ROUTE.ADD_SHORT_LIST,
    name: 'Add Short List',
    isShow: false,
    component: AddShortList,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.ADD_SHORT_LIST],
  },
  {
    path: ROUTE.ADD_PLAN + '/:planId',
    name: 'Add Plan',
    isShow: false,
    component: AddPlan,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.ADD_PLAN],
  },
  {
    path: ROUTE.EDIT_PLAN + '/:planId',
    name: 'Edit Plan',
    isShow: false,
    component: EditPlan,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.EDIT_PLAN],
  },
  {
    path: ROUTE.HOLIDAY_PERIOD_PLAN,
    name: MENU_ITEMS.HOLIDAY_PERIOD_PLAN,
    isShow: true,
    component: Home,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.HOLIDAY_PERIOD_PLAN],
  },
  // {
  //   path: ROUTE.SUPPLIERS,
  //   name: MENU_ITEMS.SUPPLIERS,
  //   isShow: true,
  //   component: Suppliers,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.SUPPLIERS],
  // },
  // {
  //   path: ROUTE.EXPERIENCES,
  //   name: MENU_ITEMS.EXPERIENCES,
  //   isShow: true,
  //   component: Experiences,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.EXPERIENCES],
  // },
  // {
  //   path: ROUTE.ACTIVITIES,
  //   name: MENU_ITEMS.ACTIVITIES,
  //   isShow: true,
  //   component: Activities,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.ACTIVITIES],
  // },
  // {
  //   path: ROUTE.ACTIVITY_RESOURCES,
  //   name: MENU_ITEMS.ACTIVITY_RESOURCES,
  //   isShow: true,
  //   component: ActivitiesResources,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.ACTIVITY_RESOURCES],
  // },
  // {
  //   path: ROUTE.PROGRAMS,
  //   name: MENU_ITEMS.PROGRAMS,
  //   isShow: true,
  //   component: Programs,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.PROGRAMS],
  // },
  // {
  //   path: ROUTE.ZONES,
  //   name: MENU_ITEMS.ZONES,
  //   isShow: true,
  //   component: Zones,
  //   layout: ROUTE.LAYOUT,
  //   pathActive: [ROUTE.ZONES],
  // },
  {
    path: ROUTE.USER,
    name: MENU_ITEMS.USERS,
    isShow: true,
    component: Users,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.USER],
  },
  {
    path: ROUTE.SETTING,
    name: MENU_ITEMS.SETTINGS,
    isShow: true,
    component: Settings,
    layout: ROUTE.LAYOUT,
    pathActive: [ROUTE.SETTING],
  },
];

export default dashboardRoutes;
