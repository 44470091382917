import { notification } from 'antd';
import {
    getSuppliersApi,
    updateSuppliersApi,
    uploadImageApi,
} from '../../services/supplierServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetSuppliersAction = {
  type: ACTION_TYPES.GET_SUPPLIERS,
};

export const failGetSuppliersAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SUPPLIERS_FAIL,
    error: data,
  };
};

export const successGetSuppliersAction = (data) => {
  return {
    type: ACTION_TYPES.GET_SUPPLIERS_SUCCESS,
    suppliers: data,
  };
};

const notifyFail = (funcFail, dispatch) => {
  dispatch(funcFail);
};

export const getSuppliers = () => {
  return (dispatch) => {
    dispatch(requestGetSuppliersAction);
    return getSuppliersApi().then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetSuppliersAction(data.suppliers));
            return data.suppliers;
          } else {
            notifyFail(failGetSuppliersAction('Get Suppliers Fail'), dispatch);
          }
        },
        () => notifyFail(failGetSuppliersAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestUpdateSuppliersAction = {
  type: ACTION_TYPES.UPDATE_SUPPLIERS,
};

export const failUpdateSuppliersAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SUPPLIERS_FAIL,
  };
};

export const successUpdateSuppliersAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_SUPPLIERS_SUCCESS,
    updateSupplier: data
  };
};

export const updateSuppliers = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateSuppliersAction);
    const body = {query: `mutation{updateSupplier(id: ${params.id}, status: ${params.status}, name: "${params.name}", address: "${params.address}", phone: "${params.phone}", email: "${params.email}") {id, name, address, email, phone}}`};
    updateSuppliersApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) { 
            dispatch(successUpdateSuppliersAction(data.updateSupplier));
          } else {
            openNotificationWithIcon('Update Suppliers Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateSuppliersAction(data));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateSuppliersAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const getRemoveBodyRequest = (body, key) => {
  switch (key) {
    case 0:
      return {query: `mutation{updateSupplier(id: ${body.id}, tc: null, name: "${body.name}", address: "${body.address}", phone: "${body.phone}", email: "${body.email}") {id, name, address, phone, email}}`};
    case 1:
      return {query: `mutation{updateSupplier(id: ${body.id}, liability: null, name: "${body.name}", address: "${body.address}", phone: "${body.phone}", email: "${body.email}") {id, name, address, phone, email}}`};
    case 2:
      return {query: `mutation{updateSupplier(id: ${body.id}, working_check: null, name: "${body.name}", address: "${body.address}", phone: "${body.phone}", email: "${body.email}") {id, name, address, phone, email}}`};
    default:
      break;
  }
};

export const removeImage = (params, key) => {
  return (dispatch) => {
    dispatch(requestUpdateSuppliersAction);
    const body = getRemoveBodyRequest(params, key);
    updateSuppliersApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          dispatch(successUpdateSuppliersAction(data.updateSupplier));
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateSuppliersAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestUploadImageAction = {
  type: ACTION_TYPES.UPLOAD_IMAGE,
};

export const failUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_FAIL,
  };
};

export const successUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_SUCCESS,
    supplierUploadInline: data
  };
};

const getBodyRequest = (file, record, index) => {
  const formData = new FormData();
  switch (index) {
    case 0:
      formData.append('operations', `{"query": "mutation UploadFile($tc_file: Upload!){supplierUploadInline(tc_file: $tc_file, id: ${record.id}) { id, tc liability working_check}}"}`);
      formData.append('map', `{"0": ["variables.tc_file"]}`);
      formData.append('0', file.originFileObj);
      return formData;
    case 1:
      formData.append('operations', `{"query": "mutation UploadFile($liability_file: Upload!){supplierUploadInline(liability_file: $liability_file, id: ${record.id}) { id, tc liability working_check}}"}`);
      formData.append('map', `{"0": ["variables.liability_file"]}`);
      formData.append('0', file.originFileObj);
      return formData;
    case 2:
      formData.append('operations', `{"query": "mutation UploadFile($working_check_file: Upload!){supplierUploadInline(working_check_file: $working_check_file, id: ${record.id}) { id, tc liability working_check}}"}`);
      formData.append('map', `{"0": ["variables.working_check_file"]}`);
      formData.append('0', file.originFileObj);
      return formData;
    default:
      break;
  }
};

export const uploadImage = (file, record, index) => {
  return (dispatch) => {
    dispatch(requestUploadImageAction);
    const body = getBodyRequest(file, record, index);
    return uploadImageApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson.success) {
            dispatch(successUploadImageAction(data.supplierUploadInline));
            return data.supplierUploadInline;
          } else {
            openNotificationWithIcon('Fail to upload file', 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch)
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};