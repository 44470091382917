import _ from 'lodash';

export const ACTION_TYPES = {
  GET_ZONES: 'GET_ZONES',
  GET_ZONES_SUCCESS: 'GET_ZONES_SUCCESS',
  GET_ZONES_FAIL: 'GET_ZONES_FAIL',

  UPDATE_ZONES: 'UPDATE_ZONES',
  UPDATE_ZONES_FAIL: 'UPDATE_ZONES_FAIL',
  UPDATE_ZONES_SUCCESS: 'UPDATE_ZONES_SUCCESS',
};

const initialState = {
  isLoading: false,
  listZones: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ZONES:
    case ACTION_TYPES.GET_ZONES:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPDATE_ZONES_FAIL:
    case ACTION_TYPES.GET_ZONES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ZONES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listZones: action.listZones,
      };
    case ACTION_TYPES.UPDATE_ZONES_SUCCESS:
      const list = _.forEach(state.listZones, (item) => {
        if (item.id === action.updateZones.id) {
          item.name = action.updateZones.name;
          item.state = action.updateZones.state;
        }
      });
      return {
        ...state,
        isLoading: false,
        listZones: list,
      };
    default:
      return state;
  }
}
