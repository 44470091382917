import React from 'react';
import {NavLink} from 'react-router-dom';
import {Menu, Layout, Icon} from 'antd';
import { connect } from 'react-redux';
import './style.css';

const {Sider} = Layout;

/**
 * the class AdminNavbar
 */
class AdminNavbar extends React.Component {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  collapseMenu = () => {
    this.setState({
      collapsed: true,
    });
  };

  /**
   * render AdminNavbar component
   * @return {Component}
   */
  render() {
    const {routes, currentRoute, userLogin} = this.props;
    const {collapsed} = this.state;
    console.log(userLogin, 'userLogin...');
    return (
      <Sider style={{background: '#f5811f'}} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Icon style={{color: 'white', width: '100%'}}
              onClick={this.toggle}
              type={collapsed ? 'menu-unfold' : 'menu-fold'}
            />
        </div>
        <Menu mode="inline" style={{lineHeight: '64px', background: '##f5811f'}}>
        {collapsed ? "" : routes
            .filter((route) => route.isShow === true && userLogin.objectUser.group_id === 1)
            .map((route, key) => (
              <Menu.Item
                key={key}
                className={currentRoute.pathActive && currentRoute.pathActive.includes(route.pathActive[0]) && !collapsed
                            ? 'ant-menu-item-selected'
                            : 'ant-menu-item-not-selected'}
                onClick={this.collapseMenu}
              >
                <NavLink key={key} to={route.layout + route.path}>
                  <span style={{color: 'white'}}>{!collapsed && route.name}</span>
                </NavLink>
              </Menu.Item>
            ))}
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin,
  };
};

export default connect(mapStateToProps)((AdminNavbar));
