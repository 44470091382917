import { combineReducers } from 'redux';

import userLogin from '../views/login/reducer';
import userAdd from '../views/users/reducer';
import suppliers from '../views/suppliers/reducer';
import zones from '../views/zones/reducer';
import programs from '../views/programs/reducer';
import importExport from '../components/importExport/reducer';
import experiences from '../views/experiences/reducer';
import activities from '../views/activities/reducer';
import activitiesResources from '../views/activitiesResources/reducer';
import plans from '../views/home/reducer';
import appState from '../views/appState/reducer';
import plan from '../views/home/addPlan/reducer';
import experienceComment from '../views/researchPan/detailExperiencesAndActivities/reducer';
import settings from '../views/settings/reducer'

// Combines all reducers to a single reducer function
const reducer = combineReducers(
  Object.assign({
    plans,
    userLogin,
    userAdd,
    suppliers,
    zones,
    programs,
    importExport,
    experiences,
    activities,
    activitiesResources,
    appState,
    plan,
    experienceComment,
    settings
  })
);

export default reducer;
