import Config from '../common/config';
import {api} from './api';

const exportDataUrl = `${Config.HOST}`;
const importDataTableUrl = `${Config.HOST}`;
const updateSeasonalTemplateUrl = `${Config.HOST}`;

export const exportDataApi = (params) => {
  return api.get(exportDataUrl + params);
};

export const importDataApi = (params) => {
  return api.postMultiplePart(importDataTableUrl, params);
}

export const updateSeasonalTemplateApi = (param) => {
  return api.post(updateSeasonalTemplateUrl, param);
}