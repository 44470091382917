import React, { Component } from 'react';
import { Button, Form, Select, Modal, Input, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { getListZones } from '../../../zones/actions';
import { STATUS_EXPERIENCES } from '../../../../common/constant';
import { saveListFilterExperiences, saveListFilterActivities } from '../../../appState/actions';
import _ from 'lodash';

const { Option } = Select;

/**
 * the class Index
 */
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'name',
      filterType: {
        supplier: [],
        zone: [],
        tags: [],
      },
      filterTypeAll: {
        supplier: [],
        zone: [],
        tags: [],
      },
      listName: [],
      listId: [],
      nameSearchFilter: '',
      typeDropboxFilter: null,
      dataNameSearchInit: null,
      dataListInit: [],
      dataListIdInit: [],
      checkAll: false,
      checkedList: [],
    };
  }

  updateFilter = () => {
    const { selected, listName, listId, nameSearchFilter, filterType, checkedList } = this.state;
    const {appState, selectedTabs} = this.props;
    const checkedListTmp = filterType[selected]?.filter(item => {
      return checkedList.includes(item.index);
    });
    const names = selected === 'zone' || selected === 'supplier' || selected === 'tags' ? checkedListTmp?.map(item => {
      if (selected === 'zone') {
        return item.name + "/" + item.state;
      }
      return item.name;
    }) : listName;
    const ids = selected === 'zone' || selected === 'supplier' || selected === 'tags' ? checkedListTmp?.map(item => {
      return item.id;
    }) : listId;
    
    const data = {
      type: selected,
      listName: names,
      listId: ids,
      nameSearchFilter: nameSearchFilter
    }
    if (selectedTabs === '1') {
      const list = appState?.experiencesShortListFilter || [];
      const index = list.findIndex((item) => item.type === selected);
      if(index >= 0) {
        list.splice(index, 1, data);
      }
      this.props.saveListFilterExperiences(list);
      this.props.addFilterShortList(list, selectedTabs);
    } else {
      const list = appState?.activitiesShortListFilter || [];
      const index = list.findIndex((item) => item.type === selected);
      if(index >= 0) {
        list.splice(index, 1, data);
      } 
      this.props.saveListFilterActivities(list);
      this.props.addFilterShortList(list, selectedTabs);
    }
    this.props.onClosePopUpFilter();
  }

  componentDidMount () {
    const { objectFilter, zones, appState, suppliers, } = this.props;
    const supplier = suppliers?.suppliers?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const zone = zones?.listZones?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const tags = appState?.listTags?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const filterTypeAll = {supplier, zone, tags};
    const checkedListId = filterTypeAll[objectFilter.type]?.filter((item) => {
      return objectFilter?.listId?.includes(item.id);
    })?.map(item => item.index);
    this.setState({ 
      typeDropboxFilter: objectFilter.type,
      selected: objectFilter.type ,
      dataNameSearchInit: objectFilter.nameSearchFilter,
      dataListInit: objectFilter.listName,
      dataListIdInit: objectFilter.listId,
      listName:  objectFilter.listName,
      listId: objectFilter.listId,
      filterType: Object.assign({}, filterTypeAll),
      filterTypeAll,
      checkedList: checkedListId || [],
      checkAll: objectFilter?.listId?.length === filterTypeAll[objectFilter.type]?.length ? true : false,
    })
  }

  deleteFilter = () => {
    const {appState, selectedTabs} = this.props;
    if (selectedTabs === '1') {
      const list = appState?.experiencesShortListFilter || [];
      this.changeListFilter(list);
      this.props.saveListFilterExperiences(list);
      this.props.addFilterShortList(list, selectedTabs);
    } else {
      const list = appState?.activitiesShortListFilter || [];
      this.changeListFilter(list);
      this.props.saveListFilterActivities(list);
      this.props.addFilterShortList(list, selectedTabs);
    }
    this.props.onClosePopUpFilter();
  }

  changeListFilter = (list) => {
     const { selected } = this.state;
    const index = list.findIndex((item) => item.type === selected);
    if(index >= 0) {
      list.splice(index, 1);
    } 
  }

  progressSelectedFilter = (value, item, i) => {
    const isChecked = value.target.checked;
    if (isChecked) {
      const listNameTmp = this.state.listName;
      const listIdTmp = this.state.listId;
      listNameTmp.push(item.name)
      listIdTmp.push(item.id)
      this.setState({
        listName: listNameTmp,
        listId: listIdTmp,
      })
    } else {
      const listName = this.state.listName;
      const listId = this.state.listId;
      _.remove(listName, function(n) {
        return n === item.name;
      });
      _.remove(listId, function(n) {
        return n === item.id;
      });
      this.setState({
        listName: listName,
        listId: listId,
      })
    }
  }

  merge_array = (array1, array2) => {
    const result_array = [];
    const arr = array1.concat(array2);
    let len = arr.length;
    const assoc = {};

    while(len--) {
        const item = arr[len];
        if (!assoc[item]) { 
            result_array.unshift(item);
            assoc[item] = true;
        }
    }
    return result_array;
  }

  onGroupChange = (value, checkedList) => {
    const { selected, filterType, filterTypeAll } = this.state;
    const checkedListTmp = filterType[selected].map(item => item.index);
    let list = [];
    if (checkedListTmp?.length === filterTypeAll[selected].length) {
      list = value;
    } else {
      const uncheckedList = checkedListTmp.filter(item => {
        return !checkedList.includes(item);
      });
      const removeCheckedList = checkedList.filter(item => !uncheckedList.includes(item));
      list = this.merge_array(removeCheckedList, value);
    }

    this.setState({
      checkedList: list,
      checkAll: filterType[selected].length === filterTypeAll[selected].length
          ? (list.length === filterTypeAll[selected].length ? true : false)
          : (value.length === filterType[selected].length ? true : false),
    });
  }

  onCheckAll = (e, checkedList) => {
    const { selected, filterType, filterTypeAll } = this.state;
    const checkedListTmp = filterType[selected].map(item => item.index);
    let list = [];
    if (checkedListTmp?.length === filterTypeAll[selected].length) {
      list = e.target.checked ? checkedListTmp : [];
    } else {
      const removeCheckedList = checkedList.filter(item => !checkedListTmp.includes(item));
      list = e.target.checked ? removeCheckedList.concat(checkedListTmp) : removeCheckedList;
    }
    this.setState({
      checkedList: list,
      checkAll: e.target.checked,
    });
  }

  onSearch = (e) => {
    const { value } = e.target;
    const { selected, filterTypeAll, filterType, checkedList } = this.state;
    if (selected === 'name') {
      this.setState({ nameSearchFilter: value })
    } else {
      const type = filterTypeAll[selected].filter(item => {
        const name = selected === 'zone' ? item.name + ' / ' + item.state : item.name;
        return name?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      const filter = filterType;
      filter[selected] = type;
      this.setState({
        checkAll: filterType[selected].length === filterTypeAll[selected].length
          ? (checkedList.length === filterType[selected].length ? true : false)
          : (filterType[selected].every(item => checkedList.includes(item.index)) ? true : false),
        filterType: filter
      });
    }
    
  }

  renderItemPopupFilter = () => {
    const { dataNameSearchInit, filterType, dataListInit, selected } = this.state;
    switch (selected) {
      case 'name':
        return (
          <div className='input-search'>
            <br />
            <br />
            <Input defaultValue={dataNameSearchInit} placeholder="Input Search" onChange={this.onSearch} />
            <br />
            <br />
          </div>
        ); 
      case 'type':
        const listStatusExperiences = STATUS_EXPERIENCES.map(item => {
          if(dataListInit.includes(item.name)){
            item.isChecked = true;
          } else {
            item.isChecked = false;
          }
          return item;
        })
        return (
          <div className='list-zones'>
            <br />
            {listStatusExperiences.map((item, i) => (
              <div key={i}>
                <Checkbox defaultChecked={item.isChecked} onChange={(value) => this.progressSelectedFilter(value,item, i)}>{item.name}</Checkbox>
              </div>
            ))}
          </div>
        );
      case 'zone':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group value={this.state.checkedList} onChange={(value) => this.onGroupChange(value, this.state.checkedList)}>
              {filterType?.zone?.map((item, i) => (
                <div key={i}>
                  <Checkbox value={item.index}>{item.name + ' / ' + item.state}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      case 'supplier':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group value={this.state.checkedList} onChange={(value) => this.onGroupChange(value, this.state.checkedList)}>
              {filterType?.supplier?.map((item, i) => (
                <div key={i}>
                  <Checkbox value={item.index}>{item.name}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      case 'tags':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group value={this.state.checkedList} onChange={(value) => this.onGroupChange(value, this.state.checkedList)}>
              {filterType?.tags?.map((item, i) => (
                <div key={i}>
                  <Checkbox value={item.index}>{item.name}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      default:
        return null;
    }
  };
  /**
   * render View
   * @return {Component}
   */
  render() {
    const { onClosePopUpFilter } = this.props;
    const { typeDropboxFilter, selected } = this.state;
    return (
      <div className='container-modal-filter'>
        <Form
          id='createPlanForm'
          onKeyPress={(e) => {
            if(e.key === "Enter"){
              this.updateFilter()
            }}
          }
        >
          <Modal
            className='modal-filter'
            bodyStyle={{ height: '50%' }}
            visible={true}
            onCancel={onClosePopUpFilter}
            title='Filter'
            footer={[
              <div>
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    <Button
                      onClick={this.updateFilter}
                      className='button-update'
                      variant='primary'
                      form='createPlanForm'
                      key='submit'
                      htmlType='submit'
                    >
                      <span className='title-update'>Update</span>
                    </Button>
                  </div>
                  <div style={{ float: 'right' }}>
                      <Button
                        onClick={this.deleteFilter}
                        className='button-delete'
                        variant='primary'
                        form='createPlanForm'
                        key='submit'
                        htmlType='submit'
                      >
                        <span className='title-delete'>Delete Filter</span>
                      </Button>
                    </div>
                </Form.Item>
              </div>,
            ]}
          >
            <div className='content-filter'>
                <Select
                  defaultValue={typeDropboxFilter}
                  style={{ width: 120 }}
                >
                  <Option  value='name'>
                    <span className="title-option" >{typeDropboxFilter}</span>
                  </Option>
                </Select>
              {
                selected !== 'name' && selected !== 'type' ? (
                  <div className="search-on-filter">
                    <Input placeholder="Search" onChange={this.onSearch}/>
                  </div>
                ) : null
              }
              {this.renderItemPopupFilter()}
              <br />
              {
                selected !== 'name' && selected !== 'type' ? (
                  <div>
                    <Checkbox checked={this.state.checkAll} onChange={this.onCheckAll}>All/None</Checkbox>
                  </div>
                ) : null
              }
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    zones: state.zones,
    appState: state.appState,
    suppliers: state.suppliers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    saveListFilterExperiences: (list) => dispatch(saveListFilterExperiences(list)),
    saveListFilterActivities: (list) => dispatch(saveListFilterActivities(list))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
