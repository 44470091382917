import _ from 'lodash';

export const ACTION_TYPES = {
  GET_PLANS: 'GET_PLANS',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_FAIL: 'GET_PLANS_FAIL',

  UPDATE_PLAN: 'UPDATE_PLAN',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAIL: 'UPDATE_PLAN_FAIL',

  DUPLICATE_PLAN: 'DUPLICATE_PLAN',
  DUPLICATE_PLAN_SUCCESS: 'DUPLICATE_PLAN_SUCCESS',
  DUPLICATE_PLAN_FAIL: 'DUPLICATE_PLAN_FAIL',

  IMPORT_DATA: 'IMPORT_DATA',
  IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
  IMPORT_DATA_FAIL: 'IMPORT_DATA_FAIL',

  GET_SEASONAL_TEMPLATE: 'GET_SEASONAL_TEMPLATE',
  GET_SEASONAL_TEMPLATE_SUCCESS: 'GET_SEASONAL_TEMPLATE_SUCCESS',
  GET_SEASONAL_TEMPLATE_FAIL: 'GET_SEASONAL_TEMPLATE_FAIL',

  SUBMIT_GENERATE_FLYER_PDF: 'SUBMIT_GENERATE_FLYER_PDF',
  SUBMIT_GENERATE_FLYER_PDF_SUCCESS: 'SUBMIT_GENERATE_FLYER_PDF_SUCCESS',
  SUBMIT_GENERATE_FLYER_PDF_FAIL: 'SUBMIT_GENERATE_FLYER_PDF_FAIL',

  GET_FILES_PREVIEW_FLYER_PDF: 'GET_FILES_PREVIEW_FLYER_PDF',
  GET_FILES_PREVIEW_FLYER_PDF_SUCCESS: 'GET_FILES_PREVIEW_FLYER_PDF_SUCCESS',
  GET_FILES_PREVIEW_FLYER_PDF_FAIL: 'GET_FILES_PREVIEW_FLYER_PDF_FAIL',

  PUBLISH_FLYER_PDF: 'PUBLISH_FLYER_PDF',
  PUBLISH_FLYER_PDF_SUCCESS: 'PUBLISH_FLYER_PDF_SUCCESS',
  PUBLISH_FLYER_PDF_FAIL: 'PUBLISH_FLYER_PDF_FAIL',
};

const initialState = {
  isLoading: false,
  listPlan: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.DUPLICATE_PLAN:
    case ACTION_TYPES.GET_PLANS:
    case ACTION_TYPES.UPDATE_PLAN:
    case ACTION_TYPES.GET_SEASONAL_TEMPLATE:
    case ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF:
    case ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.DUPLICATE_PLAN_SUCCESS:
    case ACTION_TYPES.DUPLICATE_PLAN_FAIL:
    case ACTION_TYPES.UPDATE_PLAN_FAIL:
    case ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF_FAIL:
    case ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF_FAIL:
      return {
        ...state,
        isLoading: false,
        previewUrl: '',
        flyerPDFFiles: []
      };
    case ACTION_TYPES.GET_PLANS_FAIL:
      return {
        ...state,
        isLoading: false,
        listPlan: [],
      };
    case ACTION_TYPES.GET_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPlan: action.listPlan,
      };
    case ACTION_TYPES.UPDATE_PLAN_SUCCESS_: 
      const list = _.forEach(state.listPlan, item => {
          if(item.id === action.updatePlan.id) {
            item.name = action.updatePlan.name;
            item.status = action.updatePlan.status;
            item.start_date = action.updatePlan.start_date;
            item.end_date = action.updatePlan.end_date;
            item.created_date = action.updatePlan.created_date;
            item.modified_date = action.updatePlan.modified_date;
          }
      })
      return {
        ...state,
        isLoading: false,
        listPlan: list,
      };
    case ACTION_TYPES.GET_SEASONAL_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
        seasonalTemplate: {},
      };
    case ACTION_TYPES.GET_SEASONAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seasonalTemplate: action.template,
      };
    case ACTION_TYPES.SUBMIT_GENERATE_FLYER_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        previewUrl: action.previewUrl
      }
    case ACTION_TYPES.GET_FILES_PREVIEW_FLYER_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        flyerPDFFiles: action.pdfFiles
      }
    default:
      return state;
  }
}
