import _ from 'lodash';

export const ACTION_TYPES = {
  GET_ACTIVITIES_RESOURCES: 'GET_ACTIVITIES_RESOURCES',
  GET_ACTIVITIES_RESOURCES_SUCCESS: 'GET_ACTIVITIES_RESOURCES_SUCCESS',
  GET_ACTIVITIES_RESOURCES_FAIL: 'GET_ACTIVITIES_RESOURCES_FAIL',

  UPDATE_ACTIVITIES_RESOURCES: 'UPDATE_ACTIVITIES_RESOURCES',
  UPDATE_ACTIVITIES_RESOURCES_SUCCESS: 'UPDATE_ACTIVITIES_RESOURCES_SUCCESS',
  UPDATE_ACTIVITIES_RESOURCES_FAIL: 'UPDATE_RESOURCES_ACTIVITIES_FAIL',

  GET_ACTIVITIES_ATTENDANCE: 'GET_ACTIVITIES_ATTENDANCE',
  GET_ACTIVITIES_ATTENDANCE_FAIL: 'GET_ACTIVITIES_ATTENDANCE_FAIL',
  GET_ACTIVITIES_ATTENDANCE_SUCCESS: 'GET_ACTIVITIES_ATTENDANCE_SUCCESS',
};

const initialState = {
  isLoading: false,
  listActivitiesResources: [],
  activityAttendance: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES:
    case ACTION_TYPES.GET_ACTIVITIES_RESOURCES:
    case ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_ACTIVITIES_RESOURCES_FAIL:
    case ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ACTIVITIES_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listActivitiesResources: action.listActivitiesResources,
      };
    case ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES_SUCCESS:
      const list = _.forEach(state.listActivitiesResources, (item) => {
        if (item.id === action.updateActivityResources.id) {
          item.resource_per_child = action.updateActivityResources.resource_per_child;
          item.notes = action.updateActivityResources.notes
        }
      });
      return {
        ...state,
        isLoading: false,
        listActivitiesResources: list,
      };
    case ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activityAttendance: action.items,
      };
    case ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE_FAIL:
      return {
        ...state,
        isLoading: false,
        activityAttendance: [],
      };
    default:
      return state;
  }
}
