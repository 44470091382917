import {api} from './api';
import Config from '../common/config';

const getSuppliersUrl = `${Config.HOST}?query={ suppliers{id name status address zone{name state} phone email tc liability working_check notes created_date}}` ;
const updateSuppliersUrl = `${Config.HOST}`;
const uploadImageUrl = `${Config.HOST}`;

export const getSuppliersApi = () => {
  return api.get(getSuppliersUrl);
};

export const updateSuppliersApi = (params) => {
  return api.post(updateSuppliersUrl, params)
};

export const uploadImageApi = (params) => {
  return api.postMultiplePart(uploadImageUrl, params);
}

