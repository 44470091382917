import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import {connect} from 'react-redux';
import {doLogin} from './actions';
import { ROUTE } from '../../common/constant';
import { Link } from 'react-router-dom';

import './styles.css';

class Index extends Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.doLogin(values);
      }
    });
  };

  render() {
    const { userLogin } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='container'>
        <Row>
          <div className='form-container'>
            <Row>
              <div>
                <img
                  alt='logo'
                  src={require('../../assets/TeamKids-Logo_Orange_CMYK-1.png')}
                  width='100'
                  height='100'
                />
                <span>
                  <div className='title-holiday'>Holiday Period Planner</div>
                </span>
              </div>
            </Row>
            <Row>
              <div className='title-login'>Login</div>
              <div className='form-login'>
                <Form onSubmit={this.handleSubmit} layout='vertical'>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                      <Input
                        prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder='Email'
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                      <Input
                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type='password'
                        placeholder='Password'
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Col span={4}>
                      <Button
                        className='button-login' 
                        loading={userLogin.isLoading}
                        htmlType='submit'>
                        {!userLogin.isLoading ? 'Log in' : ''} 
                      </Button>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={12}>
                        <Link className='recover-my-password' to={ROUTE.LAYOUT + ROUTE.FORGOT_PASSWORD}>
                          Forgot your password?
                        </Link>
                    </Col>
                  </Form.Item>
                </Form>
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (param) => dispatch(doLogin(param)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Index));
