import {validateStatus} from '../utils/api/index.js';
import StorageKey from '../common/storage/Key.js';
const axios = require('axios');

const HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Authorization':'Bearer ' + localStorage.getItem(StorageKey.TOKEN_KEY),
};

const HEADERS_NOT_AUTHORIZATION = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  'Content-Type': 'multipart/form-data; boundary=something',
  'Accept': 'application/json',
};

const api = {
  postLogin: (endpoint, params) => {
    return axios.default
        .post(endpoint, params, {
          headers: HEADERS_NOT_AUTHORIZATION,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },
  post: (endpoint, params) => {
    return axios.default
        .post(endpoint, params, {
          headers: HEADERS,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },

  postMultiplePart: (endpoint, params) => {
    return axios.default
        .post(endpoint, params, {
          headers: HEADERS_MULTIPLE_PART,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },

  get: (endpoint, params = {}) => {
    return axios.default
        .get(endpoint, {
          params: params,
          headers: HEADERS,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },

  put: (endpoint, params) => {
    return axios.default
        .put(endpoint, params, {
          headers: HEADERS,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },

  delete: (endpoint, params) => {
    return axios.default
        .delete(endpoint, {
          params: params,
          headers: HEADERS,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        )
        .catch(
            (response) => {
              return response.data;
            },
            (err) => {
              return err.response || err;
            }
        );
  },
};

export {api};
