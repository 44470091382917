import Config from '../common/config';
import {api} from './api';

const programsService = `${Config.HOST}`;
const uploadImageUrl = `${Config.HOST}`;
const updateProgramsUrl = `${Config.HOST}`;

export const getProgramsApi = (pageSize, pageIndex) => {
  return api.get(programsService + `?query={ programs(page_size: ${pageSize}, page_index:${pageIndex}){id name address zone{name state} phone capality contract_start_date operation_times map  comment created_date}}`);
};

export const updateProgramsApi = (params) => {
  return api.post(updateProgramsUrl, params)
};

export const uploadImageApi = (params) => {
  return api.postMultiplePart(uploadImageUrl, params);
}
