import { notification } from 'antd';
import { getProgramsApi, updateProgramsApi, uploadImageApi } from '../../services/programsServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const requestProgramsAction = {
  type: ACTION_TYPES.GET_PROGRAMS,
};

export const failGetProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PROGRAMS_FAIL,
  };
};

export const successProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PROGRAMS_SUCCESS,
    listPrograms: data
  };
};

export const getPrograms = (page_size, page_index) => {
  return (dispatch) => {
    dispatch(requestProgramsAction);
    return getProgramsApi(page_size, page_index).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successProgramsAction(data?.programs));
            return data?.programs;
          } else {
            notifyFail(failGetProgramsAction('Get Programs Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetProgramsAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestUpdateProgramsAction = {
  type: ACTION_TYPES.UPDATE_PROGRAMS,
};
export const failUpdateProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PROGRAMS_FAIL,
    error: data,
  };
};

export const successUpdateProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PROGRAMS_SUCCESS,
    updateProgram: data
  };
};

export const updatePrograms = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateProgramsAction);
    const body = {query: `mutation{updateProgram(id: ${params.id}, comment: "${params.comment}", contract_start_date: "${params.contract_start_date}", operation_times: "${params.operation_times}", name: "${params.name}", address: "${params.address}", phone: "${params.phone}", capality: ${params.capality}) {id, name, capality, address, phone, comment, operation_times, contract_start_date}}`};
    updateProgramsApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successUpdateProgramsAction(data?.updateProgram));
          } else {
            openNotificationWithIcon('Update Programs Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateProgramsAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateProgramsAction(INTERNAL_SERVER_ERROR), dispatch);
        }
    );
  };
};

const requestUploadImageAction = {
  type: ACTION_TYPES.UPLOAD_IMAGE,
};

export const failUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_FAIL,
  };
};

export const successUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_SUCCESS,
    programUploadInline: data
  };
};

export const removeImage = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateProgramsAction);
    const body = {query: `mutation{updateProgram(id: ${params.id}, name: "${params.name}", address: "${params.address}", phone: "${params.phone}", capality: ${params.capality}, map: null) {id, name, capality, address, phone, comment, operation_times, contract_start_date}}`};
    updateProgramsApi(body).then(
        (dataJson) => {
          if (dataJson && dataJson.success) {
            dispatch(successUpdateProgramsAction(dataJson.data?.updateProgram));
          } else {
            openNotificationWithIcon('Update Programs Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateProgramsAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateProgramsAction(INTERNAL_SERVER_ERROR), dispatch);
        }
    );
  };
};

export const uploadImage = (file, record, index) => {
  return (dispatch) => {
    dispatch(requestUploadImageAction);
    const body = new FormData();
    body.append('operations', `{"query": "mutation UploadFile($image_file: Upload!){programUploadInline(map_file: $image_file, id: ${record.id}) {id, name, capality, address, phone, comment, operation_times, contract_start_date}}"}`);
    body.append('map', `{"0": ["variables.image_file"]}`);
    body.append('0', file.originFileObj);
    uploadImageApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson.success) {
            dispatch(successUploadImageAction(data.programUploadInline));
          } else {
            openNotificationWithIcon('Fail to upload file', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUploadImageAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};