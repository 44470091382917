import StorageKey from '../../common/storage/Key.js';
import {ROUTE} from '../../common/constant';

export const logout = () => {
  localStorage.removeItem(StorageKey.TOKEN_KEY);
  localStorage.removeItem(StorageKey.FILTERED_ITEMS);
  window.location.replace(ROUTE.LAYOUT + ROUTE.LOGIN);
};

export const checkAuth = () => {
  if (!localStorage.getItem(StorageKey.TOKEN_KEY)) {
    window.location.replace(ROUTE.LAYOUT + ROUTE.LOGIN);
  }
};
