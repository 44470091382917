import React, { Component } from 'react';
import { Layout, Form, Icon, Button, Row, Tabs } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import ActivitiesAndExperiencesItem from '../../../components/activityAndExperiencesItem';
import Filter from '../filter';
import EditFilter from '../filter/edit';
import { getListZones } from '../../zones/actions';
import { getExperiences } from '../../experiences/actions';
import { getActivities } from '../../activities/actions';
import { saveToShortlist } from '../addPlan/actions';
import {
  getTags,
  hideMenu,
  backFromScreen,
  saveListFilterExperiences,
  saveListFilterActivities,
} from '../../appState/actions';
import { getSuppliers } from '../../suppliers/actions';
import ResearchPanDetailActivities from '../../researchPan';
import ExperiencesDetail from '../../researchPan/detailExperiencesAndActivities';
import LoadingOverlay from 'react-loading-overlay';
import { ROUTE, SCREEN, PAGE_SIZE_SHORT_LIST } from '../../../common/constant';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.css';

const { Content, Sider, Header } = Layout;
const { TabPane } = Tabs;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, index) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  background: 'white',
  'grid-column-start': `${(index % 3) + 1}`,
  width: 'fit-content',
  ...draggableStyle,
});
const getItemMenuStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  display: 'flex',
  background: isDragging ? 'white' : 'grey',
  ...draggableStyle,
});

const getListStyle = (listLength, shortListContentHeight) => ({
  background: 'white',
  width: '90%',
  minHeight: 'calc(100vh - 220px)',
  margin: 'auto',
  border: '2px dashed gray',
  marginTop: '10px',
});
const getListSelectStyle = (isDraggingOver) => ({
  background: 'white',
  'margin-top': '10px',
  display: 'grid',
  'grid-gap': '15px',
  padding: 'grid',
});

class Index extends Component {
  state = {
    collapsed: false,
    experiencesShortlist: [],
    activitiesShortlist: [],
    experiencesTmp: [],
    activitiesTmp: [],
    allActivities: [],
    allExperiences: [],
    arrayPlanDate: [],
    arrayPrograms: [],
    showPopupAddFilter: false,
    selectedTabs: '1',
    disableExperienceShortlist: false,
    disableActivityShortlist: true,
    showUpdateFilterShortList: false,
    objectFilter: {},
    itemResearchPan: {},
    itemDetailExperiences: {},
    showDetailExperiences: false,
    showPopupDetailActivities: false,
    isLoading: false,
    filterShortList: {
      1: [],
      2: [],
    },
    pageNumActivities: 1,
    pageNumExperiences: 1,
    totalActivitiesItems: 0,
    totalExperiencesItems: 0,
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    allActivities: 'allActivities',
    allExperiences: 'allExperiences',
    experiencesShortlist: 'experiencesShortlist',
    activitiesShortlist: 'activitiesShortlist',
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.getList(source.droppableId), source.index, destination.index);

      let state = { items };

      if (source.droppableId === 'allActivities') {
        state = { allActivities: items };
      } else if (source.droppableId === 'allExperiences') {
        state = { allExperiences: items };
      } else if (source.droppableId === 'experiencesShortlist') {
        state = { experiencesShortlist: items };
      } else if (source.droppableId === 'activitiesShortlist') {
        state = { activitiesShortlist: items };
      }

      this.setState(state);
    } else {
      const droppableSource = source.droppableId;
      const droppableDestination = destination.droppableId;
      if (
        (droppableDestination === 'experiencesShortlist' && droppableSource === 'allActivities') ||
        (droppableDestination === 'activitiesShortlist' && droppableSource === 'allExperiences') ||
        (droppableDestination === 'activitiesShortlist' && droppableSource === 'experiencesShortlist') ||
        (droppableDestination === 'experiencesShortlist' && droppableSource === 'activitiesShortlist')
      ) {
        return;
      }

      const result = move(this.getList(droppableSource), this.getList(droppableDestination), source, destination);
      if (result.hasOwnProperty('experiencesShortlist')) {
        this.setState({
          allExperiences: result.allExperiences ? result.allExperiences : this.state.allExperiences,
          experiencesShortlist: result?.experiencesShortlist || [],
          activitiesShortlist: [],
          experiencesTmp: result?.experiencesShortlist || [],
        });
      } else {
        this.setState({
          allActivities: result.allActivities ? result.allActivities : this.state.allActivities,
          activitiesShortlist: result.activitiesShortlist || [],
          experiencesShortlist: [],
          activitiesTmp: result.activitiesShortlist || [],
        });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.doLogin(values);
      }
    });
  };

  getShortlistByUser = () => {
    return this.props.plan.shortlistByUser?.find((item) => {
      return item.userId === this.props.userLogin.objectUser.id;
    });
  };

  saveAndExit = () => {
    const { activitiesTmp, experiencesTmp } = this.state;
    const list = this.saveShortlist(activitiesTmp, experiencesTmp);
    this.props.saveToShortlist(list);
    this.props.hideMenu(false);
    this.props.backFromScreen(SCREEN.SHORT_LIST_PLAN);
    this.props.saveListFilterExperiences([]);
    this.props.saveListFilterActivities([]);
    this.props.history.push(ROUTE.LAYOUT + ROUTE.EDIT_PLAN + `/${this.props.plan.planInfo.id}`);
  };

  saveShortlist = (activitiesShortlist, experiencesShortlist) => {
    const { shortlistByUser } = this.props.plan;
    const { objectUser } = this.props.userLogin;
    const arrayPrograms = this.getShortlistByUser()?.arrayPrograms ? this.getShortlistByUser()?.arrayPrograms : [];

    experiencesShortlist = experiencesShortlist.sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
    activitiesShortlist = activitiesShortlist.sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );

    const shortlist = {
      userId: objectUser.id,
      experiencesShortlist,
      activitiesShortlist,
      arrayPrograms,
    };
    let list = shortlistByUser !== undefined ? shortlistByUser : [];
    if (list.some((item) => item.userId === shortlist.userId)) {
      list = list.map((item) => {
        if (item.userId === shortlist.userId) {
          item = shortlist;
        }
        return item;
      });
    } else {
      list.push(shortlist);
    }
    return list;
  };

  showPopupAddFilter = () => {
    const { filterShortList } = this.state;
    this.setState({ showPopupAddFilter: true, filterShortList });
  };

  hidePopupAddFilter = () => {
    this.setState({ showPopupAddFilter: false, showUpdateFilterShortList: false });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const promiseList = [];
    const shortlist = this.getShortlistByUser();
    const { pageNumActivities, pageNumExperiences } = this.state;
    promiseList.push(this.getExperiencesData(shortlist, [], false, pageNumExperiences));
    promiseList.push(this.getDataActivitiesData(shortlist, [], false, pageNumActivities));
    promiseList.push(this.props.getListZones(100));
    promiseList.push(this.props.getTags());
    promiseList.push(this.props.getSuppliers());
    Promise.all(promiseList)
      .then((values) => {
        this.setState({ isLoading: false });
      })
      .catch((reason) => {
        this.setState({ isLoading: false });
      });
  }

  getExperiencesData = (shortlist, list, typeLoading, pageNum, isFilter) => {
    if (typeLoading) {
      this.setState({ isLoading: true });
    }
    const dataFilter = list.map((item) => {
      if (item.type === 'name') {
        return `name: "${item.nameSearchFilter}"`;
      } else if (item.type === 'zone') {
        return `zone_id: [${item.listId.join()}]`;
      } else if (item.type === 'supplier') {
        return `supplier_id: [${item.listId.join()}]`;
      } else if (item.type === 'type') {
        return `type: [${item.listName.join()}]`;
      }
      return `${item.type}: [${item.listName
        .map((name) => {
          return `"${name}"`;
        })
        .join()}]`;
    });
    this.props.getExperiences(pageNum, dataFilter).then((data) => {
      let experiencesShortlist = shortlist?.experiencesShortlist ? shortlist?.experiencesShortlist : [];
      if (data) {
        const listExperiencesTmp = data?.experiences?.items?.map((item) => ({
          id: `${item.id}`,
          name: item.name,
          content: item.fullybooked_desc,
          capacity: item.capacity,
          rating: item.rating,
          cost_per_child: item.cost_per_child,
          fullybooked_desc: item.fullybooked_desc,
          flyer_desc: item.flyer_desc,
          type: item.type,
          supplier: item.supplier,
          zone: item.zone,
          tags: item.tags,
          image: item.image,
        }));
        if (typeLoading) {
          experiencesShortlist = this.state.experiencesTmp;
        }

        let allExperiences = [];
        if (isFilter) {
          allExperiences = listExperiencesTmp.filter((item) => {
            return !experiencesShortlist.some((shortItem) => item.id === shortItem.id);
          });
        } else {
          allExperiences = this.state.allExperiences.concat(listExperiencesTmp).filter((item) => {
            return !experiencesShortlist.some((shortItem) => item.id === shortItem.id);
          });
        }

        const filterShortList = this.state.filterShortList;
        filterShortList[this.state.selectedTabs] = list;
        this.setState({
          experiencesShortlist,
          allExperiences,
          experiencesTmp: experiencesShortlist,
          isLoading: false,
          filterShortList,
          pageNumExperiences: pageNum,
          totalExperiencesItems: data?.experiences?.total_item
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  getDataActivitiesData = (shortlist, list, typeLoading, pageNum, isFilter) => {
    if (typeLoading) {
      this.setState({ isLoading: true });
    }
    const dataFilter = list.map((item) => {
      if (item.type === 'name') {
        return `name: "${item.nameSearchFilter}"`;
      }
      return `${item.type}: [${item.listName
        .map((name) => {
          return `"${name}"`;
        })
        .join()}]`;
    });
    this.props.getActivities(pageNum, dataFilter).then((data) => {
      if (!data) {
        return;
      }
      let activitiesShortlist = shortlist?.activitiesShortlist ? shortlist?.activitiesShortlist : [];
      const listActivitiesTmp = data?.activities?.items?.map((item) => ({
        id: `${item.id}`,
        name: item.name,
        content: item.description,
        image: item.image,
        tags: item.tags,
        created_date: item.created_date,
      }));
      if (typeLoading) {
        activitiesShortlist = this.state.activitiesTmp;
      }
      let allActivities = [];
      if (isFilter) {
        allActivities = listActivitiesTmp?.filter((item) => {
          return !activitiesShortlist?.some((shortItem) => item.id === shortItem.id);
        });
      } else {
        allActivities = this.state.allActivities?.concat(listActivitiesTmp).filter((item) => {
          return !activitiesShortlist?.some((shortItem) => item.id === shortItem.id);
        });
      }

      const filterShortList = this.state.filterShortList;
      filterShortList[this.state.selectedTabs] = list;
      this.setState({
        activitiesShortlist,
        allActivities,
        activitiesTmp: activitiesShortlist,
        isLoading: false,
        filterShortList,
        pageNumActivities: pageNum,
        totalActivitiesItems: data?.activities?.total_item
      });
    });
  };

  onChangTabs = (key) => {
    let disabled = true;
    let activitiesShortlist = [];
    let experiencesShortlist = [];
    if (key === '1') {
      disabled = false;
      experiencesShortlist = this.state.experiencesTmp || [];
      activitiesShortlist = [];
    } else {
      activitiesShortlist = this.state.activitiesTmp || [];
      experiencesShortlist = [];
    }

    this.setState({
      selectedTabs: key,
      disableExperienceShortlist: disabled,
      disableActivityShortlist: !disabled,
      activitiesShortlist,
      experiencesShortlist,
    });
  };

  removeAllShortlist = () => {
    if (this.state.selectedTabs === '1') {
      this.setState({
        experiencesShortlist: [],
        allExperiences: this.state.experiencesShortlist.concat(this.state.allExperiences),
        experiencesTmp: [],
      });
    } else {
      this.setState({
        activitiesShortlist: [],
        allActivities: this.state.activitiesShortlist.concat(this.state.allActivities),
        activitiesTmp: [],
      });
    }
  };

  showPopupDetailExperiences = (item) => {
    this.setState({ showDetailExperiences: true, itemDetailExperiences: item });
  };

  hidePopupDetailExperiences = () => {
    this.setState({ showDetailExperiences: false });
  };

  showPopupDetailActivitiesFun = (item) => {
    this.setState({ showPopupDetailActivities: true, itemResearchPan: item });
  };

  hidePopupResearchPanDetailActivities = () => {
    this.setState({ showPopupDetailActivities: false });
  };

  onUpdateFilterShortList = () => {};

  onDeleteFilterShortList = () => {
    this.props.removeObjectFilterShortList();
    this.getData();
  };

  addFilterShortList = (list, typeTabs) => {
    const shortlist = this.getShortlistByUser();
    if (typeTabs === '1') {
      this.getExperiencesData(shortlist, list, true, 1, true);
    } else {
      this.getDataActivitiesData(shortlist, list, true, 1, true);
    }
  };

  deleteObjectFilterShortList = () => {};

  updateObjectFilterShortList = (item) => {
    this.setState({ showUpdateFilterShortList: true, objectFilter: item });
  };

  fetchMoreDataExperiences = () => {
    const pageNum = this.state.pageNumExperiences + 1;
    const shortlist = this.getShortlistByUser();
    if (pageNum < this.state.totalExperiencesItems / PAGE_SIZE_SHORT_LIST + 1) {
      this.getExperiencesData(shortlist, this.props.appState.experiencesShortListFilter, true, pageNum);
    }
  };

  fetchMoreDataActivities = () => {
    const pageNum = this.state.pageNumActivities + 1;
    const shortlist = this.getShortlistByUser();
    if (pageNum < this.state.totalActivitiesItems / PAGE_SIZE_SHORT_LIST + 1) {
      this.getDataActivitiesData(shortlist, this.props.appState.activitiesShortListFilter, true, pageNum);
    }
  };

  render() {
    const {
      collapsed,
      showPopupAddFilter,
      experiencesShortlist,
      allExperiences,
      allActivities,
      selectedTabs,
      activitiesShortlist,
      disableExperienceShortlist,
      disableActivityShortlist,
      showUpdateFilterShortList,
      objectFilter,
      itemResearchPan,
      itemDetailExperiences,
      showDetailExperiences,
      showPopupDetailActivities,
      isLoading,
      filterShortList,
    } = this.state;
    const { appState, plan } = this.props;
    const shortListContentHeight = window.screen.height - 120;
    return (
      <LoadingOverlay active={isLoading} spinner>
        <Layout style={{ height: '100%' }}>
          <Header style={{ paddingLeft: 18, background: '#ffffff', borderBottom: '1px solid #dddddd', height: '50px' }}>
            <Row className='header-create-plan'>
              <div className='title-page-create-plan'>
                {plan.planInfo.name} &nbsp;
                <span className='plan-status'>{plan.planInfo.status}</span>
                <div className='plan-status'>{plan.planInfo.status}</div>
              </div>
              <div className='header-button'>
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    <Button className='button-save-and-exit' onClick={this.saveAndExit}>
                      <Icon type='upload' /> save and back
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Row>
          </Header>
          <div className='plan-status-container'>
            <span className='plan-status_date'>{plan.planInfo.start_date}</span>
            <span className='plan-status_date'> to </span>
            <span className='plan-status_date'>{plan.planInfo.end_date}</span>
          </div>
          <Layout style={{ height: '100%' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className='slider-on-plan'>
                <Sider
                  className={collapsed ? '' : 'slider-show'}
                  style={{ background: 'white' }}
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                >
                  <div className='logo'></div>
                  <div className='card-container'>
                    <Tabs type='card' onChange={this.onChangTabs}>
                      <TabPane tab='Experiences' key='1'>
                        <div className='shortlist'>Experiences shortlist</div>
                        <div
                          style={{
                            height: 'calc(100vh - 220px)',
                          }}
                        >
                          <Droppable isDropDisabled={disableExperienceShortlist} droppableId='experiencesShortlist'>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(experiencesShortlist?.length, shortListContentHeight)}
                              >
                                {experiencesShortlist.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <ActivitiesAndExperiencesItem
                                          typeItem='Experiences'
                                          typeCss='ItemShortList'
                                          item={item}
                                          hideApplyAll={true}
                                          showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </TabPane>
                      <TabPane tab='Activities' key='2'>
                        <div className='shortlist'>Activities shortlist</div>
                        <div
                          style={{
                            height: 'calc(100vh - 220px)',
                          }}
                        >
                          <Droppable isDropDisabled={disableActivityShortlist} droppableId='activitiesShortlist'>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(activitiesShortlist?.length, shortListContentHeight)}
                              >
                                {activitiesShortlist.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <ActivitiesAndExperiencesItem
                                          typeItem='Activities'
                                          typeCss='ItemShortList'
                                          showPopupResearchPan={(item) => this.showPopupDetailActivitiesFun(item)}
                                          item={item}
                                          hideApplyAll={true}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </TabPane>
                    </Tabs>
                    <div className='bottom-remove-all-container'>
                      <Button className='bottom-remove-all' onClick={this.removeAllShortlist}>
                        <span className='text-bottom-remove-all'>Remove All</span>
                      </Button>
                    </div>
                  </div>
                </Sider>
              </div>
              <Layout style={{ height: '100%', background: '#2F4F4F' }}>
                <Content>
                  <div className='filter-container'>
                    <div className='filter'>
                      <div className='filter-underline'>
                        <Icon type='filter' style={{ fontSize: 20, color: '#ffffff' }} />
                        <div className='title-filter'>
                          <span>Filter</span>
                        </div>
                      </div>
                    </div>
                    <div className='container-add-filter-and-new-filter'>
                      {selectedTabs === '1' ? (
                        <div>
                          {appState?.experiencesShortListFilter.length > 0 ? (
                            appState.experiencesShortListFilter.map((item) => (
                              <div className='add-filter'>
                                <div className='state-filter'>
                                  <div>
                                    <span className='state-title'>{`${item.type}:`}</span>
                                  </div>
                                  <div style={{ width: '125px', whiteSpace: 'nowrap' }}>
                                    <span className='content-filter ellipsis-text'>
                                      {item?.nameSearchFilter ? item.nameSearchFilter : item?.listName.join()}
                                    </span>
                                  </div>
                                </div>
                                <Button
                                  type='link'
                                  className='icon-filter'
                                  onClick={() => this.updateObjectFilterShortList(item)}
                                >
                                  <Icon type='form' style={{ fontSize: 20, color: '#ffffff' }} />
                                </Button>
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {appState?.activitiesShortListFilter.length > 0 ? (
                            appState?.activitiesShortListFilter.map((item) => (
                              <div className='add-filter'>
                                <div className='state-filter'>
                                  <div>
                                    <span className='state-title'>{`${item.type}:`}</span>
                                  </div>
                                  <div style={{ width: '125px', whiteSpace: 'nowrap' }}>
                                    <span className='content-filter ellipsis-text'>
                                      {item?.nameSearchFilter ? item.nameSearchFilter : item?.listName.join()}
                                    </span>
                                  </div>
                                </div>
                                <Button
                                  type='link'
                                  className='icon-filter'
                                  onClick={() => this.updateObjectFilterShortList(item)}
                                >
                                  <Icon type='form' style={{ fontSize: 20, color: '#ffffff' }} />
                                </Button>
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </div>
                      )}
                      {((appState?.activitiesShortListFilter.length <= 1 && selectedTabs === '2') ||
                        (appState?.experiencesShortListFilter.length <= 4 && selectedTabs === '1')) && (
                        <Button className='new-add-filter' onClick={this.showPopupAddFilter}>
                          <Icon type='plus' style={{ fontSize: 20, color: '#ffffff' }} />
                          Add Filter
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className='shortlist-content-table'>
                    {selectedTabs === '1' ? (
                      <Droppable droppableId='allExperiences'>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListSelectStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <div id='scrollableDiv' style={{ height: 'calc(100vh - 220px)', overflow: 'auto' }}>
                              <InfiniteScroll
                                dataLength={allExperiences?.length}
                                next={this.fetchMoreDataExperiences}
                                hasMore={true}
                                loader={<h4></h4>}
                                scrollableTarget='scrollableDiv'
                              >
                                <div className='list'>
                                  {allExperiences?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            index
                                          )}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem='Experiences'
                                            showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                                            item={item}
                                            hideApplyAll={true}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </div>
                              </InfiniteScroll>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ) : (
                      <Droppable droppableId='allActivities'>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListSelectStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <div id='scrollableDiv' style={{ height: 'calc(100vh - 220px)', overflow: 'auto' }}>
                              <InfiniteScroll
                                dataLength={allActivities?.length}
                                next={this.fetchMoreDataActivities}
                                hasMore={true}
                                loader={<h4></h4>}
                                scrollableTarget='scrollableDiv'
                              >
                                <div className='list'>
                                  {allActivities?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            index
                                          )}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem='Activities'
                                            showPopupResearchPan={(item) => this.showPopupDetailActivitiesFun(item)}
                                            item={item}
                                            hideApplyAll={true}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </div>
                              </InfiniteScroll>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </div>
                  {showPopupAddFilter && (
                    <Filter
                      onClosePopUpFilter={this.hidePopupAddFilter}
                      selectedTabs={selectedTabs}
                      filterShortList={filterShortList}
                      addFilterShortList={(list, typeTabs) => this.addFilterShortList(list, typeTabs)}
                    />
                  )}
                  {showUpdateFilterShortList && (
                    <EditFilter
                      selectedTabs={selectedTabs}
                      onUpdateFilterShortList={this.onUpdateFilterShortList}
                      onClosePopUpFilter={this.hidePopupAddFilter}
                      onDeleteFilterShortList={this.onDeleteFilterShortList}
                      objectFilter={objectFilter}
                      addFilterShortList={(list, typeTabs) => this.addFilterShortList(list, typeTabs)}
                    />
                  )}
                  {showPopupDetailActivities && (
                    <ResearchPanDetailActivities
                      item={itemResearchPan}
                      onClosePopUpReSearchPan={this.hidePopupResearchPanDetailActivities}
                    />
                  )}
                  {showDetailExperiences && (
                    <ExperiencesDetail
                      item={itemDetailExperiences}
                      onClosePopUpDetailExperiencesAndActivities={this.hidePopupDetailExperiences}
                    />
                  )}
                </Content>
              </Layout>
            </DragDropContext>
          </Layout>
        </Layout>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin,
    plan: state.plan,
    experiences: state.experiences,
    activities: state.activities,
    appState: state.appState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideMenu: (showMenu) => dispatch(hideMenu(showMenu)),
    backFromScreen: (screen) => dispatch(backFromScreen(screen)),
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    getTags: () => dispatch(getTags()),
    saveToShortlist: (body) => dispatch(saveToShortlist(body)),
    getActivities: (pageIndex, objectFilter) => dispatch(getActivities(pageIndex, objectFilter)),
    getExperiences: (pageIndex, objectFilter) => dispatch(getExperiences(pageIndex, objectFilter)),
    getSuppliers: () => dispatch(getSuppliers()),
    saveListFilterExperiences: (list) => dispatch(saveListFilterExperiences(list)),
    saveListFilterActivities: (list) => dispatch(saveListFilterActivities(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
