import React from 'react';
import { Empty } from 'antd';

export const emptyData = {
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Result" />,
};

export const convertUsernameToAvatar = (text = 'AA') => {
  let avatar = [];
  if (Array.isArray(text)) {
    return text;
  }
  for (let i = 0; i < 2; i++) {
    avatar.push(text.charAt(i).toUpperCase());
  }
  return avatar;
};

export const randomColor = () => {
  const colorClasses = [
    'color-green-haze',
    'color-cerulean',
    'color-bouquet',
    'color-gray',
    'color-corn',
    'color-mango-tango',
    'color-alizarin-crimson',
  ];
  return colorClasses[Math.floor(Math.random() * 7)];
};
