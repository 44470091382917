import Config from '../common/config';
import {api} from './api';

const planService = `${Config.HOST}`;
const templateService = `${Config.HOST}`;

export const getPlansApi = (params) => {
  return api.get(planService, params);
};

export const updatePlansApi = (params) => {
  return api.post(planService, params);
};

export const savePlanItemApi = (params) => {
  return api.post(planService, params);
};

export const saveEditCellApi = (params) => {
  return api.post(planService, params);
};

export const savePlanProgramApi = (params) => {
  return api.post(planService, params);
};

export const addPlanApi = (params) => {
  return api.post(planService, params);
};

export const getPlanDetailApi = (params) => {
  return api.get(planService + params);
}

export const getPlanItemsApi = (params) => {
  return api.get(planService + params);
}

export const getPlanProgramsApi = (params) => {
  return api.get(planService + params);
}

export const removePlanApi = (params) => {
  return api.post(planService, params);
}

export const duplicatePlanApi = (params) => {
  return api.post(planService, params);
}

export const getPlanItemDetailApi = (params) => {
  return api.get(planService + params);
}

export const importDataApi = (params) => {
  return api.get(planService + params);
}

export const getSeasonalTemplateApi = (params) => {
  return api.get(templateService, params);
};
export const generatePDFFlyerApi = (params) => {
  return api.get(templateService, params);
};
export const previewPDFFlyerApi = (params) => {
  return api.get(templateService, params);
};
export const publishPDFFlyerApi = (params) => {
  return api.get(templateService, params);
};