import { notification } from 'antd';
import { getUserAddApi, getUserGroupsApi, resetPasswordApi, removeUsersAddApi, updateUsersAddApi, addUserAddApi } from '../../services/userServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetUserAddAction = {
  type: ACTION_TYPES.GET_USER_ADD,
};

export const failGetUserAddAction = (data) => {
  return {
    type: ACTION_TYPES.GET_USER_ADD_FAIL,
    error: data,
  };
};

export const successGetUserAddAction = (data) => {
  return {
    type: ACTION_TYPES.GET_USER_ADD_SUCCESS,
    listUserAdd: data,
  };
};

const requestGetUserGroupsAction = {
  type: ACTION_TYPES.GET_USER_GROUPS,
};

export const failGetUserGroupsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_USER_GROUPS_FAIL,
    error: data,
  };
};

export const successGetUserGroupsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_USER_GROUPS_SUCCESS,
    listUserGroups: data,
  };
};

export const failResetPasswordAction = (data) => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_FAIL,
    error: data,
  };
};

const requestRemoveUsersAddAction = {
  type: ACTION_TYPES.REMOVE_USER_ADD,
};

export const failRemoveUsersAddAction = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_USER_ADD_FAIL,
  };
};

export const successRemoveUsersAddAction = (idUserRemove) => {
  return {
    type: ACTION_TYPES.REMOVE_USER_ADD_SUCCESS,
    idUserRemove: idUserRemove,
  };
};


const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

export const getListUserAdd = () => {
  return (dispatch) => {
    dispatch(requestGetUserAddAction);
    return getUserAddApi().then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetUserAddAction(data.users));
            return data.users;
          } else {
            notifyFail(failGetUserAddAction('Get User Add Fail'), dispatch);
          }
        },
        () => notifyFail(failGetUserAddAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export const getListUserGroups = () => {
  return (dispatch) => {
    dispatch(requestGetUserGroupsAction);
    getUserGroupsApi().then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetUserGroupsAction(data.userGroups));
          } else {
            notifyFail(failGetUserGroupsAction('Get User Groups Fail'), dispatch);
          }
        },
        () => notifyFail(failGetUserGroupsAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const processResetPasswordApi = (dataJson, dispatch) => {
  if (dataJson && dataJson.success) {
    openNotificationWithIcon('Reset Password Success', 'Notification', ALERT_TYPE.SUCCESS);
    dispatch({
      type: ACTION_TYPES.RESET_PASSWORD_SUCCESS,
    });
    return;
  }
  openNotificationWithIcon('Reset Password Fail', 'Notification', ALERT_TYPE.ERROR);
  notifyFail(failResetPasswordAction('Reset Password Fail'), dispatch);
};

export const resetPassword = (email) => {
  return (dispatch) => {
    const body = {query: `mutation{resetPassword(email: "${email}") {email}}`}
    dispatch({
      type: ACTION_TYPES.RESET_PASSWORD,
    });
    const dataJson = resetPasswordApi(body);
    return dataJson.then((data) => processResetPasswordApi(data, dispatch));
  };
};

export const removeUsersAdd = (idUserRemove) => {
  return (dispatch) => {
    dispatch(requestRemoveUsersAddAction);
    const body = {query: `mutation{removeUser(id: ${idUserRemove}) {id}}`}
    removeUsersAddApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Remove Users Success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successRemoveUsersAddAction(data.removeUser.id));
          } else {
            openNotificationWithIcon('Remove Users Fail', 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failRemoveUsersAddAction('Get User Groups Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failRemoveUsersAddAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestUpdateUsersAddAction = {
  type: ACTION_TYPES.UPDATE_USER_ADD,
};

export const failUpdateUsersAddAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAIL,
  };
};

export const successUpdateUsersAddAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SUCCESS,
    dataUpdateRowUser: data
  };
};

export const updateUserAdd = (params, typeUpdate) => {
  return (dispatch) => {
    dispatch(requestUpdateUsersAddAction);
    let body = {};
    if (typeUpdate) {
      body = {query: `mutation{updateUser(id: "${params.id}", name: "${params.name}", group_id: "${params.group_id}") {id, name email group_id}}`}
    } else {
      body = {query: `mutation{updateUser(id: "${params.id}", name: "${params.name}", email: "${params.email}", group_id: "${params.group_id}") {id, name email group_id}}`}
    }
    return updateUsersAddApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successUpdateUsersAddAction(data?.updateUser));
            return data;
          } else {
            notifyFail(failUpdateUsersAddAction('Update Fail'), dispatch);
            openNotificationWithIcon('Update User Fail', 'Notification', ALERT_TYPE.ERROR);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateUsersAddAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestAddUsersAction = {
  type: ACTION_TYPES.ADD_USER,
};

export const failAddUsersAction = (data) => {
  return {
    type: ACTION_TYPES.ADD_USER_FAIL,
  };
};

export const successAddUsersAction = (data) => {
  return {
    type: ACTION_TYPES.ADD_USER_SUCCESS,
    addUser: data
  };
};

export const addUsers = (name, email, group, password ) => {
  return (dispatch) => {
    dispatch(requestAddUsersAction);
    const body = {query: `mutation{addUser(email: "${email}", password: "${password}", name: "${name}", group_id: ${group} ,is_admin: ${1}, is_active : ${1}) {id, email, password, name, is_active, is_admin, group_id }}`};
    return addUserAddApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successAddUsersAction(data?.addUser));
            return dataJson.data;
          } else {
            notifyFail(failAddUsersAction('Add User Fail'), dispatch);
            openNotificationWithIcon('Add User Fail', 'Notification', ALERT_TYPE.ERROR);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateUsersAddAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

