module.exports = {
  HOST: 'https://planningapi.teamkids.com.au/api',
  HOST_UPLOAD: 'https://planningapi.teamkids.com.au/uploads/',
  HOST_UPLOAD_TEMPLATE_IMAGE: 'https://planningapi.teamkids.com.au/uploads/template_images/',
  HOST_TEMP: 'https://planningapi.teamkids.com.au/',
  DEBUG: true,
  AUTH_USER_NAME: 'CarSharing',
  AUTH_PASSWORD: 'SpeEBkoW3JzVwHYv',
  MENU_HOME: 0,
  MENU_USER: 1,
  ACTIVE: 1,
  INACTIVE: 2,
};
