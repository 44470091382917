import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Button, Modal, Row, Col} from 'antd';
import { STATUS_EXPERIENCES } from '../../common/constant'



import './styles.css';

class Index extends Component {
  state = {
    classNameBg: ''  
  };
  componentDidMount() {
    const {item} = this.props;
    this.setCardStyle(item);
  }

  componentDidUpdate(prevProps) {
    const {item} = this.props;
    if (prevProps.item.type !== item.type) {
      this.setCardStyle(item);
    }
  }

  setCardStyle = (item) => {
    if(item.type === "InHouse" || item.type === STATUS_EXPERIENCES.find(status => status.name === "InHouse").id) {
      this.setState({
        classNameBg: 'bg-left-item-experiences'
      })
    } else if(item.type === 'Excursion' || item.type === STATUS_EXPERIENCES.find(status => status.name === "Excursion").id) {
      this.setState({
        classNameBg: 'bg-left-item-experiences-excursion'
      }) 
    } else {
      this.setState({
        classNameBg: "bg-left-item-experiences-incursion"
      })
    } 
  }

  showConfirm = (props) => {
    Modal.confirm({
      title: 'Notification',
      content: 'Do you want to apply all items?',
      onOk() {
        props.applyAll();
      },
      onCancel() {},
    });
  }

  showPopUp = (item) => {
    const {showPopupResearchPan} = this.props;
    showPopupResearchPan(item);
  }

  removeItem = (item) => {
    const {removeItem, planItem} = this.props;
    removeItem(item, planItem);
  }

  render() {
    const {item, hideApplyAll, typeItem, typeCss, closeButton, planItem} = this.props;
    return (
      <div className="container-activity-and-experiences">
        <Row>
          <Col span={1}>
            {typeItem === "Experiences" 
            ?
            <div className={this.state.classNameBg}/>
            :
            <div className='bg-left-item-activities'/>
            }
          </Col>
          <Col span={16}>
            <div className={typeCss ? 'content-center-item-experiences-item-short-list' : 'content-center-item-experiences'}>
              <div className={typeCss ? 'title-item-experiences-short-list' : 'title-item-experiences'}>
                {item.name}
              </div>
              { item?.supplier && item?.supplier?.id ? 
                (
                  <div>
                    <div className={`content-item-experiences ${item?.supplier?.state && item?.supplier?.zone ? 'show' : 'hide'}`}>
                      {item?.supplier?.state + "/" + item?.supplier?.zone}
                    </div>
                    <div className='content-item-experiences'>
                      {item?.supplier?.name}
                    </div>
                  </div>
                ) : ""
              }
              {/* <div className='content-item-experiences'>
                {item.tags.join()}
              </div> */}
            </div>
          </Col>
          <Col span={7}>
          { hideApplyAll 
            ?
            <div className='bg-right-item-experiences'>
              <Button type='link' className={`button-remove-item ${closeButton && this.props.userLogin.objectUser.group_id !== 4 && this.props.userLogin.objectUser.group_id !== 5 && this.props.planInfo?.status !== 'Archived' ? 'show' : 'hide'}`} onClick={() => this.removeItem(item)} disabled={planItem?.statusLockPlanItem}>
                <Icon type='close' style={{ color: 'red', fontSize: '20px' }} size={13}/>
              </Button>
              <Button type='link' className={`button-info-circle button-info-circle-shortlist ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : 'show'}`} onClick={() => this.showPopUp(item)} disabled={planItem?.statusLockPlanItem}>
                <Icon type='info-circle' style={{ color: '#111', fontSize: '20px' }} size={13} />
              </Button>
            </div> 
            :
            <div className='bg-show-apply-all-right-item-experiences'>
              <Button type='link' className={`button-remove-item ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || this.props.planInfo?.status == 'Archived' ? 'hide' : 'show'}`} onClick={() => this.removeItem(item)} disabled={planItem?.statusLockPlanItem}>
                <Icon type='close' style={{ color: 'red', fontSize: '20px' }} size={13}/>
              </Button>
              <Button type='link' className={`button-info-circle ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : 'show'}`} onClick={() => this.showPopUp(item)} disabled={planItem?.statusLockPlanItem}>
                <Icon type='info-circle' style={{ color: '#111', fontSize: '20px' }} size={13} />
              </Button>
              <Button type='link' className={`button-apply-all ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 || this.props.planInfo?.status == 'Archived' ? 'hide' : 'show'}`} onClick={() => this.showConfirm(this.props)} disabled={planItem?.statusLockPlanItem}>Apply all</Button>
            </div>
          } 
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userLogin: state.userLogin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
