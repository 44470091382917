import React, { Component } from 'react';
import { Table, Button, Form, Select, Input, Modal, DatePicker, Upload, Tree } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getListPlan, updatePlan, duplicatePlan, importMasterData, getSeasonalTemplate, generatePDFFlyer, previewPDFFlyer, publishPDFFlyer} from './actions';
import { getPlanPrograms2 } from './editPlan/actions'
import { removePlanItem } from '../../views/home/addPlan/actions';
import { createPlan } from './addPlan/actions'
import { MENU_ITEMS, PLAN_STATUS, ROUTE, DATE_FORMART } from '../../common/constant';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { importData, exportData, updateTemplate } from '../../components/importExport/actions';
import { showLoading, offLoading } from '../appState/actions';
import config, { HOST_UPLOAD_TEMPLATE_IMAGE } from '../../common/config';

import './styles.css';
import _, { isBuffer } from 'lodash';

const EditableContext = React.createContext();
const { Option } = Select;
const statusPlan = [
  {id: 2, name: 'Active'},
  {id: 3, name: 'Archived'},
  {id: 1, name: 'Draft'},
  // {id: 4, name: 'Delete'},
];

const exportPlanOptions = [
  {id: 1, name: 'Export Option'},  
  {id: 3, name: 'Flyer Data'},
  {id: 4, name: 'Fully Booked'},
  {id: 2, name: 'Master Timetable'},
]

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={(node) => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };

  render() {
    const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
    return (
      <td {...restProps}>
        {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
      </td>
    );
  }
}

/**
 * the class Index
 */
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlans: [],
      archivedPlans: [],
      visibleModalNewPlan: false,
      isDuplicatePlan: false,
      duplicatePlanId: 0,
      visibleImportFullyBooked: false,
      fileList: [],
      planId: 0,
      importType: '',
      importTitle: '',
      isLoading: false,
      visibleModalSeasonalTemplate: false,
      template: {},
      visibleModelGenerateFlyer: false,
      planNameGenerateFlyer: '',
      planIdGenerateFlyer: 0,
      listPdfFiles: [],
      uploadFileMessage: '',
      listPrograms: [],
      selectedProgramFlyerPDF: []
    };
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.showLoading();
    this.props.getListPlan().then(() => {
      const currentPlans = this.props.plans.listPlan?.filter((item) => {
        return item.status === 'Active' || item.status === 'Draft';
      });
      const archivedPlans = this.props.plans.listPlan?.filter((item) => {
        return item.status === 'Archived';
      });
      this.setState({
        currentPlans,
        archivedPlans,
      });
      this.props.offLoading();
    });
    this.props.getSeasonalTemplate().then(() => {
      let template = this.props.plans?.seasonalTemplate || {
        closed_day_image: [],
        footer_image_left: [],
        footer_image_right: [],
        header_image_right: [],
        public_holiday_image: [],
        half_page_promo_image: []
      }

      if(template){
        if(template.closed_day_image && template.closed_day_image.length > 0){
          template.closed_day_image = [{
            uid: '-1',
            status: 'done',
            name: template.closed_day_image,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.closed_day_image}`,
          }]
        }
        if(template.footer_image_left && template.footer_image_left.length > 0){
          template.footer_image_left = [{
            uid: '-1',
            status: 'done',
            name: template.footer_image_left,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.footer_image_left}`,
          }]
        }
        if(template.footer_image_right && template.footer_image_right.length > 0){
          template.footer_image_right = [{
            uid: '-1',
            status: 'done',
            name: template.footer_image_right,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.footer_image_right}`,
          }]
        }
        if(template.header_image_right && template.header_image_right.length > 0){
          template.header_image_right = [{
            uid: '-1',
            status: 'done',
            name: template.header_image_right,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.header_image_right}`,
          }]
        }
        if(template.public_holiday_image && template.public_holiday_image.length > 0){
          template.public_holiday_image = [{
            uid: '-1',
            status: 'done',
            name: template.public_holiday_image,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.public_holiday_image}`,
          }]
        }
        if(template.half_page_promo_image && template.half_page_promo_image.length > 0){
          template.half_page_promo_image = [{
            uid: '-1',
            status: 'done',
            name: template.half_page_promo_image,
            url: `${HOST_UPLOAD_TEMPLATE_IMAGE}${template.half_page_promo_image}`,
          }]
        }
        this.props.form.setFieldsValue({
          header_bg_color: template.header_bg_color || '',
          inhouse_bg_color: template.inhouse_bg_color || '',
          incursion_bg_color: template.incursion_bg_color || '',
          excursion_bg_color: template.excursion_bg_color || '',
          theme_1_bg_color: template.theme_1_bg_color || '',
          theme_2_bg_color: template.theme_2_bg_color || '',
          theme_3_bg_color: template.theme_3_bg_color || '',
          footer_bg_color: template.footer_bg_color || '',
          sub_footer_bg_color: template.sub_footer_bg_color || '',
          header_font_color: template.header_font_color || '',
          heading_1_font_color: template.heading_1_font_color || '',
          heading_2_font_color: template.heading_2_font_color || '',
          heading_3_font_color: template.heading_3_font_color || '',
          theme_1_font_color: template.theme_1_font_color || '',
          theme_2_font_color: template.theme_2_font_color || '',
          theme_3_font_color: template.theme_3_font_color || '',
          footer_font_color: template.footer_font_color || '',
          sub_footer_font_color: template.sub_footer_font_color || '',
          header_slash_color: template.header_slash_color || '',
          note_section: template.note_section || '',
        });
      }
      
      this.setState({
        template
      });
    })
  }

  getStatus = (status) => {
    if (status !== 1 && status !== 2 && status !== 3) {
      switch (status) {
        case "Archived":
          return 3;
        case "Active":
          return 2;
        default:
          return 1;
      }
    }
  };

  handleSave = row => {
    const rowBody = {
      id: row.id,
      name: row.name,
      status: this.getStatus(row.status),
    };
    this.props.updatePlan(rowBody).then((data) => {
      window.location.reload(true);
    });
  };

  handleChangeSelect = (record, value) => {
    const rowBody = {
      id: record.id,
      name: record.name,
      status: value,
    };
    if (value === 4) {
      this.props.removePlanItem(record.id).then(() => {
        window.location.reload(true);
      });
    } else {
      this.props.updatePlan(rowBody).then((data) => {
        window.location.reload(true);
      });
    }
  }

  handleExportPlan = (record, value) => {
    if(value === 2) {
      this.props.showLoading();
      this.props.exportData(MENU_ITEMS.HOLIDAY_PERIOD_PLAN, record.id).then((data) => {
        if (data) {
          window.location.href = data?.export_plan?.path;
        }
        this.props.offLoading();
      })
    } else if(value === 3) {
      this.props.showLoading();
      this.props.exportData(MENU_ITEMS.HOLIDAY_PERIOD_PLAN_V2, record.id).then((data) => {
        if (data) {
          window.location.href = data?.export_plan_v2?.path;
        }
        this.props.offLoading();
      })
    }  else if(value === 4) {
      this.props.showLoading();
      this.props.exportData(MENU_ITEMS.FULLYBOOKED, record.id).then((data) => {
        if (data) {
          window.location.href = data?.export_fullybooked?.path;
        }
        this.props.offLoading();
      })
    }
    
  };

  duplicatePlan = (record) => {
    this.props.form.setFieldsValue({
      planName: record.name + '_copy',
      startDate: moment(record.start_date),
      endDate: moment(record.end_date),
      cssHourlyRate: record.css_hourly_rate,
      feeRef: record.fee_ref
    });
    this.setState({
      visibleModalNewPlan: true,
      isDuplicatePlan: true,
      duplicatePlanId: record.id
    });
  };

  generatePDFFlyer = (record) => {
    if(record.id != this.state.planIdGenerateFlyer){
      this.setState({
        listPdfFiles: []
      })
    }
    this.setState({
      visibleModelGenerateFlyer: true,
      planNameGenerateFlyer: record.name,
      planIdGenerateFlyer: record.id
    })
  }

  closeModalGenerateFlyer = () => {
    this.setState({
      visibleModelGenerateFlyer: false,
      listPrograms: [],
      selectedProgramFlyerPDF: []
    });
  };

  openPreview = (keys, info) => {
    if(info.node.props.isLeaf)
      window.open(`${config.HOST_TEMP}temps/plan_${this.state.planIdGenerateFlyer}/${info.node.props.title}`, '_blank', 'noreferrer');
  }

  programSelectedChange = (value) => {
    this.setState({
      selectedProgramFlyerPDF: value
    });
  };

  publishPDFFlyer = () => {
    this.props.publishPDFFlyer(this.state.planIdGenerateFlyer)
    this.setState({
      visibleModelGenerateFlyer: false,
    });    
  }

  submitGenerateDraftPDFFlyer = () => {
    this.props.generatePDFFlyer(this.state.planIdGenerateFlyer, this.state.selectedProgramFlyerPDF, 2)
    this.setState({
      visibleModelGenerateFlyer: false,
      listPrograms: [],
      selectedProgramFlyerPDF: []
    }); 
  }

  submitReviewPDFFlyer = () => {
    this.props.showLoading();
    this.props.previewPDFFlyer(this.state.planIdGenerateFlyer).then(() => {
      let files = this.props.plans.flyerPDFFiles
      if (files.length) {
        let child = []
        files.forEach((file, i) => {
          child.push({
            title: file,
            key: `0-0-${i}`,
            isLeaf: true,
          })
        });

        let treeData = [
          {
            title: this.state.planNameGenerateFlyer,
            key: '0-0',
            isLeaf: false,
            children: child,
          },
        ];

        this.setState({
          listPdfFiles: treeData,
          selectedProgramFlyerPDF: [],
          listPrograms: []
        }); 
      }
      this.props.offLoading();
    })
  }

  showListPlanPrograms = () => {
    this.props.showLoading();
    this.props.getPlanPrograms(this.state.planIdGenerateFlyer).then((data) => {
      let programs = [{
        name: "All Programs",
        id: 0
      }]
      programs = programs.concat(data)
      this.setState({
        listPrograms: programs,
        listPdfFiles: []
      }); 
      this.props.offLoading();
    })
  }
  
  // previewPDFFlyer = () => {
  //   this.props.showLoading();
  //   this.props.generatePDFFlyer(this.state.planIdGenerateFlyer, true).then((data) => {
  //     this.setState({
  //       previewUrl: data
  //     })
  //     this.props.offLoading();
  //   })
  // }

  handleCreatePlan = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['planName', 'startDate', 'endDate', 'cssHourlyRate', 'feeRef'], (err, values) => {
      if (!err) {
        const plan = {
          planName: values.planName,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
          status: PLAN_STATUS.DRAFT,
          cssHourlyRate: values.cssHourlyRate || 0,
          feeRef: values.feeRef || ''
        }
        if (this.state.isDuplicatePlan) {
          plan.id = this.state.duplicatePlanId;
          this.props.duplicatePlan(this.props, plan);
          return;
        }
        this.props.createPlan(this.props, plan);
      }
    });
  };

  handleUpdateTemplate = (e) => {
    e.preventDefault();
    this.props.form.validateFields([
      'header_bg_color', 'inhouse_bg_color', 'incursion_bg_color', 'excursion_bg_color', 'theme_1_bg_color', 'theme_2_bg_color', 'theme_3_bg_color',
      'footer_bg_color', 'sub_footer_bg_color', 'header_font_color', 'heading_1_font_color', 'heading_2_font_color', 'heading_3_font_color',
      'theme_1_font_color', 'theme_2_font_color', 'theme_3_font_color', 'footer_font_color', 'sub_footer_font_color', 'note_section', 'header_slash_color'
    ], (err, values) => {
      // if (file1 instanceof File && file2 instanceof File && file3 instanceof File && file4 instanceof File && file5 instanceof File && file6 instanceof File) {
        const postData = {
          header_image_right: this.state.template?.header_image_right,
          footer_image_left: this.state.template?.footer_image_left,
          footer_image_right: this.state.template?.footer_image_right,
          half_page_promo_image: this.state.template?.half_page_promo_image,
          public_holiday_image: this.state.template?.public_holiday_image,
          closed_day_image: this.state.template?.closed_day_image,
          header_bg_color: values.header_bg_color || '',
          inhouse_bg_color: values.inhouse_bg_color || '',
          incursion_bg_color: values.incursion_bg_color || '',
          excursion_bg_color: values.excursion_bg_color || '',
          theme_1_bg_color: values.theme_1_bg_color || '',
          theme_2_bg_color: values.theme_2_bg_color || '',
          theme_3_bg_color: values.theme_3_bg_color || '',
          footer_bg_color: values.footer_bg_color || '',
          sub_footer_bg_color: values.sub_footer_bg_color || '',
          header_font_color: values.header_font_color || '',
          heading_1_font_color: values.heading_1_font_color || '',
          heading_2_font_color: values.heading_2_font_color || '',
          heading_3_font_color: values.heading_3_font_color || '',
          theme_1_font_color: values.theme_1_font_color || '',
          theme_2_font_color: values.theme_2_font_color || '',
          theme_3_font_color: values.theme_3_font_color || '',
          footer_font_color: values.footer_font_color || '',
          sub_footer_font_color: values.sub_footer_font_color || '',
          note_section: values.note_section || '',
          header_slash_color: values.header_slash_color || '',
        }

        this.props.updateTemplate(postData).then(data => {
          if (data) {
            this.setState({
              visibleModalSeasonalTemplate: false,
            })
          }
        });
      // }
    });
  };

  closeModalNewPlan = () => {
    this.props.form.setFieldsValue({
      planName: '',
      startDate: null,
      endDate: null,
      cssHourlyRate: null,
      feeRef: ''
    });
    this.setState({
      visibleModalNewPlan: false,
      isDuplicatePlan: false,
      duplicatePlanId: 0
    });
  };

  showModalNewPlan = () => {
    this.setState({
      visibleModalNewPlan: true,
    });
  };

  showModalSeasonalTemplate = () => {
    this.setState({
      visibleModalSeasonalTemplate: true,
    });
  };

  closeModalSeasonalTemplate = () => {
    this.setState({
      visibleModalSeasonalTemplate: false,
    });
  };

  importData = () => {
    this.props.showLoading();
    this.props.importMasterData().then((res) => {
      console.log(res);
      if (res) {
        this.props.getListPlan().then(() => {
          const currentPlans = this.props.plans.listPlan?.filter((item) => {
            return item.status === 'Active' || item.status === 'Draft';
          });
          const archivedPlans = this.props.plans.listPlan?.filter((item) => {
            return item.status === 'Archived';
          });
          this.setState({
            currentPlans,
            archivedPlans,
          });
          this.props.offLoading();
        });
      } else {
        this.props.offLoading();
      }

    });
  }

  closeModalImport = () => {
    this.setState({
      visibleImportFullyBooked: false,
      fileList: [],
      planId: 0,
      uploadFileMessage: ''
    });
  }

  importFullyBooked = (record, type) => {
    const importTitle = this.getImportTitle(type);
    this.setState({
      importType: type,
      visibleImportFullyBooked: true,
      planId: record.id,
      importTitle,
    });
  }

  beforeUploadCSVFile = (file) => {
    const isCSV = file.type === 'text/csv';
    let message = '';
    if (!isCSV) {
      message = 'You can only upload CSV file!'
    }
    this.setState({
      uploadFileMessage: message
    });
    return isCSV;
  }

  submitImportFullyBooked = () => {
    const file = this.state.fileList[0] && this.state.fileList[0]?.originFileObj;
    if (file instanceof File) {
      if (file.type !== 'text/csv'){
        return false
      }
      let title = MENU_ITEMS.IMPORT;
      if (this.state.importType === 'estNumber') {
        title = MENU_ITEMS.IMPORT_EST_NUMBER;
      } else if (this.state.importType === 'expInfo') {
        title = MENU_ITEMS.IMPORT_EXP_INFO;
      }
      this.props.importData(title, file, this.state.planId).then(data => {
        if (data) {
          if (this.state.importType === 'fullyBooked') {
            const currentPlans = this.state.currentPlans.map(pl => {
              if (pl.id == this.state.planId) {
                pl.is_importing_fully_booked = 1
              }
              return pl
            })
            this.setState({
              visibleImportFullyBooked: false,
              fileList: [],
              planId: 0,
              importingFullyBooked: true,
              currentPlans,
            })
          }
          if (this.state.importType === 'estNumber') {
            const currentPlans = this.state.currentPlans.map(pl => {
              if (pl.id == this.state.planId) {
                pl.is_importing_est_number = 1
              }
              return pl
            })
            this.setState({
              visibleImportFullyBooked: false,
              fileList: [],
              planId: 0,
              importingFullyBooked: true,
              currentPlans,
            })
          }
          if (this.state.importType === 'expInfo') {
            // const currentPlans = this.state.currentPlans.map(pl => {
            //   if (pl.id == this.state.planId) {
            //     pl.is_importing_est_number = 1
            //   }
            //   return pl
            // })
            this.setState({
              visibleImportFullyBooked: false,
              fileList: [],
              planId: 0,
              importingFullyBooked: true,
              // currentPlans,
            })
          }
        }
      });
    }
  }

  handleUpload = (info) => {
    this.setState({
      fileList: [info.file]
    })
  };

  handleUploadHeaderImage = (info) => {
    let template = this.state.template
    if(template){
      template.header_image_right = [info.file]
    }
    this.setState({
      template
    })
  };

  handleUploadBottomLeftImage = (info) => {
    let template = this.state.template
    if(template){
      template.footer_image_left = [info.file]
    }
    this.setState({
      template
    })
  };

  handleUploadBottomRightImage = (info) => {
    let template = this.state.template
    if(template){
      template.footer_image_right = [info.file]
    }
    this.setState({
      template
    })
  };

  handleUploadHalfPagePromopImage = (info) => {
    let template = this.state.template
    if(template){
      template.half_page_promo_image = [info.file]
    }
    this.setState({
      template
    })
  };

  handleUploadPublicHolidayImage = (info) => {
    let template = this.state.template
    if(template){
      template.public_holiday_image = [info.file]
    }
    this.setState({
      template
    })
  };

  handleUploadClosedDayImage = (info) => {
    let template = this.state.template
    if(template){
      template.closed_day_image = [info.file]
    }
    this.setState({
      template
    })
  };

  customRequest = ({ onSuccess, onError, file }) => {
    const checkInfo = () => {
      setTimeout(() => {
        onSuccess(null, file);
      }, 100);
    };

    checkInfo();
  };

  getImportTitle = (importType) => {
    let title = "Upload fully booked";
    if (importType === 'estNumber') {
      title = 'Upload estimated number'
    } else if (importType === 'expInfo') {
      title = 'Upload experience information'
    }
    return title;
  }

  disableImport = (isImporting) => {
    return isImporting;
  }

  /**
   * render View
   * @return {Component}
   */
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {currentPlans, archivedPlans, visibleModalNewPlan, visibleImportFullyBooked, visibleModalSeasonalTemplate, fileList, importTitle, visibleModelGenerateFlyer, planNameGenerateFlyer, uploadFileMessage} = this.state;
    const { appState } = this.props;
    currentPlans.sort((a, b) => moment(b.modified_date).unix() - moment(a.modified_date).unix());
    archivedPlans.sort((a, b) => moment(b.modified_date).unix() - moment(a.modified_date).unix());
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    this.columns = [
      {
        title: 'Status',
        key: 'planStatus',
        dataIndex: 'status',
        width: '10%',
        render: (text, record) => (
          <div className='button-wrapper'>
            <Select 
              defaultValue={record.status} 
              style={{ width: 100 }} 
              onChange={(value) => this.handleChangeSelect(record, value)}
              disabled={this.props.userLogin.objectUser.group_id === 3 || this.props.userLogin.objectUser.group_id === 4 || this.props.userLogin.objectUser.group_id === 5}
            >
              {statusPlan.map((item) => (
                <Option key={item.id} value={item.id} label={item.name}>
                  <div>{item.name}</div>
                </Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        title: 'Program Name',
        key: 'planName',
        dataIndex: 'name',
        width: '20%',
        editable: this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? false : true,
        render: (text, record) => (
        <div>
          <Link className='program-name' to={ROUTE.LAYOUT + ROUTE.EDIT_PLAN + `/${record.id}`}>
            {record.name}
          </Link>
        </div>
        )
      },
      {
        title: 'Start Date',
        key: 'planStartDate',
        dataIndex: 'startDate',
        width: '15%',
        align: 'center',
        render: (text, record) => (
          <div className='button-wrapper'>
            {record.start_date}
          </div>
        ),
      },
      {
        title: 'End Date',
        key: 'planEndDate',
        dataIndex: 'endDate',
        width: '15%',
        align: 'center',
        render: (text, record) => (
          <div className='button-wrapper'>
            {record.end_date}
          </div>
        ),
      },
      {
        title: 'Last Modified',
        key: 'planLastModified',
        dataIndex: 'lastModified',
        width: '15%',
        align: 'center',
        render: (text, record) => (
          <div className='button-wrapper'>
            {record.modified_date}
          </div>
        ),
      },
      {
        title: 'Actions',
        key: 'planActions',
        dataIndex: 'operation',
        width: '25%',
        render: (text, record) => (
          <div className={`button-wrapper`}>
            <Button variant='primary' className={this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : ''} onClick={() => this.duplicatePlan(record)}> Duplicate </Button>
            &nbsp;
            <Select 
              className={this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : ''}
              defaultValue={1} 
              style={{ width: 150 }} 
              onChange={(value) => this.handleExportPlan(record, value)}
            >
              {exportPlanOptions.map((item) => (
                <Option key={item.id} value={item.id} label={item.name}>
                  <div>{item.name}</div>
                </Option>
              ))}
            </Select>
            &nbsp;
            <Button className={`${record.status === 'Active' ? 'show' : 'hide'}`} disabled={ this.props.userLogin.objectUser.group_id === 4 } onClick={() => this.generatePDFFlyer(record)}> Generate Flyers </Button>
            &nbsp;
            <Button className={`${record.status === 'Active'  && !(this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5) ? 'show' : 'hide'}`} disabled={this.disableImport(record.is_importing_fully_booked)} onClick={() => this.importFullyBooked(record, 'fullyBooked')}> Import Fully Booked </Button>
            &nbsp;
            <Button className={`${record.status === 'Draft'  && !(this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5) ? 'show' : 'hide'}`} disabled={this.disableImport(record.is_importing_est_number)} onClick={() => this.importFullyBooked(record, 'estNumber')}> Import est number </Button>
            &nbsp;
            <Button className={`${!(this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5) ? 'show' : 'hide'}`} onClick={() => this.importFullyBooked(record, 'expInfo')}> Import Exp Info </Button>
          </div>
          
        ),
      },
    ];
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <LoadingOverlay
        active={appState.isLoading}
        spinner
        >
        <div className='container'>
          <Form id="createPlanForm" onSubmit={this.handleCreatePlan}>
            <Form.Item>
              <div className='title-page'>
                Holiday Plan
              </div>
              <div>
                <Button className={`button-new-plan ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : 'show'}`} htmlType='button' onClick={this.showModalNewPlan}>
                  <span className='title-button-new-plan'>New plan</span>
                </Button>
                <Button className={`button-new-plan ${this.props.userLogin.objectUser.group_id === 4  || this.props.userLogin.objectUser.group_id === 5 ? 'hide' : 'show'}`} htmlType='button' onClick={this.importData}>
                  <span className='title-button-new-plan'>Import data</span>
                </Button>
                <Button className='button-seasonal-template' disabled={this.props.userLogin.objectUser.group_id === 4} htmlType='button' onClick={this.showModalSeasonalTemplate}>
                  <span className='title-button-new-plan'>Seasonal Template</span>
                </Button>
              </div>
              <br />
              <div className='form-table-plan'>
                <Table
                  components={components}
                  rowKey={record => record.id}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={currentPlans}
                  columns={columns}
                  size='small'
                  pagination={{pageSize:10}}
                />
              </div>
              <div className='title-archived-plan'>
                Archived
              </div>
              <div className='form-table-plan'>
                <Table
                  components={components}
                  rowKey={record => record.id}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={archivedPlans}
                  columns={columns}
                  size='small'
                  pagination={{pageSize:10}}
                />
              </div>
            </Form.Item>
            <Modal className='modal-create-plan'
              visible={visibleModalNewPlan}
              onCancel={this.closeModalNewPlan}
              title="New Holiday Plan"
              footer={[
                <div style={{textAlign: 'left'}}>
                  <Form.Item>
                    <Button className='button-create' variant="primary" form="createPlanForm" key="submit" htmlType="submit">
                      <span className='title-create'>Create</span>
                    </Button>
                  </Form.Item>
                </div>
              ]}
            >
              <span>Plan Name</span>
              <Form.Item>
                {getFieldDecorator('planName', {
                  rules: [{ required: true, message: 'Please input plan name!' }],
                })(
                  <Input placeholder="Plan Name" style={{width: '95%'}} />
                )}
              </Form.Item>
              <div className='plan-start-end-date'>
                <div style={{display: 'flex', float: 'left', width: '50%'}}>Start</div>
                <div>End</div>
              </div>
              <div className='plan-start-end-date'>
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    rules: [{ 
                      type: 'object', 
                      required: true, 
                      validator: (rule, value) => {
                        if (!value) {
                          return Promise.reject('Please select time!');
                        }
                        var date = moment(value)
                        if(date.day() == 6 || date.day() == 0){
                          return Promise.reject('Start date should be a week day!');
                        }
                        return Promise.resolve();
                      },  
                    }],
                  })(
                    <DatePicker format={DATE_FORMART} style={{width: '90%'}} placeholder="Start Date"/>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('endDate', {
                    rules: [{
                      type: 'object', 
                      required: true, 
                      validator: (rule, value) => {
                        if (!value) {
                          return Promise.reject('Please select time!');
                        }
                        if (value.unix() >= getFieldValue('startDate').unix()) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Require Start Date before End Date!');
                      },
                    },
                      
                    ],
                  })(
                    <DatePicker format={DATE_FORMART} style={{width: '90%'}} placeholder="End Date" />
                  )}
                </Form.Item>
              </div>

              <div className='css-hour-rate-fee-ref'>
                <div style={{display: 'flex', float: 'left', width: '50%'}}>CCS Hourly Rate Cap</div>
                <div>Fee Ref</div>
              </div>
              <div className='css-hour-rate-fee-ref'>
                <Form.Item>
                  {getFieldDecorator('cssHourlyRate', {
                    rules: [{ required: false, }],
                  })(
                    <Input type="number" placeholder="CCS Hourly Rate Cap" style={{width: '90%'}} />
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('feeRef', {
                    rules: [{ required: false, }],
                  })(
                    <Input placeholder="Fee Ref" style={{width: '90%'}} />
                  )}
                </Form.Item>
              </div>
              
            </Modal>
          </Form>
          <Modal className='modal-import-csv'
              visible={visibleImportFullyBooked}
              onCancel={this.closeModalImport}
              title={importTitle}
              footer={[
                <div style={{textAlign: 'center'}}>
                  <Form.Item>
                    <Button className='button-create' onClick={this.submitImportFullyBooked} disabled={fileList.length === 0}>
                      <span className='title-create'>Submit</span>
                    </Button>
                  </Form.Item>
                </div>
              ]}
            >
              <Upload 
                fileList={fileList} 
                onChange={this.handleUpload} 
                accept='.csv' 
                showUploadList={{showDownloadIcon: false, showRemoveIcon: false, showPreviewIcon: false}}
                beforeUpload={this.beforeUploadCSVFile} 
                customRequest={this.customRequest}>
                <Button className='button-new-user' htmlType='button'>
                  <span className='title-button-new-user'>Upload</span>
                </Button>
              </Upload>
              <div>
                <span style={{color: 'red', marginLeft: 10}}>{uploadFileMessage}</span>
              </div>
            </Modal>
            <Form id="updateTemplate" onSubmit={this.handleUpdateTemplate}>
            <Modal className='modal-develop-template'
              visible={visibleModalSeasonalTemplate}
              onCancel={this.closeModalSeasonalTemplate}
              title="Develop Seasonal Template"
              width={1000}
              footer={[
                <div style={{textAlign: 'left'}} key={false}>
                  <Form.Item>
                    <Button className='button-create' variant="primary" form="updateTemplate" key="submit" htmlType="submit">
                      <span className='title-create'>Save</span>
                    </Button>
                  </Form.Item>
                </div>
              ]}
            >
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Header Top/Right Graphic</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Footer Bottom/Left Graphic</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Footer Bottom/Right Graphic</div>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.header_image_right} onChange={this.handleUploadHeaderImage} customRequest={this.customRequest}>
                    {this.state.template?.header_image_right?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.footer_image_left} onChange={this.handleUploadBottomLeftImage} customRequest={this.customRequest}>
                    {this.state.template?.footer_image_left?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.footer_image_right} onChange={this.handleUploadBottomRightImage} customRequest={this.customRequest}>
                    {this.state.template?.footer_image_right?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Header BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Footer BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Sub-footer BG Color</div>
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('header_bg_color', {
                    //rules: [{ required: true, message: 'Please input Header BG Color!' }],
                  })(
                    <Input placeholder="Header BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('footer_bg_color', {
                    // rules: [{ required: true, message: 'Please input Footer BG Color!' }],
                  })(
                    <Input placeholder="Footer BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('sub_footer_bg_color', {
                    // rules: [{ required: true, message: 'Please input Sub-footer BG Color!' }],
                  })(
                    <Input placeholder="Sub-footer BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>In-House BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Incursion BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Excursion BG Color</div>                
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('inhouse_bg_color', {
                    // rules: [{ required: true, message: 'Please input In-House BG Color!' }],
                  })(
                    <Input placeholder="In-House BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('incursion_bg_color', {
                    // rules: [{ required: true, message: 'Please input Incursion BG Color!' }],
                  })(
                    <Input placeholder="Incursion BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('excursion_bg_color', {
                    // rules: [{ required: true, message: 'Please input Excursion BG Color!' }],
                  })(
                    <Input placeholder="Excursion BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 1 BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 2 BG Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 3 BG Color</div>
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('theme_1_bg_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 1 BG Color!' }],
                  })(
                    <Input placeholder="Theme Colour 1 BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('theme_2_bg_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 2 BG Color!' }],
                  })(
                    <Input placeholder="Theme Colour 2 BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('theme_3_bg_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 3 BG Color!' }],
                  })(
                    <Input placeholder="Theme Colour 3 BG Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Header Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Footer Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Sub-footer Font Color</div>
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('header_font_color', {
                    // rules: [{ required: true, message: 'Please input Header Font Color!' }],
                  })(
                    <Input placeholder="Header Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('footer_font_color', {
                    // rules: [{ required: true, message: 'Please input Footer Font Color!' }],
                  })(
                    <Input placeholder="Footer Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('sub_footer_font_color', {
                    // rules: [{ required: true, message: 'Please input Sub-footer Font Color!' }],
                  })(
                    <Input placeholder="Sub-footer Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Heading 1 Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Heading 2 Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Heading 3 Font Color</div>
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('heading_1_font_color', {
                    // rules: [{ required: true, message: 'Please input Heading 1 Font Color!' }],
                  })(
                    <Input placeholder="Heading 1 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('heading_2_font_color', {
                    // rules: [{ required: true, message: 'Please input Heading 2 Font Color!' }],
                  })(
                    <Input placeholder="Heading 2 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('heading_3_font_color', {
                    // rules: [{ required: true, message: 'Please input Heading 3 Font Color!' }],
                  })(
                    <Input placeholder="Heading 3 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 1 Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 2 Font Color</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Theme Colour 3 Font Color</div>
              </div>
              <div className='css-one-column'>
                <Form.Item>
                  {getFieldDecorator('theme_1_font_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 1 Font Color!' }],
                  })(
                    <Input placeholder="Theme Colour 1 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('theme_2_font_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 2 Font Color!' }],
                  })(
                    <Input placeholder="Theme Colour 2 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('theme_3_font_color', {
                    // rules: [{ required: true, message: 'Please input Theme Colour 3 Font Color!' }],
                  })(
                    <Input placeholder="Theme Colour 3 Font Color" style={{width: '75%'}} />
                  )}
                </Form.Item>
              </div>
              <span>Header Slash Color</span>
              <Form.Item>
                {getFieldDecorator('header_slash_color', {
                  //rules: [{ required: true, message: 'Please input Header BG Color!' }],
                })(
                  <Input placeholder="Header Slash Color" style={{width: '25%'}} />
                )}
              </Form.Item>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Half-page Promo</div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Public Holiday </div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>Closed Day </div>
              </div>
              <div className='css-one-column'>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.half_page_promo_image} onChange={this.handleUploadHalfPagePromopImage} customRequest={this.customRequest}>
                    {this.state.template?.half_page_promo_image?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.public_holiday_image} onChange={this.handleUploadPublicHolidayImage} customRequest={this.customRequest}>
                    {this.state.template?.public_holiday_image?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
                <div style={{display: 'flex', float: 'left', width: '33.33%'}}>
                  <Upload listType="picture-card" fileList={this.state.template?.closed_day_image} onChange={this.handleUploadClosedDayImage} customRequest={this.customRequest}>
                    {this.state.template?.closed_day_image?.length >= 2 ? null : 'UPLOAD'}
                  </Upload>
                </div>
              </div>
              <span>Notes</span>
              <Form.Item>
                {getFieldDecorator('note_section', {
                  // rules: [{ required: true, message: 'Please input Note content!' }],
                })(
                  <Input placeholder="Note content" style={{width: '95%', height: 120}}  />
                )}
              </Form.Item>
            </Modal>
            </Form>
            <Modal className='modal-gen-flyer'
              visible={visibleModelGenerateFlyer}
              onCancel={this.closeModalGenerateFlyer}
              title="PDF Flyer generation"
              key={1}
              footer={[
                <div style={{textAlign: 'left'}}>
                  <Form.Item>
                    {/* <Button className='button-create' onClick={this.previewPDFFlyer}>
                      <span className='title-create'>Preview</span>
                    </Button> */}
                    <Button className='button-create' onClick={this.publishPDFFlyer}>
                      <span className='title-create'>Publish</span>
                    </Button>
                    {
                      this.state.listPrograms.length == 0 ?
                        <Button className='button-gen-flyer' onClick={this.showListPlanPrograms}>
                          <span className='title-create'>Create Drafts</span>
                        </Button>
                      :
                        <Button className='button-gen-flyer' onClick={this.submitGenerateDraftPDFFlyer}>
                          <span className='title-create'>Generate</span>
                        </Button>
                    }
                    
                    <Button className='button-gen-flyer' onClick={this.submitReviewPDFFlyer}>
                      <span className='title-create'>Preview Drafts</span>
                    </Button>
                    <Button className='button-create' style={{float: "right", marginRight: 12 }} onClick={this.closeModalGenerateFlyer}>
                      <span className='title-create'>Cancel</span>
                    </Button>
                  </Form.Item>
                </div>
              ]}
            >
              <p>Generating flyers for <b>{planNameGenerateFlyer}</b> will replace any existing flyers.</p>
              {/* <p>Click "Preview" to generate a random program flyer for review.</p> */}
              <p>Click "Publish" to generate all PDF flyers.</p>
              <p>Click "Create Drafts" to generate draft PDF flyers and preview.</p>
              {/* {this.state.previewUrl ? (<a href={this.state.previewUrl} target="_blank">Preview link</a>) : (<p></p>)} */}
              <Tree.DirectoryTree
                defaultExpandAll
                treeData={this.state.listPdfFiles}
                height={300}
                onSelect={this.openPreview}
              />
              <div className={this.state.listPrograms.length ? 'show' : 'hide'}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select Schools"
                  onChange={this.programSelectedChange}
                  value={this.state.selectedProgramFlyerPDF}
                  optionFilterProp='label'
                >
                  {this.state.listPrograms.map((item) => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    <div>{item.name}</div>
                  </Option>
                ))}
                </Select>
              </div>
            </Modal>
        </div>
      </LoadingOverlay> 
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plans: state.plans,
    appState: state.appState,
    userLogin: state.userLogin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListPlan: () => dispatch(getListPlan()),
    updatePlan: (params) => dispatch(updatePlan(params)),
    createPlan: (props, params) => dispatch(createPlan(props, params)),
    duplicatePlan: (props, params) => dispatch(duplicatePlan(props, params)),
    removePlanItem: (id) => dispatch(removePlanItem(id)),
    importMasterData: () => dispatch(importMasterData()),
    importData: (title, file, planId) => dispatch(importData(title, file, planId)),
    exportData: (title, planId) => dispatch(exportData(title, planId)),
    updateTemplate: (data) => dispatch(updateTemplate(data)),
    getSeasonalTemplate: () => dispatch(getSeasonalTemplate()),
    generatePDFFlyer: (planId, programId, action) => dispatch(generatePDFFlyer(planId, programId, action)),
    previewPDFFlyer: (planId) => dispatch(previewPDFFlyer(planId)),
    publishPDFFlyer: (planId) => dispatch(publishPDFFlyer(planId)),
    getPlanPrograms: (planId) => dispatch(getPlanPrograms2(planId)),
    showLoading: () => dispatch(showLoading()),
    offLoading: () => dispatch(offLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
