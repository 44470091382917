import Config from '../common/config';
import {api} from './api';

const getListActivitiesUrl = `${Config.HOST}`;
const uploadImageUrl = `${Config.HOST}`;

export const getListActivitiesApi = (params) => {
  return api.get(getListActivitiesUrl + params);
}

export const updateActivitiesApi = (params) => {
  return api.post(getListActivitiesUrl, params)
};

export const uploadImageApi = (params) => {
  return api.postMultiplePart(uploadImageUrl, params);
}

