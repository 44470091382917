import {ACTION_TYPES} from './reducer';
import { notification } from 'antd';
import { savePlanItemApi, addPlanApi, savePlanProgramApi, saveEditCellApi, removePlanApi, updatePlansApi } from '../../../services/planServices';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE, ROUTE, PLAN_ITEM_TYPE} from '../../../common/constant';
import { getPlanItemTypeName } from '../../../common/utils';

const requestAddPlanAction = {
  type: ACTION_TYPES.ADD_PLAN,
};

export const failAddPlanAction = (data) => {
  return {
    type: ACTION_TYPES.ADD_PLAN_FAIL,
  };
};

export const successAddPlanAction = (data) => {
  return {
    type: ACTION_TYPES.ADD_PLAN_SUCCESS,
    plan: data,
  };
};

export const createPlan = (props, params) => {
  return (dispatch) => {
    dispatch(requestAddPlanAction);
    const body = {query: `mutation{addPlan(name: "${params.planName}", status: ${params.status}, start_date: "${params.startDate}", end_date: "${params.endDate}", css_hourly_rate: ${params.cssHourlyRate}, fee_ref: "${params.feeRef}") {id, name, status, start_date, end_date, css_hourly_rate, fee_ref}}`}
    return addPlanApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successAddPlanAction(data.addPlan));
            props.history.push(ROUTE.LAYOUT + ROUTE.EDIT_PLAN + `/${data.addPlan.id}`);
          } else {
            openNotificationWithIcon('Add Plan Fail', 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failAddPlanAction('Add Plan Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failAddPlanAction(INTERNAL_SERVER_ERROR), dispatch);
        }
    );
  };
};

export const saveToShortlist = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ADD_SHORT_LIST,
      shortlistByUser: params,
    });
  }
};

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const requestSavePlanItemAction = {
  type: ACTION_TYPES.SAVE_PLAN,
};

export const failSavePlanItemAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_FAIL,
  };
};

export const successSavePlanItemAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_SUCCESS,
    // listPlan: data,
  };
};

export const savePlanItems = (props, params) => {
  return (dispatch) => {
    dispatch(requestSavePlanItemAction);
    const planItems = params.planItems.join();
    const body = {query: `mutation{bulkUpdatePlanItem(plan_id: ${params.planId}, plan_items: [${planItems}]) {id}}`};
    return savePlanItemApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successSavePlanItemAction(data.plans));
            props.history.goBack();
          } else {
            notifyFail(failSavePlanItemAction('Save Plan Fail'), dispatch);
          }
        },
        () => notifyFail(failSavePlanItemAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestSavePlanItemTypeAction = {
  type: ACTION_TYPES.SAVE_PLAN_ITEM,
};

export const failSavePlanItemTypeAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_ITEM_FAIL,
  };
};

export const successSavePlanItemTypeAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_ITEM_SUCCESS,
  };
};

export const savePlanItemType = (type, item) => {
  return (dispatch) => {
    dispatch(requestSavePlanItemTypeAction);
    let action = '';
    if (type === PLAN_ITEM_TYPE.CLOSDED || type === PLAN_ITEM_TYPE.PUBLIC_HOLIDAY) {
      const activity_id_old = item[item.activity][0]?.id || null;
      const experience_id_old = item[item.experience][0]?.id || null;
      action = `, experience_id: null, experience_id_old: ${experience_id_old}, activity_id: null, activity_id_old: ${activity_id_old}`;
    }
    const body = {query: `mutation{updatePlanItem(id: ${item.id}, date_type: ${type}, date_type_old: ${getPlanItemTypeName(item.planItemType)} ${action}) {id, date_type}}`};
    return savePlanItemApi(body).then(
        (dataJson) => {
          if(dataJson && dataJson.success) {
            dispatch(successSavePlanItemTypeAction(item.id));
            return true;
          } else {
            openNotificationWithIcon(dataJson.errors[0].message, 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failSavePlanItemTypeAction('Save Plan item type Fail'), dispatch);
          }
        },
        () => notifyFail(failSavePlanItemTypeAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export const savePlanItemDragDrop = (itemId, newId, oldId, type) => {
  return (dispatch) => {
    dispatch(requestSavePlanItemTypeAction);
    newId = parseInt(newId) || null;
    let body = {query: `mutation{updatePlanItem(id: ${itemId}, experience_id: ${newId}, experience_id_old: ${oldId}) {id}}`};
    if (type === 'activities') {
      body = {query: `mutation{updatePlanItem(id: ${itemId}, activity_id: ${newId}, activity_id_old: ${oldId}) {id}}`};
    }
    return savePlanItemApi(body).then(
        (dataJson) => {
          if(dataJson && dataJson.success) {
            dispatch(successSavePlanItemTypeAction(itemId));
            return true;
          } else {
            openNotificationWithIcon(dataJson.errors[0].message, 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failSavePlanItemTypeAction('Save Plan item type Fail'), dispatch);
          }
        },
        () => notifyFail(failSavePlanItemTypeAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

const requestSavePlanProgramAction = {
  type: ACTION_TYPES.SAVE_PLAN_PROGRAMS,
};

export const failSavePlanProgramAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_PROGRAMS_FAIL,
  };
};

export const successSavePlanProgramAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_PLAN_PROGRAMS_SUCCESS,
  };
};

export const savePlanPrograms = (params) => {
  return (dispatch) => {
    dispatch(requestSavePlanProgramAction);
    const body = {query: `mutation{updatePlanProgram(plan_id: ${params.planId}, program_id: ${params.program_id}, user_id: ${params.user_id}, user_id_old: ${params.user_id_old}) {id, user_id}}`};
    return savePlanProgramApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successSavePlanProgramAction(data.plans));
            return true;
          } else {
            notifyFail(failSavePlanProgramAction('Save Plan Programs Fail'), dispatch);
          }
        },
        () => notifyFail(failSavePlanProgramAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export function addEditFilterPlan(listFilter, selectedTabs) {
  return {
    type: ACTION_TYPES.FILTER_PLAN,
    listFilter,
    selectedTabs,
  };
}

export function showLoading() {
  return {
    type: ACTION_TYPES.SHOW_LOADING,
  };
}

export function offLoading() {
  return {
    type: ACTION_TYPES.OFF_LOADING,
  };
}

const requestSavePlanItemCellAction = {
  type: ACTION_TYPES.SAVE_EDIT_CELL,
};

export const failSavePlanItemCellAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_EDIT_CELL_FAIL,
  };
};

export const successSavePlanItemCellAction = (data) => {
  return {
    type: ACTION_TYPES.SAVE_EDIT_CELL_SUCCESS,
  };
};

export const saveEditCell = (planItem, params) => {
  return (dispatch) => {
    dispatch(requestSavePlanItemCellAction);
    //capacity_number: ${params?.capacity}, capacity_number_old: ${planItem?.capacity}, plan_id: ${params?.planId}, program_id: ${params?.programId}, date_type: ${params?.planItemTypeName}, plan_date: "${params?.planItemDate}", experience_id: ${params?.experience_id}, activity_id: ${params?.activity_id}, estimated_number: ${params?.estimated}, estimated_number_old: ${planItem?.estimated}, actual_number: ${params?.actual}, actual_number_old: ${planItem?.actual}, 
    const pool_time = params?.pool_time ? `"${params?.pool_time}"` : null;
    const pool_time_old = planItem?.pool_time ? `"${planItem?.pool_time}"` : null;
    const exp_start_time = params?.exp_start_time ? `"${params?.exp_start_time}"` : null;
    const exp_start_time_old = planItem?.exp_start_time ? `"${planItem?.exp_start_time}"` : null;
    const duration = params?.duration ? `"${params?.duration}"` : null;
    const duration_old = planItem?.duration ? `"${planItem?.duration}"` : null;
    const reference_no = params?.reference_no ? `"${params?.reference_no}"` : null;
    const reference_no_old = planItem?.reference_no ? `"${planItem?.reference_no}"` : null;
    const additional_info = params?.additional_info ? `"${params?.additional_info}"` : null;
    const additional_info_old = planItem?.additional_info ? `"${planItem?.additional_info}"` : null;
    const supplier_note = params?.supplier_note ? `"${params?.supplier_note}"` : null;
    const supplier_note_old = planItem?.supplier_note ? `"${planItem?.supplier_note}"` : null;
    const bus_departure = params?.bus_departure ? `"${params?.bus_departure}"` : null;
    const bus_departure_old = planItem?.bus_departure ? `"${planItem?.bus_departure}"` : null;
    const travel_time = params?.travel_time ? `"${params?.travel_time}"` : null;
    const travel_time_old = planItem?.travel_time ? `"${planItem?.travel_time}"` : null;
    const leave_on_return = params?.leave_on_return ? `"${params?.leave_on_return}"` : null;
    const leave_on_return_old = planItem?.leave_on_return ? `"${planItem?.leave_on_return}"` : null;
    const bus_arrival = params?.bus_arrival ? `"${params?.bus_arrival}"` : null;
    const bus_arrival_old = planItem?.bus_arrival ? `"${planItem?.bus_arrival}"` : null;
    const bus_size = params?.bus_size ? `"${params?.bus_size}"` : null;
    const bus_size_old = planItem?.bus_size ? `"${planItem?.bus_size}"` : null;
    const staff_to_kids_ratio = planItem?.staff_to_kids_ratio ? `"${planItem?.staff_to_kids_ratio}"` : null;
    const program_session_duration = planItem?.pro_duration ? planItem?.pro_duration : null;
    const exp_fee = params?.exp_fee;
    const activity_fee = params?.activity_fee;
    const base_fee = params?.base_fee;
    const bus_fee = params?.bus_fee;
    const pool_fee = params?.pool_fee;
    const pool_fee_old = planItem?.pool_fee;
    const parent_fee = params?.parent_fee;
    const parent_fee_old = planItem?.parent_fee;
    const subsidised_fee = params?.subsidised_fee;
    const body = {query: `mutation{${params?.id ? `updatePlanItem(id: ${params?.id},` : `addPlanItem(`}pool_time: ${pool_time}, pool_time_old: ${pool_time_old}, 
                          exp_start_time: ${exp_start_time}, exp_start_time_old: ${exp_start_time_old}, duration: ${duration}, duration_old: ${duration_old}, 
                          reference_no: ${reference_no}, reference_no_old: ${reference_no_old}, additional_info: ${additional_info}, additional_info_old: ${additional_info_old}, 
                          supplier_note: ${supplier_note}, supplier_note_old: ${supplier_note_old}, bus_departure: ${bus_departure}, bus_departure_old: ${bus_departure_old}, 
                          travel_time: ${travel_time}, travel_time_old: ${travel_time_old}, leave_on_return: ${leave_on_return}, leave_on_return_old: ${leave_on_return_old}, 
                          bus_arrival: ${bus_arrival}, bus_arrival_old: ${bus_arrival_old}, bus_size: ${bus_size}, bus_size_old: ${bus_size_old}, 
                          staff_to_kids_ratio: ${staff_to_kids_ratio}, 
                          exp_fee: ${exp_fee},
                          activity_fee: ${activity_fee},
                          bus_fee: ${bus_fee},
                          parent_fee: ${parent_fee},parent_fee_old: ${parent_fee_old},
                          pool_fee: ${pool_fee}, pool_fee_old: ${pool_fee_old},
                          base_fee: ${base_fee},
                          subsidised_fee: ${subsidised_fee},
                          program_session_duration: ${program_session_duration},) {id, plan_date}}`};
    return saveEditCellApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successSavePlanItemCellAction(data));
            const planItemId = params?.id || data.addPlanItem.id;
            return planItemId;
          } else {
            openNotificationWithIcon(dataJson.errors[0].message, 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failSavePlanItemCellAction('Save Plan item cell Fail'), dispatch);
          }
        },
        () => notifyFail(failSavePlanItemCellAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};


const requestRemovePlanAction = {
  type: ACTION_TYPES.REMOVE_PLAN,
};

export const failRemovePlanAction = () => {
  return {
    type: ACTION_TYPES.REMOVE_PLAN_FAIL,
  };
};

export const successRemovePlanAction = () => {
  return {
    type: ACTION_TYPES.REMOVE_PLAN_SUCCESS,
  };
};

export const removePlanItem = (params) => {
  return (dispatch) => {
    dispatch(requestRemovePlanAction);
    const body = {query: `mutation{removePlan(id: ${params}) {id}}`}
    return removePlanApi(body).then(
        (dataJson) => {
          if(dataJson && dataJson.success) {
            dispatch(successRemovePlanAction());
            return true;
          } else {
            openNotificationWithIcon('Remove Plan Fail', 'Notification', ALERT_TYPE.ERROR);
            notifyFail(failRemovePlanAction(), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failRemovePlanAction(), dispatch);
        }
    );
  };
};

const requestUpdatePlansAction = {
  type: ACTION_TYPES.UPDATE_PLAN,
};

export const failUpdatePlansAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PLAN_FAIL,
  };
};

export const successUpdatePlansAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PLAN_SUCCESS,
    plan: data
  };
};

export const updatePlanInfo = (params) => {
  return (dispatch) => {
    dispatch(requestUpdatePlansAction);
    const body = {query: `mutation{updatePlan(id: "${params.id}", name: "${params.name}", status: ${params.status}, css_hourly_rate: ${params.cssHourlyRate}, fee_ref: "${params.feeRef}") {id name status start_date end_date created_date modified_date css_hourly_rate fee_ref}}`}
    return updatePlansApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            openNotificationWithIcon('Update Plan Success', 'Notification', ALERT_TYPE.SUCCESS);
            dispatch(successUpdatePlansAction(data.updatePlan));
            return data;
          } else {
            notifyFail(failUpdatePlansAction('Get Plans Fail'), dispatch);
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdatePlansAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};
