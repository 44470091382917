import Config from '../common/config';
import {api} from './api';

const settingsService = `${Config.HOST}`;

export const getSettingsApi = (params) => {
  return api.get(settingsService, params);
};

export const updateSettingsApi = (params) => {
  return api.post(settingsService, params);
};
