import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Tabs, Row, Col, Input, Select } from 'antd';
import './styles.css';

import { getPlanItemDetail } from '../editPlan/actions';
import { getActivityAttendance } from '../../activitiesResources/actions';
import { getExperienceAttendance } from '../../researchPan/detailExperiencesAndActivities/actions';
import { showLoading, offLoading } from '../../appState/actions';

const { TabPane } = Tabs;

class Index extends Component {
  state = {
    classNameBg: '',
    isRounding: true,
    experienceHistoric: {},
    activityhistoric: {},
    planItem: {},
  };

  componentDidMount() {
    const { planItem } = this.props;
    this.props.showLoading();
    this.props.getPlanItemDetail(planItem.planId, planItem.id).then(data => {
      let promiseList = []
      if(data[data.experience]?.[0])
      promiseList.push(this.props.getExperienceAttendance(data[data.experience]?.[0].id, data.programId))
      if(data[data.activity]?.[0])
        promiseList.push(this.props.getActivityAttendance(data[data.activity]?.[0].id, data.programId))
      Promise.all(promiseList)
        .then((values) => {
          const experienceHistoric = values[0][0]
          const activityhistoric = values[1][0]
          const { setFieldsValue } = this.props.form;
          setFieldsValue({...data});
          const item = JSON.parse(JSON.stringify(data));
          item.planName = planItem.planName;
          item.planItemDate = planItem.planItemDate;
          item.programName = planItem.programName;
          this.setState({ experienceHistoric, activityhistoric, planItem: item });
          this.props.offLoading();
        })
        .catch((reason) => {
          this.props.offLoading();
        });
    });
  }

  render() {
    const { isShow, closeModalEditPlanItem, userLogin } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { planItem } = this.state;

    let experienceContent = '';
    if (planItem?.[planItem?.experience]?.[0]?.fullybooked_desc && planItem?.[planItem?.experience]?.[0]?.flyer_desc) {
      experienceContent = planItem[planItem?.experience]?.[0]?.fullybooked_desc + ", " + planItem[planItem?.experience]?.[0]?.flyer_desc;
    } else if (planItem?.[planItem?.experience]?.[0]?.fullybooked_desc) {
      experienceContent = planItem?.[planItem?.experience]?.[0]?.fullybooked_desc;
    } else if (planItem?.[planItem?.experience]?.[0]?.flyer_desc) {
      experienceContent = planItem?.[planItem?.experience]?.[0]?.flyer_desc;
    }

    return (
      <Modal className='modal-edit-plan-item'
        visible={isShow}
        onCancel={closeModalEditPlanItem}
        onOk={closeModalEditPlanItem}
        title={`${planItem?.programName + " (" + planItem?.planItemDate})`}
        width="52%"
        footer={[
          <Button className='button-save-edit-cell' type="default" onClick={closeModalEditPlanItem}>
            <span className='title-create'>Close</span>
          </Button>
        ]}
      >
        <Form id="editPlanItemForm" onSubmit={this.handleEditPlanItem}>
          <div className="edit-plan-container">
            <Row>
              <Col className="references-and-fees" span={8}>
                <div className='preference'>
                  <div className="bold">Experiences</div>
                  <span>Start times</span>
                  <Form.Item>
                    {getFieldDecorator('exp_start_time', {
                      initialValue: planItem?.exp_start_time,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 10:30 AM' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Duration</span>
                  <Form.Item>
                    {getFieldDecorator('duration', {
                      initialValue: planItem?.duration,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 1.25 hours' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Reference No.</span>
                  <Form.Item>
                    {getFieldDecorator('reference_no', {
                      initialValue: planItem?.reference_no,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='Reference No.' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Additional Info</span>
                  <Form.Item>
                    {getFieldDecorator('additional_info', {
                      initialValue: planItem?.additional_info,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Experience notes</span>
                  <Form.Item>
                    {getFieldDecorator('supplier_note', {
                      initialValue: planItem?.supplier_note,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='' disabled={true}></Input>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="references-and-fees" span={8} hidden={userLogin.objectUser.group_id === 4 && planItem?.[planItem?.experience]?.[0]?.type != 'Excursion'}>
                <div className='preference'>
                  <div className="bold">Excursion</div>
                  <span>Bus departure</span>
                  <Form.Item>
                    {getFieldDecorator('bus_departure', {
                      initialValue: planItem?.bus_departure,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 9:30 AM' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Travel time (Minutes)</span>
                  <Form.Item>
                    {getFieldDecorator('travel_time', {
                      initialValue: planItem?.travel_time,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 45 or 115' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Leave on Return</span>
                  <Form.Item>
                    {getFieldDecorator('leave_on_return', {
                      initialValue: planItem?.leave_on_return,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 1:30 PM' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Bus Arrival</span>
                  <Form.Item>
                    {getFieldDecorator('bus_arrival', {
                      initialValue: planItem?.bus_arrival,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 2:30 PM' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Bus Size (seats)</span>
                  <Form.Item>
                    {getFieldDecorator('bus_size', {
                      initialValue: planItem?.bus_size,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='Bus Size (seats)' disabled={true}></Input>
                    )}
                  </Form.Item>
                  <span>Staff:Child Ratio (#)</span>
                  <Form.Item>
                    {getFieldDecorator('staff_to_kids_ratio', {
                      initialValue: planItem?.staff_to_kids_ratio,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 10' disabled={true}></Input>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="references-and-fees" span={8} hidden={userLogin.objectUser.group_id === 4}>
                <div className='preference'>
                  <div className="bold">Pool</div>
                  <span>Start time</span>
                  <Form.Item>
                    {getFieldDecorator('pool_time', {
                      initialValue: planItem?.pool_time,
                      rules: [{ required: false,}],
                    })(
                      <Input placeholder='e.g. 10:30 AM' disabled={true}></Input>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    plan: state.plan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPlanItemDetail: (plan_id, plan_item_id) => dispatch(getPlanItemDetail(plan_id, plan_item_id)),
    getActivityAttendance: (acIt, proId) => dispatch(getActivityAttendance(acIt, proId)),
    getExperienceAttendance: (exIt, proId) => dispatch(getExperienceAttendance(exIt, proId)),
    showLoading: () => dispatch(showLoading()),
    offLoading: () => dispatch(offLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
