export const ACTION_TYPES = {
  EXPORT_DATA: 'EXPORT_DATA',
  EXPORT_DATA_SUCCESS: 'EXPORT_DATA_SUCCESS',
  EXPORT_DATA_FAIL: 'EXPORT_DATA_FAIL',

  IMPORT_DATA: 'IMPORT_DATA',
  IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
  IMPORT_DATA_FAIL: 'IMPORT_DATA_FAIL',

  UPDATE_SEASONAL_TEMPLATE: 'UPDATE_SEASONAL_TEMPLATE',
  UPDATE_SEASONAL_TEMPLATE_SUCCESS: 'UPDATE_SEASONAL_TEMPLATE_SUCCESS',
  UPDATE_SEASONAL_TEMPLATE_FAIL: 'UPDATE_SEASONAL_TEMPLATE_FAIL',
};

const initialState = {
  isLoading: false,
  exportData: {},
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.IMPORT_DATA:
    case ACTION_TYPES.EXPORT_DATA:
    case ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.IMPORT_DATA_FAIL:
    case ACTION_TYPES.EXPORT_DATA_FAIL:
    case ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.EXPORT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exportData: action.exportData,
      };
    case ACTION_TYPES.IMPORT_DATA_SUCCESS:
    case ACTION_TYPES.UPDATE_SEASONAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
