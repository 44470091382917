import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import store, {persistor} from './stores/configureStore';
import {PersistGate} from 'redux-persist/lib/integration/react';
import AdminLayout from './layouts/Admin.jsx';
import StorageKey from './common/storage/Key.js';
import {ROUTE} from './common/constant';

import 'antd/dist/antd.css';
import './assets/css/common/site.css';

const token = localStorage.getItem(StorageKey.TOKEN_KEY);

ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={<div />} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route path="/" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to={token ? ROUTE.LAYOUT + ROUTE.HOME : ROUTE.LAYOUT + ROUTE.LOGIN} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
);
