import React, { Component } from 'react';
import { Row, Col, Form, Select, Modal, Table } from 'antd';
import { connect } from 'react-redux';
import {getActivitiesResources, getActivityAttendance } from '../activitiesResources/actions'
import './styles.css';
import config from '../../common/config';

const { Option } = Select;

/**
 * the class Index
 */
class Index extends Component {

  state = {
    activitiesResources: [],
    activityAttendance: [],
    program: {},
  };

  onChange = (e) => {};

  componentDidMount() {
    const {item} = this.props;
    this.props.getActivitiesResources([item.id], 10, 1).then(data => {
      if(data && data.length > 0) {
        this.setState({activitiesResources : data.filter(itemFilter => itemFilter?.activity?.id == item.id) });
      }
    });
    this.props.getActivityAttendance(item.id).then((data) => {
      this.setState({
        activityAttendance: data
      })
    });
  }

  handleProgramChange = (value) => {
    const program = this.state.activityAttendance.find(item => item.program_id === value)
    this.setState({
      program: JSON.parse(JSON.stringify(program))
    })
  }

  /**
   * render View
   * @return {Component}
   */
  render() {
    const { onClosePopUpReSearchPan } = this.props;
    const {  activityAttendance, program } = this.state;
    const programs = activityAttendance.map( item => {
      return {
        id: item.program_id,
        name: item.program_name
      }
    })
    const columnsRight = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Attendees',
        dataIndex: 'attendees',
        key: 'attendees',
      },
      {
        title: '% Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
      }
    ];
    
    const columns = [
      {
        title: 'Resource',
        dataIndex: 'resource_name',
        key: 'resource_name',
      },
      {
        title: 'Quantity',
        dataIndex: 'resource_per_child',
        key: 'resource_per_child',
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
      },
    ];
    const {item} = this.props;
    const {activitiesResources} = this.state;
    return (
      <span className='container-modal-research-pan'>
        <Form id='createPlanForm'>
          <Modal
            className='modal-modal-research-pan'
            bodyStyle={{ height: '50%'}}
            visible={true}
            width={'80%'}
            onCancel={onClosePopUpReSearchPan}
            title={item?.name}
            footer={[]}
          >
            <span className='contain-modal-research-pan'>
              <Row>
                <Col span={10}>
                  <div className="left-container">
                    <span className="label-popup">Description</span>
                    <div>
                      <span>{item?.content ? item?.content : item?.description}</span>
                    </div>
                    <div className="label">
                      <Table dataSource={activitiesResources} columns={columns} pagination={false}/>
                    </div>
                    <span className="label-popup">Tags</span>
                    <div>
                      <span>{item?.tags.join()}</span>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="left-container">
                    <div style={{ height: 30, display: 'flow-root' }}>
                      <span style={{ fontSize: 20 }}>Key stats</span>
                      <Select
                        defaultValue={'Select Program'}
                        style={{ width: '200px', float: 'right' }}
                        onChange={this.handleProgramChange}
                      >
                        {programs?.map((item) => (
                          <Option key={item.id} value={item.id} label={item.name}>
                            <div>{item.name}</div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div key={program?.program_id} className={`border-table ${program?.program_id ? '' : 'hide'}`}>
                      <div className="text-label">
                        <span className="label-popup">Last run: <span style={{fontWeight: 'bold'}}>{program.last_run}</span></span>
                      </div>
                      <div>
                        <span className="label-popup">Last category run: <span style={{fontWeight: 'bold'}}>{program.last_cat_run}</span></span>
                      </div>
                      <div>
                        <span className="label-popup"> Historic Attendance</span>
                        <div>
                          <Table rowKey={record => record.date} dataSource={program.historic_attendance} columns={columnsRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className={`right-label ${item?.image ? '' : 'hide'}`}>
                    <img src={config.HOST_UPLOAD + item?.image} width='150px' height='150px' alt='' />
                  </div>
                </Col>
              </Row>
           </span>
          </Modal>
        </Form>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivitiesResources: (id, pageSize, pageIndex) => dispatch(getActivitiesResources(id, pageSize, pageIndex)),
    getActivityAttendance: (id) => dispatch(getActivityAttendance(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
