import _ from 'lodash';

export const ACTION_TYPES = {
  GET_EXPERIENCES: 'GET_EXPERIENCES',
  GET_EXPERIENCES_SUCCESS: 'GET_EXPERIENCES_SUCCESS',
  GET_EXPERIENCES_FAIL: 'GET_EXPERIENCES_FAIL',

  UPDATE_EXPERIENCES: 'UPDATE_EXPERIENCES',
  UPDATE_EXPERIENCES_SUCCESS: 'UPDATE_EXPERIENCES_SUCCESS',
  UPDATE_EXPERIENCES_FAIL: 'UPDATE_EXPERIENCES_FAIL',

  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',
};

const initialState = {
  isLoading: false,
  listExperiences: [],
  totalPage: 0,
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_EXPERIENCES:
    case ACTION_TYPES.GET_EXPERIENCES:
    case ACTION_TYPES.UPLOAD_IMAGE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPDATE_EXPERIENCES_SUCCESS:
      const list = _.forEach(state.listExperiences, (item) => {
        if (item.id === action.updateExperiences.id) {
          item.name = action.updateExperiences.name;
          item.type = action.updateExperiences.type;
          item.flyer_desc = action.updateExperiences.flyer_desc;
          item.fullybooked_desc = action.updateExperiences.fullybooked_desc;
          item.cost_per_child = action.updateExperiences.cost_per_child;
          item.rating = action.updateExperiences.rating;
          item.capacity = action.updateExperiences.capacity;
        }
      });
      return {
        ...state,
        isLoading: false,
        listExperiences: list,
      };
    case ACTION_TYPES.UPDATE_EXPERIENCES_FAIL:
    case ACTION_TYPES.UPLOAD_IMAGE_SUCCESS:
    case ACTION_TYPES.UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_EXPERIENCES_FAIL:
      return {
        ...state,
        isLoading: false,
        listExperiences: [],
      };
    case ACTION_TYPES.GET_EXPERIENCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listExperiences: action.listExperiences,
        totalPage: action.totalPage,
      };
    default:
      return state;
  }
}
