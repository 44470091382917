export const ACTION_TYPES = {
  GET_TAGS: 'GET_TAGS',
  GET_TAGS_FAIL: 'GET_TAGS_FAIL',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',

  SHOW_LOADING: 'SHOW_LOADING',
  OFF_LOADING: 'OFF_LOADING',
  STATUS_MENU: 'STATUS_MENU',
  BACK_FROM_SCREEN: 'BACK_FROM_SCREEN',

  FILTER_EXPERIENCES_SORT_LIST: 'FILTER_EXPERIENCES_SORT_LIST',
  FILTER_ACTIVITIES_SORT_LIST: 'FILTER_ACTIVITIES_SORT_LIST',
  FILTER_PLAN: 'FILTER_PLAN',
};

const initialState = {
  isLoading: false,
  listTags: [],
  showMenu: true,
  screen: '',
  experiencesShortListFilter: [],
  activitiesShortListFilter: [],
  filterPlan: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.STATUS_MENU: 
      return {
        ...state,
        showMenu: action.showMenu,
      };
    case ACTION_TYPES.BACK_FROM_SCREEN:
      return {
        ...state,
        screen: action.screen,
      };
    case ACTION_TYPES.GET_TAGS:
    case ACTION_TYPES.SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_TAGS_FAIL:
    case ACTION_TYPES.OFF_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_TAGS_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        listTags: action.listTags,
      };
    case ACTION_TYPES.FILTER_EXPERIENCES_SORT_LIST: 
      return {
        ...state,
        experiencesShortListFilter: action.experiencesShortListFilter,
      };
    case ACTION_TYPES.FILTER_ACTIVITIES_SORT_LIST: 
      return {
        ...state,
        activitiesShortListFilter: action.activitiesShortListFilter,
      };
    case ACTION_TYPES.FILTER_PLAN:
      return {
        ...state,
        filterPlan: action.listFilter,
      };
    default:
      return state;
  }
}
