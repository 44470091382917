module.exports = {
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
  SUPPORT_IMAGE_TYPE_LIST: ['image/jpeg', 'image/png', 'image/jpg'],
  PLAN_ITEMS_TYPE: [
    { id: 1, name: 'Closed' },
    { id: 3, name: 'Curriculum Day' },
    { id: 2, name: 'Operating Day' },
    // { id: 4, name: 'Pool Day' },
    { id: 5, name: 'Public Holiday' }
  ],
  PLAN_ITEM_TYPE: {CLOSDED: 'Closed',OPERATING_DAY: 'OperatingDay',CURRICULUM_DAY: 'CurriculumDay',POOL_DAY: 'PoolDay', PUBLIC_HOLIDAY: 'PublicHoliday' },
  HTTP_RESPONSE: {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401
  },
  ALERT_TYPE: {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
  },
  SCREEN: {
    ADD_EDIT_PLAN: 'ADD_EDIT_PLAN',
    SHORT_LIST_PLAN: 'SHORT_LIST_PLAN'
  },
  ROUTE: {
    LAYOUT: '/team-kids',
    HOME: '/holiday-period-plan',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    HOLIDAY_PERIOD_PLAN: '/holiday-period-plan',
    ADD_PLAN: '/add-plan',
    EDIT_PLAN: '/edit-plan',
    ADD_SHORT_LIST: '/add-short-list',
    SUPPLIERS: '/suppliers',
    EXPERIENCES: '/experiences',
    ACTIVITIES: '/activities',
    ACTIVITY_RESOURCES: '/activity-resources',
    PROGRAMS: '/programs',
    ZONES: '/zones',
    USER: '/users',
    //SETTING: '/settings'
  },
  MENU_ITEMS: {
    HOLIDAY_PERIOD_PLAN: 'Holiday Plan',
    SUPPLIERS: 'Suppliers',
    EXPERIENCES: 'Experiences',
    ACTIVITIES: 'Activities',
    ACTIVITY_RESOURCES: 'Activity Resources',
    PROGRAMS: 'Programs',
    ZONES: 'Zones',
    USERS: 'Users',
    IMPORT: 'Import',
    IMPORT_EST_NUMBER: 'Import Est Number',
    IMPORT_EXP_INFO: 'Import Exp Info',
    HOLIDAY_PERIOD_PLAN_V2: 'Holiday Plan V2',
    FULLYBOOKED: 'Fully Booked',
    //SETTINGS: 'Settings'
  },
  PLAN_STATUS: {
    DRAFT: 1,
    ACTIVE: 2,
    ARCHIVED: 3
  },
  STATUS_EXPERIENCES: [
    { id: 1, name: 'Excursion' },
    { id: 2, name: 'Incursion' },
    { id: 3, name: 'InHouse' }
  ],
  PAGE_SIZE: 7,
  PAGE_SIZE_SHORT_LIST: 60,
  DATE_FORMART: 'DD/MM/YYYY'
};
