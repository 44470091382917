import {PLAN_ITEM_TYPE} from '../common/constant';
export const getPlanItemTypeName = (type) => {
  switch (type) {
    case 2:
      return PLAN_ITEM_TYPE.OPERATING_DAY;
    case 3:
      return PLAN_ITEM_TYPE.CURRICULUM_DAY;
    case 4:
      return PLAN_ITEM_TYPE.POOL_DAY;
    case 5:
      return PLAN_ITEM_TYPE.PUBLIC_HOLIDAY;
    case 1:
    default:
      return PLAN_ITEM_TYPE.CLOSDED;
  }
};