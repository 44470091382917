import React, { Component } from 'react';
import { Table, Button, Form, Select, Input, Spin, Modal } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getListUserAdd, getListUserGroups, 
  resetPassword, removeUsersAdd, updateUserAdd, addUsers } from './actions';

import './styles.css';

const EditableContext = React.createContext();
const { Option } = Select;

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={(node) => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };

  render() {
    const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
    return (
      <td {...restProps}>
        {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
      </td>
    );
  }
}

/**
 * the class Error404
 */
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 2,
      visible: false,
      email: '',
      password: '',
      name: '',
      group: '',

    };
    this.onClickResetPassword = this.onClickResetPassword.bind(this);
    this.onClickRemoveUsersAdd = this.onClickRemoveUsersAdd.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.getListUserAdd();
    this.props.getListUserGroups();
  }

  onClickResetPassword(data) {
    this.props.resetPassword(data.email);
  }

  onClickRemoveUsersAdd(data) {
    this.props.removeUsersAdd(data.id);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleSave = (row) => {
    this.props.updateUserAdd(row, false);
  };

  handleOk = () => {
    const { name, email, group, password } = this.state;
    if(name && email && password && group) {
      this.props.addUsers(name, email, group, password).then((data) => {
        this.setState({
          visible: false,
        });
      });
    } else {
      
    }

  };


  handleChangeSelectGroup = (record, value) => {
    const rowBody = {
      id: record.id,
      name: record.name,
      email: record.email,
      group_id: value,
    };
    this.props.updateUserAdd(rowBody, true).then(data => {
      this.props.getListUserAdd();
    });
  };

  handleChangeSelectGroupAddUser = (value) => {
    this.setState({group: value})
  }

  onChangeName = (e) => {
    this.setState({name: e.target.value})
  }

  onChangeEmail = (e) => {
    this.setState({email: e.target.value})
  }

  onChangePassword = (e) => {
    this.setState({password: e.target.value})
  }
  /**
   * render View
   * @return {Component}
   */
  render() {
    const { userAdd } = this.props;
    const { visible } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '25%',
        editable: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%',
        editable: true,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        width: '25%',
        render: (text, record) => (
          <div className='button-wrapper'>
            <Select
              defaultValue={record.group_id}
              style={{ width: 200 }}
              onChange={(value) => this.handleChangeSelectGroup(record, value)}
            >
              {userAdd.listUserGroups.map((item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        width: '20%',
        render: (text, record) => (
          <div className='button-wrapper'>
            <Button variant='primary' onClick={() => this.onClickResetPassword(record)}>
              {' '}
              Reset password{' '}
            </Button>
            {record?.group_id !== 1 && (
              <Button style={{ marginLeft: 10 }} variant='primary' onClick={() => this.onClickRemoveUsersAdd(record)}>
                {' '}
                Remove{' '}
              </Button>
            )}
          </div>
        ),
      },
    ];
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
        <div className='container'>
          <Form.Item>
            <div>
              <Link style={{ float: 'right' }}>
                <Button className='button-new-user' onClick={this.showModal}>
                  <span className='title-button-new-user'>New user</span>
                </Button>
              </Link>
            </div>
            <br />
            <div className='form-table-user'>
              <Table
                rowKey={record => record.id}
                loading={userAdd?.isLoading ? { indicator: <Spin size='small' /> } : false}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={userAdd.listUserAdd}
                columns={columns}
              />
            </div>
          </Form.Item>
          <Modal title='Add User' visible={visible} onOk={this.handleOk} onCancel={this.handleCancel}>
            <div>
              <Form.Item>
                <Input placeholder='Input Name' onChange={this.onChangeName}/>
                <Input placeholder='Input Email' onChange={this.onChangeEmail}/>
                <Input.Password placeholder='Input Password' onChange={this.onChangePassword}/>
                <Select style={{ width: 200 }} onChange={(value) => this.handleChangeSelectGroupAddUser(value)}>
                  {userAdd.listUserGroups.map((item, index) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAdd: state.userAdd,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListUserAdd: () => dispatch(getListUserAdd()),
    getListUserGroups: () => dispatch(getListUserGroups()),
    resetPassword: (email) => dispatch(resetPassword(email)),
    removeUsersAdd: (idUsersAdd) => dispatch(removeUsersAdd(idUsersAdd)),
    updateUserAdd: (params, type) => dispatch(updateUserAdd(params, type)),
    addUsers: (name, email, group, password ) => dispatch(addUsers(name, email, group, password ))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
