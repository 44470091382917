export const ACTION_TYPES = {
  ADD_PLAN: 'ADD_PLAN',
  ADD_PLAN_FAIL: 'ADD_PLAN_FAIL',
  ADD_PLAN_SUCCESS: 'ADD_PLAN_SUCCESS',

  GET_PLAN_DETAIL: 'GET_PLAN_DETAIL',
  GET_PLAN_DETAIL_FAIL: 'GET_PLAN_DETAIL_FAIL',
  GET_PLAN_DETAIL_SUCCESS: 'GET_PLAN_DETAIL_SUCCESS',

  GET_PLAN_ITEMS: 'GET_PLAN_ITEMS',
  GET_PLAN_ITEMS_FAIL: 'GET_PLAN_ITEMS_FAIL',
  GET_PLAN_ITEMS_SUCCESS: 'GET_PLAN_ITEMS_SUCCESS',

  GET_PLAN_PROGRAMS: 'GET_PLAN_PROGRAMS',
  GET_PLAN_PROGRAMS_FAIL: 'GET_PLAN_PROGRAMS_FAIL',
  GET_PLAN_PROGRAMS_SUCCESS: 'GET_PLAN_PROGRAMS_SUCCESS',

  ADD_SHORT_LIST: 'ADD_SHORT_LIST',

  SAVE_PLAN: 'SAVE_PLAN',
  SAVE_PLAN_SUCCESS: 'SAVE_PLAN',
  SAVE_PLAN_FAIL: 'SAVE_PLAN_FAIL',

  SAVE_EDIT_CELL: 'SAVE_EDIT_CELL',
  SAVE_EDIT_CELL_FAIL: 'SAVE_EDIT_CELL_FAIL',
  SAVE_EDIT_CELL_SUCCESS: 'SAVE_EDIT_CELL_SUCCESS',

  SAVE_PLAN_PROGRAMS: 'SAVE_PLAN_PROGRAMS',
  SAVE_PLAN_PROGRAMS_SUCCESS: 'SAVE_PLAN_PROGRAMS_SUCCESS',
  SAVE_PLAN_PROGRAMS_FAIL: 'SAVE_PLAN_PROGRAMS_FAIL',

  SHOW_LOADING: 'SHOW_LOADING',
  OFF_LOADING: 'OFF_LOADING',
  FILTER_PLAN: 'FILTER_PLAN',

  REMOVE_PLAN: 'REMOVE_PLAN',
  REMOVE_PLAN_SUCCESS: 'REMOVE_PLAN_SUCCESS',
  REMOVE_PLAN_FAIL: 'REMOVE_PLAN_FAIL',

  UPDATE_PLAN: 'UPDATE_PLAN',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAIL: 'UPDATE_PLAN_FAIL',

  SAVE_PLAN_ITEM: 'SAVE_PLAN_ITEM',
  SAVE_PLAN_ITEM_FAIL: 'SAVE_PLAN_ITEM_FAIL',
  SAVE_PLAN_ITEM_SUCCESS: 'SAVE_PLAN_ITEM_SUCCESS',

  GET_PLAN_ITEM_DETAIL: 'GET_PLAN_ITEM_DETAIL',
  GET_PLAN_ITEM_DETAIL_FAIL: 'GET_PLAN_ITEM_DETAIL_FAIL',
  GET_PLAN_ITEM_DETAIL_SUCCESS: 'GET_PLAN_ITEM_DETAIL_SUCCESS'
};

const initialState = {
  isLoading: false,
  planInfo: {},
  shortlistByUser: [],
  filterPlan: [],
  planItemDetail: {}
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_PLAN_SUCCESS:
    case ACTION_TYPES.ADD_PLAN_SUCCESS:
    case ACTION_TYPES.GET_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planInfo: action.plan,
      };
    case ACTION_TYPES.ADD_SHORT_LIST:
      return {
        ...state,
        shortlistByUser: action.shortlistByUser,
      };
    case ACTION_TYPES.UPDATE_PLAN:
    case ACTION_TYPES.REMOVE_PLAN:
    case ACTION_TYPES.SHOW_LOADING:
    case ACTION_TYPES.SAVE_PLAN:
    case ACTION_TYPES.ADD_PLAN:
    case ACTION_TYPES.GET_PLAN_DETAIL:
    case ACTION_TYPES.GET_PLAN_ITEMS:
    case ACTION_TYPES.GET_PLAN_PROGRAMS:
    case ACTION_TYPES.SAVE_PLAN_PROGRAMS:
    case ACTION_TYPES.SAVE_PLAN_ITEM:
    case ACTION_TYPES.GET_PLAN_ITEM_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPDATE_PLAN_FAIL:
    case ACTION_TYPES.REMOVE_PLAN_SUCCESS:
    case ACTION_TYPES.REMOVE_PLAN_FAIL:
    case ACTION_TYPES.OFF_LOADING:
    case ACTION_TYPES.SAVE_PLAN_SUCCESS:
    case ACTION_TYPES.SAVE_PLAN_FAIL:
    case ACTION_TYPES.ADD_PLAN_FAIL:
    case ACTION_TYPES.GET_PLAN_DETAIL_FAIL:
    case ACTION_TYPES.GET_PLAN_ITEMS_FAIL:
    case ACTION_TYPES.GET_PLAN_PROGRAMS_FAIL:
    case ACTION_TYPES.SAVE_PLAN_PROGRAMS_FAIL:
    case ACTION_TYPES.SAVE_PLAN_PROGRAMS_SUCCESS:
    case ACTION_TYPES.SAVE_EDIT_CELL:
    case ACTION_TYPES.SAVE_EDIT_CELL_SUCCESS:
    case ACTION_TYPES.SAVE_EDIT_CELL_FAIL:
    case ACTION_TYPES.SAVE_PLAN_ITEM_FAIL:
    case ACTION_TYPES.SAVE_PLAN_ITEM_SUCCESS:
    case ACTION_TYPES.GET_PLAN_PROGRAMS_SUCCESS:
    case ACTION_TYPES.GET_PLAN_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.FILTER_PLAN:
      return {
        ...state,
        filterPlan: action.listFilter,
      };
    case ACTION_TYPES.GET_PLAN_ITEM_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        planItemDetail: {}
      }
    case ACTION_TYPES.GET_PLAN_ITEM_DETAIL_SUCCESS:
        return {
          ...state,
          isLoading: false,
          planItemDetail: action.planItemDetail
        }
    default:
      return state;
  }
}
