import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Tabs, Row, Col, Input, Checkbox, Table, Select, Rate } from 'antd';
import './styles.css';

import { getPlanItemDetail } from '../editPlan/actions';
import { getActivityAttendance } from '../../activitiesResources/actions';
import { getExperienceAttendance } from '../../researchPan/detailExperiencesAndActivities/actions';
import { showLoading, offLoading } from '../../appState/actions';

const { TabPane } = Tabs;
const { Option } = Select;

class Index extends Component {
  state = {
    classNameBg: '',
    isRounding: true,
    experienceHistoric: {},
    activityhistoric: {},
    planItem: {},
  };

  componentDidMount() {
    const { planItem } = this.props;
    this.props.showLoading();
    this.props.getPlanItemDetail(planItem.planId, planItem.id).then(data => {
      let promiseList = []
      promiseList.push(this.props.getExperienceAttendance(data[data.experience]?.[0].id, data.programId))
      promiseList.push(this.props.getActivityAttendance(data[data.activity]?.[0].id, data.programId))
      Promise.all(promiseList)
        .then((values) => {
          const experienceHistoric = values[0][0]
          const activityhistoric = values[1][0]
          const { setFieldsValue } = this.props.form;
          setFieldsValue({...data});
          const item = JSON.parse(JSON.stringify(data));
          item.planName = planItem.planName;
          item.planItemDate = planItem.planItemDate;
          item.programName = planItem.programName;
          this.setState({ experienceHistoric, activityhistoric, planItem: item });
          this.props.offLoading();
        })
        .catch((reason) => {
          this.props.offLoading();
        });
    });
  }

  onChangeRounding = (event) => {
    this.setState({isRounding: event.target.checked});
  }

  convertToRoundNumber = (number) => {
    return Math.round(number);
  }

  convertToTwoDecimalPlace = (number) => {
    return Number.parseFloat(number).toFixed(2);
  }

  updateFee = (event, fieldName) => {
    const { plan } = this.props;
    const { getFieldValue, setFieldsValue } = this.props.form;

    const exp_fee_total = Number(event.target.value) + Number(getFieldValue('parent_fee')) + Number(getFieldValue('pool_fee')) - Number(getFieldValue(fieldName));
    setFieldsValue({exp_fee_total});

    const parent_fee = fieldName === 'parent_fee' ? Number(event.target.value) : Number(getFieldValue('parent_fee'))
    const daily_total = parent_fee + Number(getFieldValue('base_fee'));
    setFieldsValue({daily_total});

    const cssHourlyRate = Number(plan.planInfo?.css_hourly_rate);
    // let duration = Number(getFieldValue('duration'));
    // duration = duration ? duration : Number(plan.planItemDetail?.pro_duration)
    let duration = Number(plan.planItemDetail?.pro_duration);
    const maxCssFee = cssHourlyRate * duration;
    let afterMaxCSS = 0;
    if (daily_total > maxCssFee) {
      afterMaxCSS = (daily_total - maxCssFee) + (maxCssFee*15/100);
    } else {
      afterMaxCSS = daily_total*15/100;
    }
    setFieldsValue({subsidised_fee: this.convertToTwoDecimalPlace(afterMaxCSS)});
  }

  // updateFeeByDuration = (event) => {
  //   const { plan } = this.props;
  //   const { getFieldValue, setFieldsValue } = this.props.form;
  //   const daily_total = Number(getFieldValue('daily_total'));
  //   const cssHourlyRate = Number(plan.planInfo?.css_hourly_rate);
  //   const duration = event.target.value;
  //   const maxCssFee = cssHourlyRate * duration;
  //   let afterMaxCSS = 0;
  //   if (daily_total > maxCssFee) {
  //     afterMaxCSS = (daily_total - maxCssFee) + (maxCssFee*15/100);
  //   } else {
  //     afterMaxCSS = daily_total*15/100;
  //   }
  //   setFieldsValue({subsidised_fee: this.convertToTwoDecimalPlace(afterMaxCSS)});
  // }

  render() {
    const { isShow, closeModalEditPlanItem, saveEditCell } = this.props;
    const { getFieldDecorator, getFieldsValue, getFieldValue } = this.props.form;
    const { isRounding, experienceHistoric, activityhistoric, planItem } = this.state;

    const cellItem = {...getFieldsValue(), 
      id: planItem?.id,
      planId: planItem?.planId,
      programId: planItem?.programId,
      planItemTypeName: planItem?.planItemTypeName,
      planItemDate: planItem?.planItemDate,
      experience_id: planItem?.[planItem?.experience]?.[0]?.id,
      activity_id: planItem?.[planItem?.activity]?.[0]?.id,
      capacity: planItem?.capacity,
      estimated: planItem?.estimated,
      actual: planItem?.actual,
      activity: planItem?.activity,
      experience: planItem?.experience
    };

    let experienceContent = '';
    if (planItem?.[planItem?.experience]?.[0]?.fullybooked_desc && planItem?.[planItem?.experience]?.[0]?.flyer_desc) {
      experienceContent = planItem[planItem?.experience]?.[0]?.fullybooked_desc + ", " + planItem[planItem?.experience]?.[0]?.flyer_desc;
    } else if (planItem?.[planItem?.experience]?.[0]?.fullybooked_desc) {
      experienceContent = planItem?.[planItem?.experience]?.[0]?.fullybooked_desc;
    } else if (planItem?.[planItem?.experience]?.[0]?.flyer_desc) {
      experienceContent = planItem?.[planItem?.experience]?.[0]?.flyer_desc;
    }

    const columnsRight = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Attendees',
        dataIndex: 'attendees',
        key: 'attendees',
      },
      {
        title: '% Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
      }
    ];

    return (
      <Modal className='modal-edit-plan-item'
        visible={isShow}
        onCancel={closeModalEditPlanItem}
        onOk={() => saveEditCell(planItem, getFieldsValue())}
        title={`${planItem?.programName + " (" + planItem?.planItemDate})`}
        width="80%"
        footer={[
          <Button className='button-save-edit-cell' type="default" onClick={() => saveEditCell(planItem, cellItem)}>
            <span className='title-create'>Save Edit Cell</span>
          </Button>
        ]}
      >
        <Form id="editPlanItemForm" onSubmit={this.handleEditPlanItem}>
          <div className="edit-plan-container">
            <Row>
              <Col className="references-and-fees" span={8}>
                <Tabs type="card">
                  <TabPane tab="Preference" key="3">
                    <div className='preference'>
                      <div className="bold">Experiences</div>
                      <span>Start times</span>
                      <Form.Item>
                        {getFieldDecorator('exp_start_time', {
                          initialValue: planItem?.exp_start_time,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 10:30 AM'></Input>
                        )}
                      </Form.Item>
                      <span>Duration</span>
                      <Form.Item>
                        {getFieldDecorator('duration', {
                          initialValue: planItem?.duration,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 1.25 hours'></Input>
                        )}
                      </Form.Item>
                      <span>Reference No.</span>
                      <Form.Item>
                        {getFieldDecorator('reference_no', {
                          initialValue: planItem?.reference_no,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='Reference No.'></Input>
                        )}
                      </Form.Item>
                      <span>Additional Info</span>
                      <Form.Item>
                        {getFieldDecorator('additional_info', {
                          initialValue: planItem?.additional_info,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder=''></Input>
                        )}
                      </Form.Item>
                      <span>Experience notes</span>
                      <Form.Item>
                        {getFieldDecorator('supplier_note', {
                          initialValue: planItem?.supplier_note,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder=''></Input>
                        )}
                      </Form.Item>
                      {/* <div className="bold">Pool</div>
                      <span>Start time</span>
                      <Form.Item>
                        {getFieldDecorator('pool_time', {
                          initialValue: planItem?.pool_time,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 10:30 AM'></Input>
                        )}
                      </Form.Item> */}
                      <div className="bold">Excursion</div>
                      <span>Bus departure</span>
                      <Form.Item>
                        {getFieldDecorator('bus_departure', {
                          initialValue: planItem?.bus_departure,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 9:30 AM'></Input>
                        )}
                      </Form.Item>
                      <span>Travel time (Minutes)</span>
                      <Form.Item>
                        {getFieldDecorator('travel_time', {
                          initialValue: planItem?.travel_time,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 45 or 115'></Input>
                        )}
                      </Form.Item>
                      <span>Leave on Return</span>
                      <Form.Item>
                        {getFieldDecorator('leave_on_return', {
                          initialValue: planItem?.leave_on_return,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 1:30 PM'></Input>
                        )}
                      </Form.Item>
                      <span>Bus Arrival</span>
                      <Form.Item>
                        {getFieldDecorator('bus_arrival', {
                          initialValue: planItem?.bus_arrival,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 2:30 PM'></Input>
                        )}
                      </Form.Item>
                      <span>Bus Size (seats)</span>
                      <Form.Item>
                        {getFieldDecorator('bus_size', {
                          initialValue: planItem?.bus_size,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='Bus Size (seats)'></Input>
                        )}
                      </Form.Item>
                      <span>Staff:Child Ratio (#)</span>
                      <Form.Item>
                        {getFieldDecorator('staff_to_kids_ratio', {
                          initialValue: planItem?.staff_to_kids_ratio,
                          rules: [{ required: false,}],
                        })(
                          <Input placeholder='e.g. 10' disabled={true}></Input>
                        )}
                      </Form.Item>
                    </div>
                  </TabPane>
                  <TabPane tab="Fees" key="4">
                    <div className="fees">
                      <div className="fees-title">Fees</div>
                      <Form.Item label="Exp Only">
                        {getFieldDecorator('exp_fee', {
                          initialValue: planItem?.exp_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Activity Fee">
                        {getFieldDecorator('activity_fee', {
                          initialValue: planItem?.activity_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Bus Fee">
                        {getFieldDecorator('bus_fee', {
                          initialValue: planItem?.bus_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Exp Fee">
                        {getFieldDecorator('exp_fee_sum', {
                          initialValue: planItem?.exp_fee_sum,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Parent Fee">
                        {getFieldDecorator('parent_fee', {
                          initialValue: planItem?.parent_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" onChange={(e) => this.updateFee(e, 'parent_fee')}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Pool Fee" className="hide">
                        {getFieldDecorator('pool_fee', {
                          initialValue: planItem?.pool_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" onChange={(e) => this.updateFee(e, 'pool_fee')}></Input>
                        )}
                      </Form.Item>
                      <div className="fees-title">Summary</div>
                      <Form.Item label="Base Fee">
                        {getFieldDecorator('base_fee', {
                          initialValue: planItem?.base_fee,
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Exp** Fee">
                        {getFieldDecorator('exp_fee_total', {
                          initialValue: this.convertToTwoDecimalPlace(getFieldValue('exp_fee_total')),
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="Daily Total">
                        {getFieldDecorator('daily_total', {
                          initialValue: this.convertToTwoDecimalPlace(getFieldValue('daily_total')),
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                      <Form.Item label="After Max CCS">
                        {getFieldDecorator('subsidised_fee', {
                          initialValue: this.convertToTwoDecimalPlace(getFieldValue('subsidised_fee')),
                          rules: [{ required: false,}],
                        })(
                          <Input className="fees-item-input" disabled={true}></Input>
                        )}
                      </Form.Item>
                    </div>
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={8}>
                <div className="left-container">
                  <div style={{ height: 30, display: 'flow-root' }}>
                    <span style={{ fontSize: 20 }}>{planItem?.[planItem?.experience]?.[0]?.name}</span>
                    &nbsp;
                    <Rate disabled={true} style={{float: 'right'}} value={planItem?.[planItem?.experience]?.[0]?.rating || 0} />
                  </div>
                  {
                    experienceHistoric?.program_id ? (
                      <div key={experienceHistoric?.program_id} className={`border-table ${experienceHistoric?.program_id ? '' : 'hide'}`}>
                        <div className="text-label">
                          <span className="label-popup">Last run: <span style={{fontWeight: 'bold'}}>{experienceHistoric?.last_run}</span></span>
                        </div>
                        <div>
                          <span className="label-popup">Last category run: <span style={{fontWeight: 'bold'}}>{experienceHistoric?.last_cat_run}</span></span>
                        </div>
                        <div>
                          <span className="label-popup"> Historic Attendance</span>
                          <div>
                            <Table rowKey={record => record.date} dataSource={experienceHistoric?.historic_attendance} columns={columnsRight} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ fontSize: 18, background: 'white', height: 200, width: '100%', marginTop: 10 }}>
                        No attendance data
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col span={8}>
                <div className="left-container">
                  <div style={{ height: 30, display: 'flow-root' }}>
                    <span style={{ fontSize: 20 }}>{planItem?.[planItem?.activity]?.[0]?.name}</span>
                  </div>
                  {
                    activityhistoric?.program_id ? (
                      <div key={activityhistoric?.program_id} className={`border-table ${activityhistoric?.program_id ? '' : 'hide'}`}>
                        <div className="text-label">
                          <span className="label-popup">Last run: <span style={{fontWeight: 'bold'}}>{activityhistoric?.last_run}</span></span>
                        </div>
                        <div>
                          <span className="label-popup">Last category run: <span style={{fontWeight: 'bold'}}>{activityhistoric?.last_cat_run}</span></span>
                        </div>
                        <div>
                          <span className="label-popup"> Historic Attendance</span>
                          <div>
                            <Table rowKey={record => record.date} dataSource={activityhistoric?.historic_attendance} columns={columnsRight} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ fontSize: 18, background: 'white', height: 200, width: '100%', marginTop: 10 }}>
                        No attendance data
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    plan: state.plan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPlanItemDetail: (plan_id, plan_item_id) => dispatch(getPlanItemDetail(plan_id, plan_item_id)),
    getActivityAttendance: (acIt, proId) => dispatch(getActivityAttendance(acIt, proId)),
    getExperienceAttendance: (exIt, proId) => dispatch(getExperienceAttendance(exIt, proId)),
    showLoading: () => dispatch(showLoading()),
    offLoading: () => dispatch(offLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
