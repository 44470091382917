import Config from '../common/config';
import {api} from './api';

const zonesService = `${Config.HOST}`;

export const getZonesApi = (params) => {
  return api.get(zonesService, params);
};

export const updateZonesApi = (params) => {
  return api.post(zonesService, params);
};
