import React, { Component } from 'react';
import { Button, Form, Upload } from 'antd';
import {connect} from 'react-redux';
import {exportData, importData} from './actions';

import './styles.css';

class Index extends Component {
  export = () => {
    this.props.exportData(this.props.title).then(() => {
      window.location.href = this.props.importExport?.exportData?.path;
    });
  };

  handleUpload = (info) => {
    if (info.file.status !== 'uploading') {
      this.props.importData(this.props.title, info.file).then(() => {
        window.location.reload();
      });
    }
  }

  render() {
    const {title} = this.props;
    return (
      <Form.Item>
        <div className='title-page'>
          {title}
        </div>
        <div style={{ float: 'right' }}>
            <Button className='button-new-user' htmlType='button' onClick={this.export}>
              <span className='title-button-new-user'>Export</span>
            </Button>
            <Upload onChange={this.handleUpload}>
              <Button className='button-new-user' htmlType='button'>
                <span className='title-button-new-user'>Import</span>
              </Button>
            </Upload>
        </div>
      </Form.Item>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    importExport: state.importExport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    exportData: (title) => dispatch(exportData(title)),
    importData: (title, file) => dispatch(importData(title, file))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Index));
