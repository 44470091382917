import { ACTION_TYPES } from '../addPlan/reducer';
import { notification } from 'antd';
import { getPlanDetailApi, getPlanItemsApi, getPlanProgramsApi, getPlanItemDetailApi } from '../../../services/planServices';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE, PAGE_SIZE } from '../../../common/constant';

const notifyFail = (funcFai, dispatch) => {
  dispatch(funcFai);
};

const openNotificationWithIcon = (description, title, type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

export const saveToShortlist = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ADD_SHORT_LIST,
      shortlistByUser: params,
    });
  };
};

const requestGetPlanDetailAction = {
  type: ACTION_TYPES.GET_PLAN_DETAIL,
};

export const failGetPlanDetailAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_DETAIL_FAIL,
  };
};

export const successGetPlanDetailAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_DETAIL_SUCCESS,
    plan: data,
  };
};

export const getPlanDetail = (planId) => {
  return (dispatch) => {
    dispatch(requestGetPlanDetailAction);
    const body = `?query={ plans(id: ${planId}){id, name, status, start_date, end_date, css_hourly_rate, fee_ref}}`;
    return getPlanDetailApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetPlanDetailAction(data.plans[0]));
          return data.plans[0];
        } else {
          openNotificationWithIcon('Get Plan Detail Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanDetailAction('Add Plan Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanDetailAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestGetPlanItemsAction = {
  type: ACTION_TYPES.GET_PLAN_ITEMS,
};

export const failGetPlanItemsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_ITEMS_FAIL,
  };
};

export const successGetPlanItemsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_ITEMS_SUCCESS,
    planItems: data,
  };
};

export const getPlanItems = (planId, pageNum) => {
  return (dispatch) => {
    dispatch(requestGetPlanItemsAction);
    // const body = `?query={ plan_items(plan_id: ${planId}){id plan {id name} program {id name} date_type plan_date experience {id name capacity rating created_date modified_date cost_per_child fullybooked_desc flyer_desc type tags image supplier{id name} zone{name state}} activity {id name  description image tags } capacity_number estimated_number actual_number pool_time exp_start_time duration reference_no additional_info supplier_note bus_departure travel_time leave_on_return bus_arrival bus_size staff_to_kids_ratio exp_fee activity_fee base_fee bus_fee pool_fee extras_fee parent_fee subsidised_fee created_date modified_date }}`;
    const body = `?query={ plan_itemsv2(plan_id: ${planId}, page_index: ${pageNum}, page_size: ${PAGE_SIZE}) }`;
    return getPlanItemsApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetPlanItemsAction(data.plan_itemsv2));
          return data.plan_itemsv2;
        } else {
          openNotificationWithIcon('Get Plan Items Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanItemsAction('Add Plan Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanItemsAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

export const filterPlanItems = (planId, nameSearch, listId, pageNum) => {
  return (dispatch) => {
    dispatch(requestGetPlanItemsAction);
    // const body = `?query={ plan_items(plan_id: ${planId}){id plan {id name} program {id name} date_type plan_date experience {id name capacity rating created_date modified_date cost_per_child fullybooked_desc flyer_desc type tags image supplier{id name} zone{name state}} activity {id name  description image tags } capacity_number estimated_number actual_number pool_time exp_start_time duration reference_no additional_info supplier_note bus_departure travel_time leave_on_return bus_arrival bus_size staff_to_kids_ratio exp_fee activity_fee base_fee bus_fee pool_fee extras_fee parent_fee subsidised_fee created_date modified_date }}`;
    let body = `?query={ plan_itemsv2(plan_id: ${planId}, page_index: ${pageNum}, page_size: ${PAGE_SIZE}) }`;
    if (nameSearch && listId && listId.length > 0) {
      body = `?query={ plan_itemsv2(plan_id: ${planId}, program_name: "${nameSearch}", zone_ids: [${listId.join()}], page_index: ${pageNum}, page_size: ${PAGE_SIZE}) }`;
    } else if (nameSearch) {
      body = `?query={ plan_itemsv2(plan_id: ${planId}, program_name: "${nameSearch}", page_index: ${pageNum}, page_size: ${PAGE_SIZE}) }`;
    } else if (listId && listId.length > 0) {
      body = `?query={ plan_itemsv2(plan_id: ${planId}, zone_ids: [${listId.join()}], page_index: ${pageNum}, page_size: ${PAGE_SIZE}) }`;
    }
    return getPlanItemsApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetPlanItemsAction(data.plan_itemsv2));
          return data.plan_itemsv2;
        } else {
          openNotificationWithIcon('Get Plan Items Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanItemsAction('Add Plan Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanItemsAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestGetPlanProgramsAction = {
  type: ACTION_TYPES.GET_PLAN_PROGRAMS,
};

export const failGetPlanProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_PROGRAMS_FAIL,
  };
};

export const successGetPlanProgramsAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_PROGRAMS_SUCCESS,
    planPrograms: data,
  };
};

export const getPlanPrograms = (planId) => {
  return (dispatch) => {
    dispatch(requestGetPlanProgramsAction);
    const body = `?query={ plan_programs(plan_id: ${planId}){plan_id program_id user_id}}`;
    return getPlanProgramsApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetPlanProgramsAction(data?.plan_programs));
          return data?.plan_programs;
        } else {
          openNotificationWithIcon('Get Plan Programs Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanProgramsAction('Get Plan Programs Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanProgramsAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

export const getPlanPrograms2 = (planId) => {
  return (dispatch) => {
    dispatch(requestGetPlanProgramsAction);
    const body = `?query={ plan_programs_2(plan_id: ${planId})}`;
    return getPlanProgramsApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetPlanProgramsAction(data?.plan_programs_2));
          return data?.plan_programs_2;
        } else {
          openNotificationWithIcon('Get Plan Programs Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanProgramsAction('Get Plan Programs Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanProgramsAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestGetPlanItemDetailAction = {
  type: ACTION_TYPES.GET_PLAN_ITEM_DETAIL,
};

export const failGetPlanItemDetailAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_ITEM_DETAIL_FAIL,
  };
};

export const successGetPlanItemDetailAction = (data) => {
  return {
    type: ACTION_TYPES.GET_PLAN_ITEM_DETAIL_SUCCESS,
    planItemDetail: data,
  };
};

export const getPlanItemDetail = (plan_id, plan_item_id) => {
  return (dispatch) => {
    dispatch(requestGetPlanItemDetailAction);
    const body = `?query={ plan_itemsv2(plan_id: ${plan_id}, plan_item_id: ${plan_item_id}) }`;
    return getPlanItemDetailApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          const planItemDetail = data?.plan_itemsv2?.items?.[0]?.planDates?.[0];
          dispatch(successGetPlanItemDetailAction(planItemDetail));
          return planItemDetail;
        } else {
          openNotificationWithIcon('Get plan item detail Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetPlanItemDetailAction('Get plan item detail Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetPlanItemDetailAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
}
