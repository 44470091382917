import React, { Component } from 'react';
import { Button, Form, Select, Modal, Input, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { getListZones } from '../../zones/actions';
import { STATUS_EXPERIENCES, SCREEN } from '../../../common/constant';
import { saveListFilterExperiences, saveListFilterActivities, addEditFilterPlan } from '../../appState/actions';
import _ from 'lodash';

import './styles.css';

const { Option } = Select;
const TYPE = ['name', 'type', 'supplier', 'zone', 'tags'];

/**
 * the class Index
 */
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'name',
      filterType: {
        supplier: [],
        zone: [],
        tags: [],
      },
      filterTypeAll: {
        supplier: [],
        zone: [],
        tags: [],
      },
      listFilterSelected: [],
      listName: [],
      listId: [],
      nameSearchFilter: '',
      dataListIdInit: [],
      checkAll: false,
      checkedList: [],
    };
  }

  componentDidMount() {
    const {
      updateFilterPlan,
      zones,
      appState,
      suppliers,
      editFilter,
      screen,
      filterShortList,
      selectedTabs
    } = this.props;
    const supplier = suppliers?.suppliers?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const zone = zones?.listZones?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const tags = appState?.listTags?.map((item, index) => {
      item.index = index + 1;
      return item;
    }) || [];
    const filterTypeAll = {supplier, zone, tags};
    if (screen === SCREEN.ADD_EDIT_PLAN) {
      const filterType = appState.filterPlan?.map(item => item.type);
      const type = editFilter ? editFilter.type : (filterType.includes('name') ? 'zone' : 'name');
      const checkedListId = filterTypeAll[type]?.filter((item) => {
        return updateFilterPlan?.listId?.includes(item.id);
      })?.map(item => item.index);
      this.setState({
        listName: updateFilterPlan?.listName || [],
        listId: updateFilterPlan?.listId || [],
        dataListIdInit: updateFilterPlan?.listId || [],
        selected: type,
        nameSearchFilter: updateFilterPlan?.nameSearchFilter,
        filterType: JSON.parse(JSON.stringify(filterTypeAll)),
        filterTypeAll,
        checkedList: checkedListId || [],
        checkAll: updateFilterPlan?.listId?.length === filterTypeAll[type]?.length ? true : false,
      });
    } else {
      const filterType = filterShortList[selectedTabs]?.map(item => item.type);
      const type = selectedTabs === '1' ? TYPE.filter((item) => {
        if (filterType.includes(item)) {
          return false;
        }
        return true;
      })[0] || 'name' : filterType.includes('name') ? 'tags' : 'name';
      const checkedListId = filterTypeAll[type]?.filter((item) => {
        return filterShortList[selectedTabs]?.listId?.includes(item.id);
      })?.map(item => item.index);
      this.setState({
        listName: filterShortList[selectedTabs]?.listName || [],
        listId: filterShortList[selectedTabs]?.listId || [],
        dataListIdInit: filterShortList[selectedTabs]?.listId || [],
        selected: type,
        nameSearchFilter: filterShortList[selectedTabs]?.nameSearchFilter,
        filterType: Object.assign({}, filterTypeAll),
        filterTypeAll: filterTypeAll,
        checkedList:  checkedListId || [],
        checkAll: filterShortList[selectedTabs]?.listId?.length === filterTypeAll[type]?.length ? true : false,
      });
    }
  }

  onChange = (e) => {};

  onChangeActivities = (e) => {};

  handleChangeSelectExperiences = (value) => {
    this.setState({ selected: value, listName: [], listId: [], checkAll: false, checkedList: []});
  };

  handleChangeSelectActivities = (value) => {
    const {updateFilterPlan} = this.props;
    if (updateFilterPlan) {
      if (value === 'zone') {
        this.setState({ selected: value, listName: updateFilterPlan?.listName || [], listId: updateFilterPlan?.listId || [], checkAll: false, checkedList: []});
      } else {
        this.setState({ selected: value, nameSearchFilter: updateFilterPlan?.nameSearchFilter, checkAll: false, checkedList: []});
      }
    } else {
      this.setState({ selected: value, listName: [], listId: [], checkAll: false, checkedList: [] });
    }
  };

  addFilter = () => {
    const { selected, listName, listId, nameSearchFilter, checkedList, filterTypeAll } = this.state;
    const {appState, selectedTabs, screen} = this.props;
    const checkedListTmp = filterTypeAll[selected]?.filter(item => {
      return checkedList.includes(item.index);
    });
    const names = selected === 'zone' || selected === 'supplier' || selected === 'tags' ? checkedListTmp?.map(item => {
      if (selected === 'zone') {
        return item.name + "/" + item.state;
      }
      return item.name;
    }) : listName;
    const ids = selected === 'zone' || selected === 'supplier' || selected === 'tags' ? checkedListTmp?.map(item => {
      return item.id;
    }) : listId;
    const data = {
      type: selected,
      listName: names,
      listId: ids,
      nameSearchFilter: nameSearchFilter
    }
    if (screen === SCREEN.ADD_EDIT_PLAN) {
      let listFilter = appState?.filterPlan || [];
      this.changeListFilter(data, listFilter);

      this.props.addEditFilterPlan(listFilter);
      this.props.handleFilterPlan(selectedTabs);
    } else {
      if (selectedTabs === '1') {
        let list = appState?.experiencesShortListFilter || [];
        this.changeListFilter(data, list);
        this.props.saveListFilterExperiences(list);
        this.props.addFilterShortList(list, selectedTabs);
      } else {
        const list = appState?.activitiesShortListFilter || [];
        this.changeListFilter(data, list);
        this.props.saveListFilterActivities(list);
        this.props.addFilterShortList(list, selectedTabs);
      }
    }
    
    this.props.onClosePopUpFilter();
  }

  changeListFilter = (data, list) => {
    const index = list.findIndex((item) => item.type === this.state.selected);
     if(index >= 0) {
      if(list[index].listId.length > 0 || list[index].nameSearchFilter) {
        list.splice(index, 1, data);
      } else {
        list.splice(index, 1);
      }
    } else {
      list.push(data);
    }
  }

  deleteFilter = () => {
    const { selected } = this.state;
    const {appState, selectedTabs} = this.props;
    let listFilter = appState?.filterPlan || [];
    const index = listFilter.findIndex((item) => item.type === selected);
    if(index >= 0) {
      listFilter.splice(index, 1);
    }
    this.props.addEditFilterPlan(listFilter);
    this.props.handleFilterPlan(selectedTabs);
    this.props.onClosePopUpFilter();
  }

  progressSelectedFilter = (value, item, i) => {
    const { selected, listName, listId } = this.state;
    const isChecked = value.target.checked;
    item.isChecked = isChecked;
    if (isChecked) {
      const listNameTmp = listName;
      const listIdTmp = listId;
      const name = selected === 'zone' ? item.name + "/" + item.state : item.name;
      listNameTmp.push(name)
      listIdTmp.push(item.id)
      this.setState({
        listName: listNameTmp,
        listId: listIdTmp,
        nameSearchFilter: '',
      })
    } else {
      const listNameTmp = listName;
      const listIdTmp = listId;
      _.remove(listNameTmp, function(n) {
        if (selected === 'zone') {
          return n === item.name + "/" + item.state;
        }
        return n === item.name;
      });
      _.remove(listIdTmp, function(n) {
        return n === item.id;
      });
      this.setState({
        listName: listNameTmp,
        listId: listIdTmp,
        nameSearchFilter: '',
      })
    }
  }

  merge_array = (array1, array2) => {
    const result_array = [];
    const arr = array1.concat(array2);
    let len = arr.length;
    const assoc = {};

    while(len--) {
        const item = arr[len];
        if (!assoc[item]) { 
            result_array.unshift(item);
            assoc[item] = true;
        }
    }
    return result_array;
  }

  onGroupChange = (value) => {
    const { selected, filterType, filterTypeAll, checkedList } = this.state;
    const filterListTmp = filterType[selected].map(item => item.index);
    let list = [];
    if (filterListTmp?.length === filterTypeAll[selected].length) {
      list = value;
    } else {
      const uncheckedList = filterListTmp.filter(item => {
        return !checkedList.includes(item);
      });
      const removeCheckedList = checkedList.filter(item => !uncheckedList.includes(item));
      list = this.merge_array(removeCheckedList, value);
    }

    this.setState({
      checkedList: list,
      checkAll: filterType[selected].length === filterTypeAll[selected].length
          ? (list.length === filterTypeAll[selected].length ? true : false)
          : (value.length === filterType[selected].length ? true : false),
    });
  }

  onCheckAll = (e, checkedList) => {
    const { selected, filterType, filterTypeAll } = this.state;
    const checkedListTmp = filterType[selected].map(item => item.index);
    let list = [];
    if (checkedListTmp?.length === filterTypeAll[selected].length) {
      list = e.target.checked ? checkedListTmp : [];
    } else {
      const removeCheckedList = checkedList.filter(item => !checkedListTmp.includes(item));
      list = e.target.checked ? removeCheckedList.concat(checkedListTmp) : removeCheckedList;
    }
    this.setState({
      checkedList: list,
      checkAll: e.target.checked,
    });
  }

  onSearch = (e) => {
    const { value } = e.target;
    const { selected, filterTypeAll, filterType, checkedList } = this.state;
    if (selected === 'name') {
      this.setState({ nameSearchFilter: value })
    } else {
      const type = filterTypeAll[selected].filter(item => {
        const name = selected === 'zone' ? item.name + ' / ' + item.state : item.name;
        return name?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      const filter = filterType;
      filter[selected] = type;
      this.setState({
        checkAll: filterType[selected].length === filterTypeAll[selected].length
          ? (checkedList.length === filterType[selected].length ? true : false)
          : (filterType[selected].every(item => checkedList.includes(item.index)) ? true : false),
        filterType: filter
      });
    }
    
  }

  renderItemPopupFilter = () => {
    const { updateFilterPlan } = this.props;
    const { filterType } = this.state;
    switch (this.state.selected) {
      case 'name':
        return (
          <div className='input-search'>
            <br />
            <br />
            <Input defaultValue={updateFilterPlan?.nameSearchFilter}  placeholder="Input Search" onChange={this.onSearch} />
            <br />
            <br />
          </div>
        ); 
      case 'type':
        return (
          <div className='list-zones'>
            <br />
            {STATUS_EXPERIENCES.map((item, i) => (
              <div key={i}>
                <Checkbox onChange={(value) => this.progressSelectedFilter(value,item, i)}>{item.name}</Checkbox>
              </div>
            ))}
          </div>
        );
      case 'zone':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group defaultValue={this.state.checkedList} onChange={this.onGroupChange}>
              {filterType?.zone.map((item, i) => (
                <div key={item.id}>
                  <Checkbox value={item.index}>{item.name + ' / ' + item.state}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      case 'supplier':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group defaultValue={this.state.checkedList} onChange={this.onGroupChange}>
              {filterType?.supplier?.map((item, i) => (
                <div key={item.id}>
                  <Checkbox value={item.index}>{item.name}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      case 'tags':
        return (
          <div className='list-zones'>
            <br />
            <Checkbox.Group defaultValue={this.state.checkedList} onChange={this.onGroupChange}>
              {filterType?.tags?.map((item, i) => (
                <div key={item.id}>
                  <Checkbox value={item.index}>{item.name}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        );
      default:
        return null;
    }
  };

  renderModal = (screen, selectedTabs) => {
    const {editFilter, appState, filterShortList} = this.props;
    const filterType = appState.filterPlan?.map(item => item.type);
    const type = this.state.selected;
    if (screen === SCREEN.ADD_EDIT_PLAN) {
      return editFilter ? (
        <Select
          defaultValue={type}
          style={{ width: 120 }}
        >
          <Option  value={type}>
            <span className="title-option" >{type}</span>
          </Option>
        </Select>
        ):(
          <Select
            defaultValue={type}
            style={{ width: 120 }}
            onChange={(value) => this.handleChangeSelectActivities(value)}
          >
            <Option disabled={filterType.includes('name')} value='name'>Name</Option>
            <Option disabled={filterType.includes('zone')} value='zone'>Zone/State</Option>
          </Select>
      );
    } else {
      const filterShortListType = filterShortList[selectedTabs]?.map(item => item.type);
      if (selectedTabs === '1') {
        return (
          <Select
            defaultValue={type}
            style={{ width: 120 }}
            onChange={(value) => this.handleChangeSelectExperiences(value)}
          >
            <Option disabled={filterShortListType.includes('name')} value='name'>Name</Option>
            <Option disabled={filterShortListType.includes('type')} value='type'>Type</Option>
            <Option disabled={filterShortListType.includes('supplier')} value='supplier'>Supplier</Option>
            {/* <Option disabled={filterShortListType.includes('zone')} value='zone'>Zone</Option> */}
            <Option disabled={filterShortListType.includes('tags')} value='tags'>Tags</Option>
          </Select>
        );
      }
      return (
        <Select
          defaultValue={type}
          style={{ width: 120 }}
          onChange={(value) => this.handleChangeSelectActivities(value)}
        >
          <Option disabled={filterShortListType.includes('name')} value='name'>Name</Option>
          <Option disabled={filterShortListType.includes('tags')} value='tags'>Tags</Option>
        </Select>
      );
    };
  }

  /**
   * render View
   * @return {Component}
   */
  render() {
    const { onClosePopUpFilter, selectedTabs, editFilter, screen } = this.props;
    const { selected, checkedList } = this.state;
    return (
      <div className='container-modal-filter'>
        <Form
          id='createPlanForm' 
          onKeyPress={(e) => {
            if(e.key === "Enter"){
              this.addFilter()
            }}
          }
          >
          <Modal
            className='modal-filter'
            bodyStyle={{ height: '50%' }}
            visible={true}
            onCancel={onClosePopUpFilter}
            title='Filter'
            footer={[
              <div>
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    {editFilter ? (
                      <Button
                        onClick={this.deleteFilter}
                        className='button-delete'
                        variant='primary'
                      >
                        <span className='title-delete'>Delete filter</span>
                      </Button>) : null
                    }
                    <Button
                      onClick={this.addFilter}
                      className='button-update'
                      variant='primary'
                    >
                      <span className='title-update'>{editFilter ? 'Update filter' : 'Add'}</span>
                    </Button>
                  </div>
                </Form.Item>
              </div>,
            ]}
          >
            <div className='content-filter'>
              {this.renderModal(screen, selectedTabs)}
              {
                selected !== 'name' && selected !== 'type' ? (
                  <div className="search-on-filter">
                    <Input placeholder="Search" onChange={this.onSearch}/>
                  </div>
                ) : null
              }
              {this.renderItemPopupFilter()}
              <br />
              {
                selected !== 'name' && selected !== 'type' ? (
                  <div>
                    <Checkbox checked={this.state.checkAll} onChange={(e) => this.onCheckAll(e, this.state.checkedList)}>All/None</Checkbox>
                  </div>
                ) : null
              }
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    zones: state.zones,
    appState: state.appState,
    suppliers: state.suppliers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    saveListFilterExperiences: (list) => dispatch(saveListFilterExperiences(list)),
    saveListFilterActivities: (list) => dispatch(saveListFilterActivities(list)),
    addEditFilterPlan: (list) => dispatch(addEditFilterPlan(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
