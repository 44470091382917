import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Modal, DatePicker, Select } from 'antd';
import { removePlanItem, updatePlanInfo } from '../../views/home/addPlan/actions';
import { DATE_FORMART } from '../../common/constant';
import moment from 'moment';

import './styles.css';

const { Option } = Select;

class Index extends Component {

  handleCreatePlan = (e) => {
    const { plan } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const params = {
          id: plan?.planInfo?.id,
          name: values?.planName,
          status: values?.status === 'archived' ? 3 : (values?.status === 'active' ? 2 : 1),
          cssHourlyRate: values.cssHourlyRate || 0,
          feeRef: values.feeRef
        }
        this.props.updatePlanInfo(params).then(data => {
          this.props.closePopupSettings();
        });
      }
    });
  };

  deleteFilter = () => {
    const { plan } = this.props;
    this.props.removePlanItem(plan?.planInfo?.id).then((res) => {
      if (res) {
        this.props.context.history.goBack();
      }
    });
  }

  render() {
    const { closePopupSettings, plan } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <Form id='createPlanForm' onSubmit={this.handleCreatePlan}>
        <Modal
          className='modal-update-plan'
          visible={true}
          onCancel={closePopupSettings}
          title={`${plan?.planInfo?.name} - Settings Plan`}
          footer={[
              <div>
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    <Button
                      className='button-update'
                      variant='primary'
                      form='createPlanForm'
                      key='submit'
                      htmlType='submit'
                    >
                      <span className='title-update'>Update</span>
                    </Button>
                  </div>
                  <div style={{ float: 'right' }}>
                      <Button
                        onClick={this.deleteFilter}
                        className='button-delete'
                        variant='primary'
                      >
                        <span className='title-delete'>Delete Plan</span>
                      </Button>
                    </div>
                </Form.Item>
              </div>,
            ]}
        >
          <span>Plan Name</span>
          <Form.Item>
            {getFieldDecorator('planName', {
              rules: [{ required: true, message: 'Please input plan name!' }],
              initialValue: plan?.planInfo?.name ? plan?.planInfo?.name : '',
            })(<Input placeholder='Plan Name' style={{ width: '95%' }} />)}
          </Form.Item>
          <div className='plan-start-end-date'>
            <div style={{display: 'flex', float: 'left', width: '50%'}}>Start</div>
            <div>End</div>
          </div>
          <div className='plan-start-end-date'>
            <Form.Item>
              {getFieldDecorator('startDate', {
                rules: [{ 
                  type: 'object', 
                  required: true, 
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject('Please select time!');
                    }
                    var date = moment(value)
                    if(date.day() == 6 || date.day() == 0){
                      return Promise.reject('Start date should be a week day!');
                    }
                    return Promise.resolve();
                  },
                }],
                initialValue: plan?.planInfo?.start_date ? moment(plan?.planInfo?.start_date) : '',
              })(<DatePicker format={DATE_FORMART} disabled={true} style={{ width: '90%' }} placeholder='Start Date' />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('endDate', {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject('Please select time!');
                      }
                      if (value.unix() >= getFieldValue('startDate').unix()) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Require Start Date before End Date!');
                    },
                  },
                ],
                initialValue: plan?.planInfo?.end_date ? moment(plan?.planInfo?.end_date) : '',
              })(<DatePicker format={DATE_FORMART} disabled={true} style={{ width: '90%' }} placeholder='End Date' />)}
            </Form.Item>
          </div>
          <div className='css-hour-rate-fee-ref'>
            <div style={{display: 'flex', float: 'left', width: '50%'}}>CCS Hourly Rate Cap</div>
            <div>Fee Ref</div>
          </div>
          <div className='css-hour-rate-fee-ref'>
            <Form.Item>
                {getFieldDecorator('cssHourlyRate', {
                  rules: [{ required: false, }],
                  initialValue: `${plan?.planInfo?.css_hourly_rate}`,
                })(
                  <Input type="number" placeholder="CCS Hourly Rate Cap" style={{width: '90%'}} />,
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('feeRef', {
                  rules: [{ required: false, }],
                  initialValue: `${plan?.planInfo?.fee_ref || ''}`,
                })(
                  <Input placeholder="Fee Ref" style={{width: '90%'}} />,
                )}
            </Form.Item>
          </div>
          <div className='status'>
            <Form.Item>
              {getFieldDecorator('status', {
                rules: [
                ],
                initialValue: `${plan?.planInfo?.status}` ? `${plan?.planInfo?.status}` : 'draft',
              })(<Select style={{ width: 120 }}>
                <Option value='archived'>Archived</Option>
                <Option value='active'>Active</Option>
                <Option value='draft'>Draft</Option>
              </Select>)}
            </Form.Item>
          </div>
        </Modal>
      </Form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    plan: state.plan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removePlanItem: (id) => dispatch(removePlanItem(id)),
    updatePlanInfo: (params) => dispatch(updatePlanInfo(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
