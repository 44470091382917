export const ACTION_TYPES = {
  GET_COMMENTS: 'GET_COMMENTS',
  GET_COMMENTS_FAIL: 'GET_COMMENTS_FAIL',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  POST_COMMENT: 'POST_COMMENT',
  POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS',
  POST_COMMENT_ERROR: 'POST_COMMENT_ERROR',

  GET_EXPERIENCE_ATTENDANCE: 'GET_EXPERIENCE_ATTENDANCE',
  GET_EXPERIENCE_ATTENDANCE_FAIL: 'GET_EXPERIENCE_ATTENDANCE_FAIL',
  GET_EXPERIENCE_ATTENDANCE_SUCCESS: 'GET_EXPERIENCE_ATTENDANCE_SUCCESS',

  CHANGE_RATING: 'CHANGE_RATING',
  CHANGE_RATING_FAIL: 'CHANGE_RATING_FAIL',
  CHANGE_RATING_SUCCESS: 'CHANGE_RATING_SUCCESS',
};

const initialState = {
  isLoading: false,
  comments: {},
  experienceAttendance: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE:
    case ACTION_TYPES.GET_COMMENTS:
    case ACTION_TYPES.CHANGE_RATING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.comments,
        isLoading: false,
      };
    case ACTION_TYPES.GET_COMMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.POST_COMMENT:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.POST_COMMENT_SUCCESS:
      return {
        ...state,
        addComment: action.data.addExperienceComment,
        isLoading: false,
      };
    case ACTION_TYPES.POST_COMMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        experienceAttendance: action.items,
        isLoading: false,
      };
    case ACTION_TYPES.GET_EXPERIENCE_ATTENDANCE_FAIL:
      return {
        ...state,
        experienceAttendance: [],
        isLoading: false,
      };
    case ACTION_TYPES.CHANGE_RATING_SUCCESS:
    case ACTION_TYPES.CHANGE_RATING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
