import React, { Component } from 'react';
import { Table, Form, Input, Spin } from 'antd';
import {connect} from 'react-redux';
import { getListZones, updateZones } from './actions';
import ImportExport from '../../components/importExport';
import LoadingOverlay from 'react-loading-overlay';

import './styles.css';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={(node) => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };

  render() {
    const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
    return (
      <td {...restProps}>
        {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
      </td>
    );
  }
}

class Index extends Component {

  componentDidMount() {
    this.props.getListZones(100);
  }

  handleSave = row => {
    this.props.updateZones(row);
  };

  render() {
    const {zones, importExport} = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    this.columns = [
      {
        title: 'Zone Name',
        dataIndex: 'name',
        width: '50%',
        editable: false,
      },
      {
        title: 'State',
        dataIndex: 'state',
        width: '50%',
        editable: false,
      },
    ];
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <LoadingOverlay
      active={importExport.isLoading}
      spinner
      >
        <div className='container'>
          <Form.Item>
            <ImportExport  title="Zones" />
            <div className='form-table-user'>
              <Table
                rowKey={record => record.id}
                loading={ zones?.isLoading ? { indicator: <Spin size="small"/>} : false }
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={zones?.listZones}
                columns={columns}
              />
            </div>
          </Form.Item>
        </div>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    zones: state.zones,
    importExport: state.importExport
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    updateZones: (params) => dispatch(updateZones(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Index));
