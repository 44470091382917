import { notification } from 'antd';
import { getListActivitiesApi, updateActivitiesApi, uploadImageApi } from '../../services/activitiesServices';
import { ACTION_TYPES } from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE, PAGE_SIZE_SHORT_LIST } from '../../common/constant';

const openNotificationWithIcon = (description, title, type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetActivitiesAction = {
  type: ACTION_TYPES.GET_ACTIVITIES,
};

export const failGetActivitiesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_FAIL,
    error: data,
  };
};

export const successGetActivitiesAction = (data, totalPage) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_SUCCESS,
    listActivities: data,
    totalPage: totalPage,
  };
};

const notifyFail = (funcFail, dispatch) => {
  dispatch(funcFail);
};

export const getActivities = (pageIndex, request) => {
  return (dispatch) => {
    dispatch(requestGetActivitiesAction);
    let params = `?query={ activities(page_size: ${PAGE_SIZE_SHORT_LIST}, page_index: ${pageIndex}){total_item items{id name  description image tags created_date}}}`;
    if (request) {
      params = `?query={ activities(page_size: ${PAGE_SIZE_SHORT_LIST}, page_index: ${pageIndex}, ${request} ){total_item items{id name  description image tags created_date}}}`;
    }
    return getListActivitiesApi(params).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetActivitiesAction(data.activities.items, data.activities.total_item));
          return data;
        } else {
          openNotificationWithIcon('Get Activities Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetActivitiesAction('Get Activities Fail'), dispatch);
        }
      },
      () => notifyFail(failGetActivitiesAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export const failUpdateActivitiesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITIES_FAIL,
    error: data,
  };
};

const requestUpdateActivitiesAction = {
  type: ACTION_TYPES.UPDATE_ACTIVITIES,
};

export const successUpdateActivitiesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITIES_SUCCESS,
    updateActivity: data,
  };
};

export const updateActivities = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateActivitiesAction);
    const tags = params?.tags.map((item) => {
      return `"${item}"`;
    });
    let body = {
      query: `mutation{updateActivity(id: ${params.id}, name: "${params.name}", description: "${params.description}", tags: [${tags}]) {id, name, description, tags}}`,
    };
    if (tags.length === 0) {
      body = {
        query: `mutation{updateActivity(id: ${params.id}, name: "${params.name}", description: "${params.description}", tags: []) {id, name, description, tags}}`,
      };
    }
    updateActivitiesApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successUpdateActivitiesAction(data?.updateActivity));
        } else {
          openNotificationWithIcon('Update Experience Fail', 'Notification', ALERT_TYPE.ERROR);
          dispatch(failUpdateActivitiesAction('Call api update fail', dispatch));
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUpdateActivitiesAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestUploadImageAction = {
  type: ACTION_TYPES.UPLOAD_IMAGE,
};

export const failUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_FAIL,
  };
};

export const successUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_SUCCESS,
    activityUploadInline: data,
  };
};

export const removeImage = (params) => {
  const tags = params?.tags.map((item) => {
    return `"${item}"`;
  });
  return (dispatch) => {
    dispatch(requestUpdateActivitiesAction);
    let body = {
      query: `mutation{updateActivity(id: ${params.id}, image: null, description: "${params.description}", tags: [${tags}]) {id, name, description, tags}}`,
    };
    if (tags.length === 0) {
      body = {
        query: `mutation{updateActivity(id: ${params.id}, image: null, description: "${params.description}", tags: []) {id, name, description, tags}}`,
      };
    }
    updateActivitiesApi(body).then(
      (dataJson) => {
        if (dataJson && dataJson.success) {
          dispatch(successUpdateActivitiesAction(dataJson.data?.updateActivity));
        } else {
          openNotificationWithIcon('Update Experience Fail', 'Notification', ALERT_TYPE.ERROR);
          dispatch(failUpdateActivitiesAction('Call api update fail', dispatch));
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUpdateActivitiesAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

export const uploadImage = (file, record) => {
  return (dispatch) => {
    dispatch(requestUploadImageAction);
    const body = new FormData();
    body.append(
      'operations',
      `{"query": "mutation UploadFile($image_file: Upload!){activityUploadInline(image_file: $image_file, id: ${record.id}) { id, name, image}}"}`
    );
    body.append('map', `{"0": ["variables.image_file"]}`);
    body.append('0', file.originFileObj);
    uploadImageApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson.success) {
          dispatch(successUploadImageAction(data.activityUploadInline));
        } else {
          openNotificationWithIcon('Fail to upload file', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};
