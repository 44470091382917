import React, { Component } from 'react';
import { Layout, Form, Icon, Select, Button, Row, Col, Tabs } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { PLAN_ITEMS_TYPE, ROUTE, SCREEN } from '../../../common/constant';
import ActivitiesAndExperiencesItem from '../../../components/activityAndExperiencesItem';
import moment from 'moment';
import Filter from '../filter';
import { saveToShortlist, savePlanItems, savePlanPrograms, saveEditCell } from './actions';
import { getListZones } from '../../zones/actions';
import ResearchPanDetailActivities from '../../researchPan';
import { getListUserAdd } from '../../users/actions';
import { getPrograms } from '../../programs/actions';
import { hideMenu, backFromScreen, addEditFilterPlan, showLoading, offLoading } from '../../appState/actions';
import EditPlanItem from '../editPlanItem';
import LoadingOverlay from 'react-loading-overlay';
import ExperiencesDetail from '../../researchPan/detailExperiencesAndActivities';
import SettingPlan from '../../../components/planSettings/index';

import './styles.css';

const { Content, Sider, Header } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = [];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = source;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getListPlanItemStyle = () => ({
  background: 'white',
  width: '90%',
  height: `105px`,
  margin: 'auto',
  border: '2px dashed gray',
  'margin-top': '10px',
});

const getItemMenuStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  display: 'flex',
  background: isDragging ? 'white' : 'grey',
  ...draggableStyle,
});

const getListStyle = (listLength, planContentHeight) => ({
  background: 'white',
  width: '90%',
  height: 'calc(100vh - 220px)',
  margin: 'auto',
  border: '2px dashed gray',
  'margin-top': '10px',
});

class Index extends Component {
  state = {
    isShow: false,
    collapsed: true,
    selectedTabs: '1',
    experiences: [],
    activities: [],
    arrayPlanDate: [],
    arrayPrograms: [],
    arrayProgramsTmp: [],
    showPopupAddFilter: false,
    showPopupDetailActivities: false,
    disableExperienceShortlist: false,
    editPlanItem: {},
    itemResearchPan: {},
    itemDetailExperiences: {},
    showDetailExperiences: false,
    isLoading: false,
    filterProgram: [],
    filterPlan: {},
    showPopupSettings: false,
    editFilter: {},
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    experiences: 'experiences',
    activities: 'activities',
  };

  getList = (id) => {
    if (id === 'experiences' || id === 'activities') {
      return this.state[this.id2List[id]];
    } else {
      let droppableItem = [];
      this.state.arrayPrograms.some((program, programIndex) => {
        var found = false;
        program.planDates.some((plan, planDateIndex) => {
          if (plan.hasOwnProperty(id)) {
            droppableItem = { programIndex, planDateIndex };
            found = true;
            return true;
          }
          return false;
        });
        if (found) {
          return true;
        }
        return false;
      });
      return this.state.arrayPrograms[droppableItem.programIndex].planDates[droppableItem.planDateIndex][id];
    }
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId !== 'experiences' && destination.droppableId !== 'activities') {
        return;
      }
      const items = reorder(this.getList(source.droppableId), source.index, destination.index);

      let state = { items };

      if (source.droppableId === 'items') {
        state = { items: items };
      } else if (source.droppableId === 'experiences') {
        state = { experiences: items };
      } else if (source.droppableId === 'activities') {
        state = { activities: items };
      }

      this.setState(state);
    } else {
      const droppableSource = source.droppableId;
      const droppableDestination = destination.droppableId;

      if (
        (droppableSource === 'experiences' && !droppableDestination.includes('experience')) ||
        (droppableSource === 'activities' && !droppableDestination.includes('activity'))
      ) {
        return;
      }
      const listSource = this.getList(droppableSource);
      const listDestination = this.getList(droppableDestination);
      if (
        listDestination?.length > 2 &&
        (droppableDestination.includes('experience') || droppableDestination.includes('activity'))
      ) {
        return;
      }
      const result = move(listSource, listDestination, source, destination);
      const arrayPrograms = result[droppableDestination]
        ? this.state.arrayPrograms.map((program, index) => {
            program.planDates.forEach((item, i) => {
              if (item.hasOwnProperty(droppableDestination)) {
                item[droppableDestination] = result[droppableDestination];
              }
              if (item[item.experience].length > 0 && item[item.activity].length > 0 && item.id > 0) {
                item.disabledEdit = false;
              }
            });
            return program;
          })
        : this.state.arrayPrograms;
      const arrayProgramsTmp = this.state.arrayProgramsTmp.map((item) => {
        const program = arrayPrograms.find((pro) => pro.programId === item.programId);
        if (program) {
          item = program;
        }
        return item;
      });
      this.setState({
        arrayPrograms,
        arrayProgramsTmp,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.doLogin(values);
      }
    });
  };

  collapseMenu = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    const { appState } = this.props;
    this.props.hideMenu(!appState.showMenu);
  };

  getPlanItemTypeName = (type) => {
    switch (type) {
      case 2:
        return 'OperatingDay';
      case 3:
        return 'CurriculumDay';
      case 4:
        return 'PoolDay';
      case 5:
        return 'PublicHoliday';
      case 1:
      default:
        return 'Closed';
    }
  };

  componentDidMount() {
    this.props.showLoading();
    const promiseList = [];
    promiseList.push(this.props.getListUserAdd());
    promiseList.push(this.props.getPrograms(100, 1));
    promiseList.push(this.props.getListZones(100));
    Promise.all(promiseList)
      .then((values) => {
        this.initData(values[1]);
      })
      .catch((reason) => {
        this.props.offLoading();
      });
  }

  initData = (data) => {
    const { plan, appState } = this.props;

    const countDate = moment(plan.planInfo.end_date).diff(moment(plan.planInfo.start_date), 'days');
    let arrayPlanDate = [];
    for (let i = 0; i < countDate + 1; i++) {
      const dayOfWeek = moment(plan.planInfo.start_date).add(i, 'days').format('dddd');
      const objectPlan = {
        planItemType: dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday' ? 0 : 1,
        planItemDate: moment(plan.planInfo.start_date).add(i, 'days').format('DD MMM YYYY'),
        dayOfWeek,
      };
      arrayPlanDate.push(objectPlan);
    }
    const screen = appState.screen;

    const arrayProgramsTmp =
      screen === SCREEN.SHORT_LIST_PLAN
        ? this.getShortlistByUser()?.arrayPrograms
        : this.initPrograms(data, arrayPlanDate);
    const experiences = this.getShortlistByUser()?.experiencesShortlist || [];
    const activities = this.getShortlistByUser()?.activitiesShortlist || [];
    this.setState({
      arrayProgramsTmp,
      arrayPrograms: arrayProgramsTmp,
      arrayPlanDate,
      experiences,
      activities,
    });
    this.props.offLoading();
  };

  initPrograms = (programs, arrayPlanDate) => {
    const { plan } = this.props;
    return programs?.map((item, index) => {
      const programId = index + 1;
      const planDates = [];
      for (let i = 0; i < arrayPlanDate?.length; i++) {
        const experience = `experience-${index}${i}`;
        const activity = `activity-${index}${i}`;
        const objectPlan = {
          planItemType: arrayPlanDate[i].planItemType,
          planItemTypeName: 'Closed',
          [activity]: [],
          [experience]: [],
          activity: activity,
          experience: experience,
          planItemDate: arrayPlanDate[i].planItemDate,
          statusLockPlanItem: false,
          disabledEdit: true,
          capacity: 0,
          estimated: 0,
          actual: 0,
          parentFee: 0,
          id: null,
          planId: plan?.planInfo?.id,
          programId: programId,
          pool_time: '',
          exp_start_time: '',
          duration: '',
          reference_no: '',
          additional_info: '',
          supplier_note: '',
          bus_departure: '',
          travel_time: '',
          leave_on_return: '',
          bus_arrival: '',
          bus_size: '',
          staff_to_kids_ratio: '',
          exp_fee: null,
          activity_fee: null,
          bus_fee: null,
          exp_fee_sum: null,
          parent_fee: null,
          pool_fee: null,
          base_fee: null,
          exp_fee_total: null,
          subsidised_fee: null,
        };
        planDates.push(objectPlan);
      }
      return {
        programId,
        programName: item.name,
        planDates,
        userId: 0,
        zoneState: item?.zone?.name + '/' + item?.zone?.state,
      };
    });
  };

  saveAndExit = () => {
    this.props.showLoading();
    const { arrayPrograms } = this.state;
    const { shortlistByUser } = this.props.plan;
    const { objectUser } = this.props.userLogin;

    const shortlist = {
      userId: objectUser.id,
      experiencesShortlist: this.getShortlistByUser()?.experiencesShortlist || [],
      activitiesShortlist: this.getShortlistByUser()?.activitiesShortlist || [],
    };
    let list = shortlistByUser !== undefined ? shortlistByUser : [];
    if (list.some((item) => item.userId === shortlist.userId)) {
      list = list.map((item) => {
        if (item.userId === shortlist.userId) {
          item = shortlist;
        }
        return item;
      });
    } else {
      list.push(shortlist);
    }
    this.props.saveToShortlist(list);
    const planItems = [];
    const planPrograms = [];
    arrayPrograms.forEach((item, index) => {
      const planProgram = `{plan_id: ${this.props.plan?.planInfo?.id},program_id: ${item.programId},user_id: ${item.userId}}`;
      planPrograms.push(planProgram);
      return item.planDates?.forEach((planDate, planDateIndex) => {
        const experience = planDate[planDate.experience];
        const activity = planDate[planDate.activity];
        const dateType = this.getPlanItemTypeName(planDate.planItemType);
        const experience_id =
          experience.length === 0 || dateType === 'Closed' || dateType === 'PublicHoliday' || experience[0].id === '0'
            ? undefined
            : experience[0].id;
        const activity_id =
          activity.length === 0 || dateType === 'Closed' || dateType === 'PublicHoliday' || activity[0].id === '0'
            ? undefined
            : activity[0].id;

        const planItem = `{id: ${planDate.id}, plan_id: ${this.props.plan?.planInfo?.id},program_id: ${
          item.programId
        },date_type: ${dateType},plan_date: "${moment(planDate.planItemDate).format('YYYY-MM-DD')}"${
          experience_id ? `,experience_id: ${experience_id},` : ''
        }${activity_id ? `activity_id: ${activity_id}` : ''}}`;
        planItems.push(planItem);
      });
    });
    const paramPlanItems = {
      planId: this.props.plan?.planInfo?.id,
      planItems,
    };

    const paramPlanPrograms = {
      planId: this.props.plan?.planInfo?.id,
      planPrograms,
    };

    this.props.backFromScreen(SCREEN.ADD_EDIT_PLAN);
    this.props.savePlanPrograms(paramPlanPrograms);
    this.props.addEditFilterPlan([]);
    this.props.savePlanItems(this.props, paramPlanItems);
  };

  applyAll = (planItem) => {
    const { arrayPrograms, arrayProgramsTmp } = this.state;
    const experience = planItem[planItem.experience];
    const activity = planItem[planItem.activity];
    const programs = arrayPrograms.map((item, index) => {
      item.planDates.forEach((cell, cellIndex) => {
        if (
          experience &&
          experience.length > 0 &&
          experience[0].id === cell[`experience-${index}${cellIndex}`][0]?.id
        ) {
          cell[`activity-${index}${cellIndex}`] = activity;
        }
      });
      return item;
    });
    const programsTmp = arrayProgramsTmp.map((item) => {
      const program = programs.find((pro) => pro.programId === item.programId);
      if (program) {
        item = program;
      }
      return item;
    });
    this.setState({
      arrayPrograms: programs,
      arrayProgramsTmp: programsTmp,
    });
  };

  handlePlanItemType = (value, items, planItemIndex) => {
    const experience = items.experience;
    const activity = items.activity;
    items.planItemType = value;
    items.planItemTypeName = this.getPlanItemTypeName(value);
    if (value === 5 || value === 1) {
      items[experience] = [];
      items[activity] = [];
    }
    this.setState({
      arrayPrograms: this.state.arrayPrograms,
    });
  };

  handleOwnerChange = (value, programId) => {
    this.setState({
      arrayPrograms: this.state.arrayPrograms.map((item, index) => {
        if (item.programId === programId) {
          item.userId = value;
        }
        return item;
      }),
    });
  };

  addShortList = () => {
    const { arrayProgramsTmp } = this.state;
    const { shortlistByUser } = this.props.plan;
    const { objectUser } = this.props.userLogin;

    const shortlist = {
      userId: objectUser.id,
      experiencesShortlist: this.getShortlistByUser()?.experiencesShortlist || [],
      activitiesShortlist: this.getShortlistByUser()?.activitiesShortlist || [],
      arrayPrograms: arrayProgramsTmp,
    };
    let list = shortlistByUser !== undefined ? shortlistByUser : [];
    if (list.some((item) => item.userId === shortlist.userId)) {
      list = list.map((item) => {
        if (item.userId === shortlist.userId) {
          item = shortlist;
        }
        return item;
      });
    } else {
      list.push(shortlist);
    }
    this.props.saveToShortlist(list);
    this.props.addEditFilterPlan([]);
    this.props.history.push(ROUTE.LAYOUT + ROUTE.ADD_SHORT_LIST);
  };

  lockPlanItem = (planItem, planItemIndex) => {
    const experience = planItem.experience;
    const activity = planItem.activity;
    this.setState({
      arrayPrograms: this.state.arrayPrograms.map((item, index) => {
        if (item.programId === planItem.programId) {
          item.planDates[planItemIndex].statusLockPlanItem = !item.planDates[planItemIndex].statusLockPlanItem;
          item.planDates[planItemIndex].disabledEdit = false;
          if (
            item.planDates[planItemIndex].statusLockPlanItem ||
            (item.planDates[planItemIndex][experience].length === 0 &&
              item.planDates[planItemIndex][activity].length === 0)
          ) {
            item.planDates[planItemIndex].disabledEdit = true;
          }
        }
        return item;
      }),
    });
  };

  renderPlanItem = (planItem, planItemIndex) => {
    const type = planItem.planItemType;
    switch (type) {
      case 1:
      case 5:
        return (
          <div className="plan-type-item">
            <div className="plan-type-public-closed">
              {type === 1 ? 'Closed' : 'Public holiday'}
              <br />
              There are no experiences or activities planned at this program today
            </div>
          </div>
        );
      case 2:
      case 3:
      case 4:
        return (
          <div className={planItem.statusLockPlanItem ? 'disabled-plan-type-item' : 'plan-type-item'}>
            <Droppable droppableId={planItem.experience} isDropDisabled={planItem.statusLockPlanItem}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListPlanItemStyle()}>
                  {planItem[planItem.experience].map((item, index) => (
                    <ActivitiesAndExperiencesItem
                      typeItem="Experiences"
                      key={index}
                      item={item}
                      hideApplyAll={true}
                      showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId={planItem.activity} isDropDisabled={planItem.statusLockPlanItem}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListPlanItemStyle()}>
                  {planItem[planItem.activity]?.map((item, index) => (
                    <ActivitiesAndExperiencesItem
                      typeItem="Activities"
                      key={index}
                      item={item}
                      hideApplyAll={false}
                      applyAll={() => this.applyAll(planItem)}
                      showPopupResearchPan={(item) => this.showPopupDetailActivities(item)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Row>
              <Col span={8}>
                <div>
                  <span className="capacity-title">Capacity</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className="capacity-title">Estimated</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className="capacity-title">Actual</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div>
                  <span className="capacity-title-">{planItem.capacity}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className="capacity-title-">{planItem.estimated}</span>
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <span className="capacity-title-">{planItem.actual}</span>
                </div>
              </Col>
            </Row>
            <Row className="parent-fee-container">
              <Col style={{ 'margin-top': 'inherit' }} span={12}>
                <div>
                  <span className="capacity-title">Parent Fee: {planItem.parentFee}</span>
                </div>
              </Col>
              <Col style={{ 'margin-right': '5px' }} span={4}>
                <Button
                  className={planItem.statusLockPlanItem ? 'disabled-button-unlock' : 'button-unlock'}
                  onClick={() => this.lockPlanItem(planItem, planItemIndex)}
                >
                  <Icon
                    type={planItem.statusLockPlanItem ? 'lock' : 'unlock'}
                    style={{ fontSize: 18, color: 'white' }}
                  />
                </Button>
              </Col>
              <Col style={{ width: 'fit-content' }} span={8}>
                <Button
                  className="button-edit"
                  onClick={() => this.editPlanItem(planItem)}
                  disabled={planItem.disabledEdit}
                >
                  <Icon type="edit" /> Edit
                </Button>
              </Col>
            </Row>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  getShortlistByUser = () => {
    return this.props.plan.shortlistByUser?.find((item) => {
      return item.userId === this.props.userLogin.objectUser.id;
    });
  };

  onChangTabs = (key) => {
    let disabled = true;
    let activities = [];
    let experiences = [];
    if (key === '1') {
      disabled = false;
      experiences = this.getShortlistByUser()?.experiencesShortlist || [];
      activities = [];
    } else {
      activities = this.getShortlistByUser()?.activitiesShortlist || [];
      experiences = [];
    }
    this.setState({
      selectedTabs: key,
      disableExperienceShortlist: disabled,
      experiences,
      activities,
    });
  };

  showPopupAddFilter = () => {
    this.setState({ showPopupAddFilter: true, filterPlan: [], editFilter: false });
  };

  updateFilterPlan = (item) => {
    this.setState({ showPopupAddFilter: true, filterPlan: item, editFilter: { type: item.type } });
  };

  hidePopupAddFilter = () => {
    this.setState({ showPopupAddFilter: false });
  };

  handleFilterPlan = (selectedTabs) => {
    const { appState } = this.props;
    const { arrayProgramsTmp } = this.state;
    const filterProgram = appState?.filterPlan;

    const nameSearchFilter = filterProgram.find((item) => item.type === 'name')?.nameSearchFilter;
    const listName = filterProgram.find((item) => item.type === 'zone')?.listName;

    this.setState({
      filterProgram,
      arrayPrograms: arrayProgramsTmp?.filter((item, index) => {
        if (nameSearchFilter && listName && listName.length > 0) {
          return item.programName.includes(nameSearchFilter) && listName.includes(item.zoneState);
        } else if (nameSearchFilter) {
          return item.programName.includes(nameSearchFilter);
        } else if (listName && listName.length > 0) {
          return listName.includes(item.zoneState);
        }
        return true;
      }),
    });
  };

  showPopupDetailActivities = (item) => {
    this.setState({ showPopupDetailActivities: true, itemResearchPan: item });
  };

  hidePopupResearchPanDetailActivities = () => {
    this.setState({ showPopupDetailActivities: false });
  };

  editPlanItem = (item) => {
    const { plan } = this.props;
    const planName = plan?.planInfo?.name;
    const planItemDate = moment(item.planItemDate).format('DD-MMM-YYYY');
    this.setState({ isShow: true, editPlanItem: { ...item, planName, planItemDate } });
  };

  closeModalEditPlanItem = () => {
    this.setState({ isShow: false });
  };

  saveEditCell = (planItem, cellItem) => {
    cellItem.planItemDate = moment(cellItem.planItemDate).format('YYYY-MM-DD hh:mm:ss');
    this.props.saveEditCell(planItem, cellItem).then((planItemId) => {
      this.setState({
        isShow: false,
        arrayPrograms: this.state.arrayPrograms.map((item, index) => {
          item.planDates.forEach((cell, cellIndex) => {
            if (cellItem.experience === `experience-${index}${cellIndex}`) {
              cell = {
                ...cell,
                id: planItemId,
                pool_time: cellItem.pool_time || '',
                exp_start_time: cellItem.exp_start_time || '',
                duration: cellItem.duration || '',
                reference_no: cellItem.reference_no || '',
                additional_info: cellItem.additional_info || '',
                supplier_note: cellItem.supplier_note || '',
                bus_departure: cellItem.bus_departure || '',
                travel_time: cellItem.travel_time || '',
                leave_on_return: cellItem.leave_on_return || '',
                bus_arrival: cellItem.bus_arrival || '',
                bus_size: cellItem.bus_size || '',
                staff_to_kids_ratio: cellItem.staff_to_kids_ratio || '',
                exp_fee: cellItem.exp_fee || null,
                activity_fee: cell.activity_fee || null,
                bus_fee: cellItem.bus_fee || null,
                exp_fee_sum: cellItem.exp_fee_sum || null,
                parent_fee: cellItem.parent_fee || null,
                pool_fee: cellItem.pool_fee || null,
                base_fee: cellItem.base_fee || null,
                exp_fee_total: cellItem.exp_fee_total || null,
                subsidised_fee: cellItem.subsidised_fee || null,
              };
            }
          });
          return item;
        }),
      });
    });
  };

  showPopupDetailExperiences = (item) => {
    this.setState({ showDetailExperiences: true, itemDetailExperiences: item });
  };

  hidePopupDetailExperiences = () => {
    this.setState({ showDetailExperiences: false });
  };

  showPopupSettings = () => {
    this.setState({ showPopupSettings: true });
  };

  closePopupSettings = () => {
    this.setState({ showPopupSettings: false });
  };

  nodeRef = (node) => {
    this.widthContent += node?.offsetWidth;
  };

  widthContent = 0;

  /* eslint-disable */
  render() {
    const {
      editPlanItem,
      arrayPlanDate,
      arrayPrograms,
      showPopupAddFilter,
      experiences,
      activities,
      showPopupDetailActivities,
      disableExperienceShortlist,
      itemResearchPan,
      isShow,
      showDetailExperiences,
      itemDetailExperiences,
      selectedTabs,
      filterProgram,
      filterPlan,
      showPopupSettings,
      editFilter,
    } = this.state;

    const saveListFilter = filterProgram || [];
    const widthContentPlanProgram = this.widthContent + 40;

    const planProgramWithContent = arrayPlanDate.length > 0 ? widthContentPlanProgram : 'auto';
    const planContentHeight = window.screen.height - 100;
    const { userAdd, appState, plan } = this.props;
    const userProgramList = userAdd?.listUserAdd ? userAdd?.listUserAdd.filter((item) => item.group_id === 4) : [];
    userProgramList?.unshift({
      id: 0,
      name: 'select Co-Ordinator',
    });
    return (
      <LoadingOverlay active={appState.isLoading} spinner>
        <Layout style={{ height: '100%' }}>
          <EditPlanItem
            planItem={editPlanItem}
            isShow={isShow}
            closeModalEditPlanItem={this.closeModalEditPlanItem}
            saveEditCell={this.saveEditCell}
          />
          <Header style={{ paddingLeft: 18, background: '#ffffff', borderBottom: '1px solid #dddddd', height: '50px' }}>
            <div className="header-create-plan">
              <div className="title-page-create-plan">
                {plan.planInfo?.name} &nbsp;
                <span className="plan-status">{plan.planInfo?.status}</span>
              </div>

              <div className="header-button">
                <Form.Item>
                  <div style={{ float: 'right' }}>
                    <Button onClick={this.showPopupSettings}>
                      <Icon type="setting" /> setting
                    </Button>
                    &nbsp;
                    <Button className="button-save-and-exit" onClick={this.saveAndExit}>
                      <Icon type="upload" /> save and exit
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </div>
          </Header>
          <div className="plan-status-container">
            <span className="plan-status_date">{plan.planInfo?.start_date}</span>
            <span className="plan-status_date"> to </span>
            <span className="plan-status_date">{plan.planInfo?.end_date}</span>
          </div>
          <Layout style={{ height: '100%' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="slider-on-plan">
                <Sider
                  className={appState?.showMenu ? '' : 'slider-show'}
                  style={{ background: 'white' }}
                  trigger={null}
                  collapsible
                  collapsed={appState?.showMenu}
                >
                  <div className="logo"></div>
                  {appState?.showMenu ? (
                    ''
                  ) : (
                    <div className="card-container">
                      <Tabs type="card" onChange={this.onChangTabs}>
                        <TabPane tab="Experiences" key="1">
                          <Button className="button-save-and-exit" onClick={this.addShortList}>
                            <Icon type="plus" /> Add to short list
                          </Button>
                          <div className="shortlist">Experiences shortlist</div>
                          <div style={{ height: 'calc(100vh - 220px)' }}>
                            <Droppable isDropDisabled={disableExperienceShortlist} droppableId="experiences">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(experiences?.length, planContentHeight)}
                                >
                                  {experiences?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem="Experiences"
                                            typeCss="ItemShortList"
                                            item={item}
                                            hideApplyAll={true}
                                            showPopupResearchPan={(item) => this.showPopupDetailExperiences(item)}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </TabPane>
                        <TabPane tab="Activities" key="2">
                          <Button className="button-save-and-exit" onClick={this.addShortList}>
                            <Icon type="plus" /> Add to short list
                          </Button>
                          <div className="shortlist">Activities shortlist</div>
                          <div style={{ height: 'calc(100vh - 220px)' }}>
                            <Droppable droppableId="activities">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(activities?.length, planContentHeight)}
                                >
                                  {activities?.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemMenuStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <ActivitiesAndExperiencesItem
                                            typeItem="Activities"
                                            typeCss="ItemShortList"
                                            showPopupResearchPan={(item) => this.showPopupDetailActivities(item)}
                                            item={item}
                                            hideApplyAll={true}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  )}
                </Sider>
              </div>
              <Layout style={{ height: '100%', background: '#2F4F4F', overflow: 'auto', width: '800px' }}>
                <Content style={{ width: planProgramWithContent }}>
                  <div className="slider-collapse-button">
                    <Button style={{ background: '#2F4F4F', border: 'none' }} onClick={this.collapseMenu}>
                      <Icon style={{ color: 'white' }} type="double-right" />
                    </Button>
                  </div>
                  <div className="filter-container">
                    <div className="filter">
                      <div className="filter-underline">
                        <Icon type="filter" style={{ fontSize: 20, color: '#ffffff' }} />
                        <div className="title-filter">
                          <span>Filter</span>
                        </div>
                      </div>
                    </div>
                    <div className="container-add-filter-and-new-filter">
                      {saveListFilter?.map((item) => (
                        <div className="add-filter">
                          <div className="state-filter">
                            <div>
                              <span className="state-title">{item.type}:</span>
                            </div>
                            <div style={{ width: '125px' }}>
                              <span className="content-filter">
                                {item?.type === 'name' ? item?.nameSearchFilter : item?.listName?.join()}
                              </span>
                            </div>
                          </div>
                          <Button type="link" className="icon-filter" onClick={() => this.updateFilterPlan(item)}>
                            <Icon type="form" style={{ fontSize: 20, color: '#ffffff' }} />
                          </Button>
                        </div>
                      ))}
                      {saveListFilter?.length <= 1 && (
                        <Button className="new-add-filter" onClick={this.showPopupAddFilter}>
                          <Icon type="plus" style={{ fontSize: 20, color: '#ffffff' }} />
                          Add Filter
                        </Button>
                      )}
                    </div>
                    <div className="plan-date">
                      {arrayPlanDate?.map((item, i) =>
                        item.planItemType !== 0 ? (
                          <div ref={this.nodeRef} key={i} span={6} className="column-date-plan">
                            <div className="text-plan-label">
                              <div>
                                <span className="text-plan-item">{item.dayOfWeek}</span>
                              </div>
                              <span className="text-plan-item">{item.planItemDate}</span>
                            </div>
                          </div>
                        ) : (
                          <div ref={this.nodeRef} key={i} span={1} className="column-date-plan-weekend"></div>
                        )
                      )}
                    </div>
                  </div>
                  <div style={{ height: 'calc(100vh - 280px)' }} className="plan-content-table">
                    {arrayPrograms?.map((program, programIndex) => (
                      <div key={programIndex}>
                        <Row style={{ height: '50px' }}>
                          <div className="programs-header-name">{program.programName}</div>
                          <div className="programs-header-button">
                            <Select
                              value={program.userId}
                              defaultValue={0}
                              style={{ width: '200px', float: 'right' }}
                              onChange={(value) => this.handleOwnerChange(value, program.programId)}
                            >
                              {userProgramList?.map((item) => (
                                <Option key={item.id} value={item.id} label={item.name}>
                                  <div>{item.name}</div>
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Row>
                        <Row style={{ width: 'auto', display: 'flex' }}>
                          {program?.planDates?.map((items, index) =>
                            items.planItemType !== 0 ? (
                              <div span={6} className="column-type-plan">
                                <Select
                                  value={items.planItemTypeName}
                                  defaultValue={items.planItemType}
                                  style={{ 'margin-top': '10px', width: '90%' }}
                                  onChange={(value) => this.handlePlanItemType(value, items, index)}
                                >
                                  {PLAN_ITEMS_TYPE?.map((item) => (
                                    <Option key={item.id} value={item.id} label={item.name}>
                                      <div>{item.name}</div>
                                    </Option>
                                  ))}
                                </Select>
                                {this.renderPlanItem(items, index)}
                              </div>
                            ) : (
                              <div span={1} className="column-type-plan-weekend"></div>
                            )
                          )}
                        </Row>
                      </div>
                    ))}
                  </div>
                  {showPopupAddFilter && (
                    <Filter
                      showPopupAddFilter={showPopupAddFilter}
                      onClosePopUpFilter={this.hidePopupAddFilter}
                      handleFilterPlan={() => this.handleFilterPlan(selectedTabs)}
                      selectedTabs={selectedTabs}
                      editFilter={editFilter}
                      updateFilterPlan={filterPlan}
                      screen={SCREEN.ADD_EDIT_PLAN}
                    />
                  )}
                  {showPopupDetailActivities && (
                    <ResearchPanDetailActivities
                      item={itemResearchPan}
                      onClosePopUpReSearchPan={this.hidePopupResearchPanDetailActivities}
                    />
                  )}
                  {showDetailExperiences && (
                    <ExperiencesDetail
                      item={itemDetailExperiences}
                      onClosePopUpDetailExperiencesAndActivities={this.hidePopupDetailExperiences}
                    />
                  )}
                  {showPopupSettings && (
                    <SettingPlan
                      context={this.props}
                      showPopupSettings={showPopupSettings}
                      closePopupSettings={this.closePopupSettings}
                    />
                  )}
                </Content>
              </Layout>
            </DragDropContext>
          </Layout>
        </Layout>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    programs: state.programs,
    userAdd: state.userAdd,
    userLogin: state.userLogin,
    plan: state.plan,
    appState: state.appState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideMenu: (showMenu) => dispatch(hideMenu(showMenu)),
    backFromScreen: (screen) => dispatch(backFromScreen(screen)),
    getListUserAdd: () => dispatch(getListUserAdd()),
    saveToShortlist: (body) => dispatch(saveToShortlist(body)),
    addEditFilterPlan: (body) => dispatch(addEditFilterPlan(body)),
    getListZones: (pageSize) => dispatch(getListZones(pageSize)),
    saveToShortlist: (body) => dispatch(saveToShortlist(body)),
    savePlanItems: (props, body) => dispatch(savePlanItems(props, body)),
    savePlanPrograms: (body) => dispatch(savePlanPrograms(body)),
    saveEditCell: (planItem, body) => dispatch(saveEditCell(planItem, body)),
    getPrograms: (page_size, page_index) => dispatch(getPrograms(page_size, page_index)),
    showLoading: () => dispatch(showLoading()),
    offLoading: () => dispatch(offLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
