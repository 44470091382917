import _ from 'lodash';

export const ACTION_TYPES = {
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAIL: 'GET_ACTIVITIES_FAIL',

  UPDATE_ACTIVITIES: 'ACTIVITIES',
  UPDATE_ACTIVITIES_SUCCESS: 'UPDATE_ACTIVITIES_SUCCESS',
  UPDATE_ACTIVITIES_FAIL: 'UPDATE_ACTIVITIES_FAIL',

  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',
};

const initialState = {
  isLoading: false,
  listActivities: [],
  totalPage: 0,
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ACTIVITIES:
    case ACTION_TYPES.GET_ACTIVITIES:
    case ACTION_TYPES.UPLOAD_IMAGE:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPLOAD_IMAGE_SUCCESS:
    case ACTION_TYPES.UPLOAD_IMAGE_FAIL:
    case ACTION_TYPES.UPDATE_ACTIVITIES_FAIL:
    case ACTION_TYPES.GET_ACTIVITIES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listActivities: action.listActivities,
        totalPage: action.totalPage,
      };
    case ACTION_TYPES.UPDATE_ACTIVITIES_SUCCESS:
      const list = _.forEach(state.listActivities, (item) => {
        if (item.id === action.updateActivity.id) {
          item.name = action.updateActivity.name;
          item.description = action.updateActivity.description;
        }
      });
      return {
        ...state,
        isLoading: false,
        listActivities: list,
      };
    default:
      return state;
  }
}
