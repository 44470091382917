import React, { Component } from 'react';
import { Row, Col, Form, Modal, Tabs, Comment, Table, List, Input, Rate, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Config from '../../../common/config';
import { getComments, addComment, getExperienceAttendance, changeRating } from './actions';
import { convertUsernameToAvatar } from '../../../utils/common';

import './styles.css';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const CommentList = ({ comments, currentUser }) => {
  const getClassName = (isNewComment, user) => {
    let className = 'comment';

    if (user) {
      className += user?.id === currentUser?.objectUser.id || isNewComment ? ' user-comment' : ' color-bouquet'
    }
    return className
  }

  return (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={({ comment, created_date, isNewComment, user }) => (
        <Comment
          avatar={convertUsernameToAvatar(user?.name)}
          className={getClassName(isNewComment, user)}
          content={comment}
          datetime={created_date ? moment(created_date).format('DD-MMM-YYYY') : moment(Date.now()).toNow()}
        />
      )}
    />
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea
        placeholder="Your message"
        rows={4}
        onChange={onChange}
        value={value}
        onPressEnter={onSubmit}
        disabled={submitting}
      />
    </Form.Item>
  </>
);

/**
 * the class Index
 */
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComments: [],
      submitting: false,
      value: '',
      item: props.item,
      totalComments: [],
      program: {},
      experienceAttendance: []
    };

    this.containerRef = React.createRef();
  }

  handleSubmit = () => {
    const { value, item } = this.state;
    const { userLogin } = this.props;
    if (!value) {
      return;
    }

    this.setState({
      submitting: true,
    });

    this.props.addComment({
      user_id: userLogin.objectUser.id,
      experience_id: item.id,
      comment: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  onChange = (e) => {};

  componentDidMount() {
    const { getComments, getExperienceAttendance, item } = this.props;
    getComments(item.id);
    getExperienceAttendance(item.id).then((data) => {
      this.setState({
        experienceAttendance: data
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { addExperienceComment, userLogin } = this.props;
    const { newComments } = this.state;
    if (prevProps.addExperienceComment !== addExperienceComment) {
      this.setState({
        newComments: [
          ...newComments,
          {
            user: {
              name: convertUsernameToAvatar(userLogin.objectUser.name),
            },
            comment: addExperienceComment.comment,
            isNewComment: true,
          },
        ],
        value: '',
        submitting: false,
      });
    }

    if (prevProps.comments !== this.props.comments) {
      this.setState({
        totalComments: [].concat(this.props.comments)
      })
    }

    if (prevState.newComments !== this.state.newComments) {
      this.setState({
        totalComments: [...this.state.totalComments, newComments[newComments.length - 1]]
      }, () => {
        this.scrolltoBottom();
      })
    }
  }

  scrolltoBottom = () => {
    const currentTab = this.containerRef.current;
    if (currentTab) {
      currentTab.scrollTop = currentTab.scrollHeight - currentTab.clientHeight;
    }
  }

  addContainerRef = (container) => {
    if (container) {
      this.containerRef.current = container.querySelector('.ant-list-items');
      this.scrolltoBottom();
    }
  }

  handleProgramChange = (value) => {
    const program = this.state.experienceAttendance.find(item => item.program_id === value)
    this.setState({
      program: JSON.parse(JSON.stringify(program))
    })
  }

  changeRating = (value) => {
    this.props.item.rating = value;
    this.props.changeRating(this.props.userLogin.objectUser.id, this.props.item.id, value)
  }

  /**
   * render View
   * @return {Component}
   */
  render() {
    const { onClosePopUpDetailExperiencesAndActivities, item, userLogin } = this.props;
    const { submitting, value, totalComments, experienceAttendance, program } = this.state;
    const programs = experienceAttendance.map( item => {
      return {
        id: item.program_id,
        name: item.program_name
      }
    })

    const columnsRight = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Attendees',
        dataIndex: 'attendees',
        key: 'attendees',
      },
      {
        title: '% Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
      }
    ];
    return (
      <span className="container-modal-research-plan">
        <Form id="createPlanForm">
          <Modal
            className="modal-modal-research-plan"
            bodyStyle={{ height: '50%' }}
            visible={true}
            width={'80%'}
            onCancel={onClosePopUpDetailExperiencesAndActivities}
            title={
              <div>
                {item?.name} &nbsp;
                <Rate defaultValue={item.rating} onChange={this.changeRating} />
              </div>
            }
            footer={null}
          >
            <div className="contain-modal-research-pan">
              <Tabs type="card" onChange={this.scrolltoBottom}>
                <TabPane tab="Info" key="1" >
                  <div className="container-tab-info"  >
                    <Row>
                      <Col span={8}>
                        <div className="left-container">
                          <div>
                            <span className="label-popup">Experience type: </span>
                            <span className="content-popup">{item?.type}</span>
                          </div>
                          <div style={{ marginTop: '5px' }}>
                            <span className="label-popup">Tags: </span>
                            <span className="content-popup">{item?.tags.join()}</span>
                          </div>
                          <div style={{ marginTop: '5px' }}>
                            <span className="label-popup">Supplier: </span>
                            <span className="content-popup">{item?.supplier?.name}</span>
                          </div>
                          <div style={{ marginTop: '5px' }}>
                            <span className="label-popup">Exp Cost: </span>
                            <span className="content-popup">{item?.cost_per_child} per child</span>
                          </div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="left-container">
                          <div style={{ height: 30, display: 'flow-root' }}>
                            <span style={{ fontSize: 20 }}>Key stats</span>
                            <Select
                              defaultValue={'Select Program'}
                              style={{ width: '200px', float: 'right' }}
                              onChange={this.handleProgramChange}
                            >
                              {programs?.map((item) => (
                                <Option key={item.id} value={item.id} label={item.name}>
                                  <div>{item.name}</div>
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div key={program?.program_id} className={`border-table ${program?.program_id ? '' : 'hide'}`}>
                            <div className="text-label">
                              <span className="label-popup">Last run: <span style={{fontWeight: 'bold'}}>{program.last_run}</span></span>
                            </div>
                            <div>
                              <span className="label-popup">Last category run: <span style={{fontWeight: 'bold'}}>{program.last_cat_run}</span></span>
                            </div>
                            <div>
                              <span className="label-popup"> Historic Attendance</span>
                              <div>
                                <Table rowKey={record => record.date} dataSource={program.historic_attendance} columns={columnsRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className={`left-container ${item?.image ? '' : 'hide'}`}>
                          <div style={{ height: 40, display: 'flow-root' }}>Preview: </div>
                          <div className="text-label">
                            <img src={Config.HOST_UPLOAD + item?.image} width="100px" height="100px" />
                            <div>
                              <div>
                                <span className="title-name">{item?.name}</span>
                              </div>
                              <div>
                                <span>{item?.fullybooked_desc}</span>
                              </div>
                              <div>
                                <span>{item?.flyer_desc}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Comment" key="2">
                  <div className="container-tab-info container-tab-info--comment" ref={this.addContainerRef}>
                    {totalComments.length > 0 && <CommentList comments={totalComments} currentUser={userLogin} />}
                    <Comment
                      className="text-area"
                      content={
                        <Editor
                          onChange={this.handleChange}
                          onSubmit={this.handleSubmit}
                          submitting={submitting}
                          value={value}
                        />
                      }
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        </Form>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    comments: state.experienceComment.comments,
    addExperienceComment: state.experienceComment.addComment,
    userLogin: state.userLogin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getComments: (experience_id) => dispatch(getComments(experience_id)),
    addComment: (params) => dispatch(addComment(params)),
    getExperienceAttendance: (id) => dispatch(getExperienceAttendance(id)),
    changeRating: (userId, exId, rating) => dispatch(changeRating(userId, exId, rating)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index));
