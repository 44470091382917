export const ACTION_TYPES = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
};

const initialState = {
  isLoading: false,
  objectUser: {},
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        objectUser: action.objectUser,
        isLoading: false,
      };
    case ACTION_TYPES.LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
