import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Layout, Icon, Col, Button} from 'antd';
import AdminNavbar from '../components/navbar';
import routes from '../routes.js';
import {checkAuth, logout} from '../utils/auth';

import './style.css';

const {Header} = Layout;

/**
 * @class Admin
 * @extends {Component}
 */
class Admin extends Component {
  /**
   * get routes
   * @param {routes} r
   * @return {Route} Route
   */
  getRoutes = (r) => {
    return r.map((prop, key) => {
      if (prop.layout === '/team-kids') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} handleClick={this.handleNotificationClick} />}
            key={key}
          />
        );
      }
      return null;
    });
  };

  getCurrentRoute = () => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.includes(routes[i].layout + routes[i].pathActive[0])) {
        return routes[i];
      }
    }
    return [];
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    const currentRoute = this.getCurrentRoute();
    if (currentRoute.name !== 'Login') {
      checkAuth();
    }
  }

  clearLocalStorage = () => {
    localStorage.clear();
  }

  /**
   * render Admin component
   * @return {Component}
   */
  render() {
    const {Content} = Layout;
    const currentRoute = this.getCurrentRoute();
    if (currentRoute.name === 'Login' || currentRoute.name === 'Forgot Password') {
      return (
        <Layout style={{height: '100%'}}>
          <Button
            className='button-clear-data-local'
            htmlType='button'
            onClick={this.clearLocalStorage}
          >
            Clear data local storage
          </Button>
          <Content
            style={{
              padding: 5,
              background: '#fff',
              minHeight: 280,
              textAlign: 'center',
            }}
          >
            <div style={{margin: '0 auto', background: '#ffffff', padding: 5, minHeight: 280, width: '25%'}}>
              <div>
                <Switch currentRoute={currentRoute}>{this.getRoutes(routes)}</Switch>
                <div id="loading" className="hidden">
                  <div id="bg-loading" />
                  <div id="icon-loading">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      );
    } else if (currentRoute.name === 'Add Plan'
      || currentRoute.name === 'Add Short List'
      || currentRoute.name === 'Edit Plan') {
      return (
        <Switch currentRoute={currentRoute}>{this.getRoutes(routes)}</Switch>
      );
    }
    return (
      <Layout>
        <Header style={{paddingLeft: 18, background: '#ffffff', borderBottom: '1px solid #dddddd'}}>
          <Col span={8}>
            <div className="image-label">
              <img style={{height: '50px'}} src={require('../assets/TeamKids-Logo_Orange_CMYK-1.png')} alt="logo"/>
            </div>
          </Col>
          <Col span={8}></Col>
          <Col xs={{span: 5, offset: 1}} lg={{span: 6, offset: 2}}>
            <div className="info-content">
              <Icon className="icon-logout"
                onClick={logout}
                type="logout"
              />
            </div>
          </Col>
        </Header>
        <Layout>
          <Layout>
            <Content
              style={{
                background: '#fff',
                minHeight: 280,
              }}
            >
              <div style={{background: '#fff', padding: 5, minHeight: 280}}>
                <div>
                  <Switch currentRoute={currentRoute}>{this.getRoutes(routes)}</Switch>
                  <div id="loading" className="hidden">
                    <div id="bg-loading" />
                    <div id="icon-loading">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
          <AdminNavbar {...this.props} currentRoute={currentRoute} routes={routes}/>
        </Layout>
      </Layout>
    );
  }
}

export default Admin;
