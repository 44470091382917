import { notification } from 'antd';
import { getListExperiencesApi, updateExperiencesApi, uploadImageApi } from '../../services/experiencesServices';
import { ACTION_TYPES } from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE, PAGE_SIZE_SHORT_LIST } from '../../common/constant';

const openNotificationWithIcon = (description, title, type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetExperiencesAction = {
  type: ACTION_TYPES.GET_EXPERIENCES,
};

export const failGetGetExperiencesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPERIENCES_FAIL,
    error: data,
  };
};

export const successGetGetExperiencesAction = (data, totalPage) => {
  return {
    type: ACTION_TYPES.GET_EXPERIENCES_SUCCESS,
    listExperiences: data,
    totalPage: totalPage,
  };
};

const notifyFail = (funcFail, dispatch) => {
  dispatch(funcFail);
};

export const getExperiences = (pageIndex, request) => {
  return (dispatch) => {
    dispatch(requestGetExperiencesAction);
    let params = `?query={ experiences(page_size: ${PAGE_SIZE_SHORT_LIST}, page_index:${pageIndex}){total_item items{id name capacity rating created_date modified_date cost_per_child fullybooked_desc flyer_desc type supplier{id name} zone{name state} tags image}}}`;
    if (request) {
      params = `?query={ experiences(page_size: ${PAGE_SIZE_SHORT_LIST}, page_index:${pageIndex},  ${request}){total_item items{id name capacity rating created_date modified_date cost_per_child fullybooked_desc flyer_desc type supplier{id name} zone{name state} tags image}}}`;
    }
    return getListExperiencesApi(params).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successGetGetExperiencesAction(data.experiences.items, data.experiences.total_item));
          return data;
        } else {
          notifyFail(failGetGetExperiencesAction('Get Experiences Fail'), dispatch);
        }
      },
      () => notifyFail(failGetGetExperiencesAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};

export const failUpdateExperiencesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPERIENCES_FAIL,
    error: data,
  };
};

const requestUpdateExperiencesAction = {
  type: ACTION_TYPES.GET_EXPERIENCES,
};

export const successUpdateExperiencesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_EXPERIENCES_SUCCESS,
    updateExperiences: data,
  };
};

export const updateExperiences = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateExperiencesAction);
    const tags = params?.tags.map((item) => {
      return `"${item}"`;
    });
    let body = {
      query: `mutation{updateExperience(id: ${params.id}, type: ${params.type}, name: "${params.name}", tags: [${tags}], capacity: ${params.capacity}, rating: ${params.rating}, 
        cost_per_child: ${params.cost_per_child}, fullybooked_desc: "${params.fullybooked_desc}", flyer_desc: "${params.flyer_desc}") 
        {id, name, capacity, rating, created_date, modified_date, cost_per_child, fullybooked_desc, flyer_desc, type, tags}}`,
    };
    if (tags.length === 0) {
      body = {
        query: `mutation{updateExperience(id: ${params.id}, type: ${params.type}, name: "${params.name}", tags: [], capacity: ${params.capacity}, rating: ${params.rating}, 
        cost_per_child: ${params.cost_per_child}, fullybooked_desc: "${params.fullybooked_desc}", flyer_desc: "${params.flyer_desc}") 
        {id, name, capacity, rating, created_date, modified_date, cost_per_child, fullybooked_desc, flyer_desc, type, tags}}`,
      };
    }
    updateExperiencesApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          dispatch(successUpdateExperiencesAction(data?.updateExperience));
        } else {
          openNotificationWithIcon('Update Experience Fail', 'Notification', ALERT_TYPE.ERROR);
          dispatch(failUpdateExperiencesAction('Call api update fail', dispatch));
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUpdateExperiencesAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

const requestUploadImageAction = {
  type: ACTION_TYPES.UPLOAD_IMAGE,
};

export const failUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_FAIL,
  };
};

export const successUploadImageAction = (data) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE_SUCCESS,
    experienceUploadInline: data,
  };
};

export const removeImage = (params) => {
  const tags = params?.tags.map((item) => {
    return `"${item}"`;
  });
  return (dispatch) => {
    dispatch(requestUpdateExperiencesAction);
    let body = {
      query: `mutation{updateExperience(id: ${params.id}, image: null, tags: [${tags}]) {id, name, capacity, rating, created_date, modified_date, cost_per_child, fullybooked_desc, flyer_desc, type, tags}}`,
    };
    if (tags.length === 0) {
      body = {
        query: `mutation{updateExperience(id: ${
          params.id
        }, image: null, tags: "${null}") {id, name, capacity, rating, created_date, modified_date, cost_per_child, fullybooked_desc, flyer_desc, type, tags}}`,
      };
    }
    updateExperiencesApi(body).then(
      (dataJson) => {
        if (dataJson && dataJson.success) {
          dispatch(successUpdateExperiencesAction(dataJson.data?.updateExperience));
        } else {
          openNotificationWithIcon('Update Experience Fail', 'Notification', ALERT_TYPE.ERROR);
          dispatch(failUpdateExperiencesAction('Call api update fail', dispatch));
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUpdateExperiencesAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};

export const uploadImage = (file, record) => {
  return (dispatch) => {
    dispatch(requestUploadImageAction);
    const body = new FormData();
    body.append(
      'operations',
      `{"query": "mutation UploadFile($image_file: Upload!){experienceUploadInline(image_file: $image_file, id: ${record.id}) { id, name, image}}"}`
    );
    body.append('map', `{"0": ["variables.image_file"]}`);
    body.append('0', file.originFileObj);
    uploadImageApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson.success) {
          dispatch(successUploadImageAction(data.experienceUploadInline));
        } else {
          openNotificationWithIcon('Fail to upload file', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failUploadImageAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};
