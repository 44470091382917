import Config from '../common/config';
import {api} from './api';

const getListActivitiesResourcesUrl = `${Config.HOST}`;

export const getListActivitiesResourcesApi = (activityId, pageSize, pageIndex) => {
  return api.get(getListActivitiesResourcesUrl + `?query={ activity_resources(activity_id: ${activityId ? "[" + activityId.join() + "]" : ""},page_size: ${pageSize}, page_index: ${pageIndex}){id activity {id name} resource_name resource_per_child supplier {id name} cost_per_child notes created_date}}`);
}

export const updateActivitiesResourcesApi = (params) => {
  return api.post(getListActivitiesResourcesUrl, params)
};

export const getActivitiesAttendanceApi = (params) => {
  return api.get(getListActivitiesResourcesUrl + params);
}

