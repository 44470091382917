import _ from 'lodash';
export const ACTION_TYPES = {
  GET_PROGRAMS: 'GET_PROGRAMS',
  GET_PROGRAMS_SUCCESS: 'GET_PROGRAMS_SUCCESS',
  GET_PROGRAMS_FAIL: 'GET_PROGRAMS_FAIL',

  UPDATE_PROGRAMS: 'UPDATE_PROGRAMS',
  UPDATE_PROGRAMS_SUCCESS: 'UPDATE_PROGRAMS_SUCCESS',
  UPDATE_PROGRAMS_FAIL: 'UPDATE_PROGRAMS_FAIL',

  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',
};

const initialState = {
  isLoading: false,
  listPrograms: []
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_PROGRAMS:
    case ACTION_TYPES.UPDATE_PROGRAMS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPLOAD_IMAGE:
    case ACTION_TYPES.GET_PROGRAMS_FAIL:
    case ACTION_TYPES.UPLOAD_IMAGE_FAIL:
    case ACTION_TYPES.UPDATE_PROGRAMS_FAIL:
    case ACTION_TYPES.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPrograms: action.listPrograms
      };
    case ACTION_TYPES.UPDATE_PROGRAMS_SUCCESS:
      const list = _.forEach(state.listPrograms, (item) => {
        if (item.id === action.updateProgram.id) {
          item.name = action.updateProgram.name;
          item.capality = action.updateProgram.capality;
          item.address = action.updateProgram.address;
          item.phone = action.updateProgram.phone;
          item.contract_start_date = action.updateProgram.contract_start_date;
          item.operation_times = action.updateProgram.operation_times;
          item.comment = action.updateProgram.comment;
        }
      });
      return {
        ...state,
        isLoading: false,
        listPrograms: list,
      };
    default:
      return state;
  }
}
