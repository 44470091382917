import { notification } from 'antd';
import {
  getListActivitiesResourcesApi,
  updateActivitiesResourcesApi,
  getActivitiesAttendanceApi
} from '../../services/activitiesResourcesServices';
import {ACTION_TYPES} from './reducer';
import { INTERNAL_SERVER_ERROR, ALERT_TYPE } from '../../common/constant';

const openNotificationWithIcon = (description, title , type) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const requestGetActivitiesResourcesAction = {
  type: ACTION_TYPES.GET_ACTIVITIES_RESOURCES,
};

export const failGetActivitiesResourcesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_RESOURCES_FAIL,
    error: data,
  };
};

export const successGetActivitiesResourcesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_RESOURCES_SUCCESS,
    listActivitiesResources: data,
  };
};

const notifyFail = (funcFail, dispatch) => {
  dispatch(funcFail);
};

export const getActivitiesResources = (activityId, pageSize, pageIndex) => {
  return (dispatch) => {
    dispatch(requestGetActivitiesResourcesAction);
    return getListActivitiesResourcesApi(activityId, pageSize, pageIndex).then(
        (dataJson) => {
          const data = dataJson.data;
          if(dataJson && dataJson.success) {
            dispatch(successGetActivitiesResourcesAction(data.activity_resources));
            return data.activity_resources;
          } else {
            notifyFail(failGetActivitiesResourcesAction('Get Activities Resources Fail'), dispatch);
          }
        },
        () => notifyFail(failGetActivitiesResourcesAction(INTERNAL_SERVER_ERROR), dispatch)
    );
  };
};


export const failUpdateActivitiesResourcesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES_FAIL,
    error: data,
  };
};

const requestUpdateActivitiesResourcesAction = {
  type: ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES,
};

export const successUpdateActivitiesResourcesAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITIES_RESOURCES_SUCCESS,
    updateActivityResources: data
  };
};

export const updateActivitiesResources = (params) => {
  return (dispatch) => {
    dispatch(requestUpdateActivitiesResourcesAction);    
    const body = {query: `mutation{updateActivityResource(id: ${params.id}, resource_per_child: "${params.resource_per_child}", notes: "${params.notes}" ) {id, resource_per_child, notes, created_date, modified_date, activity{id name}}}`};
    updateActivitiesResourcesApi(body).then(
        (dataJson) => {
          const data = dataJson.data;
          if (dataJson && dataJson.success) {
            dispatch(successUpdateActivitiesResourcesAction(data?.updateActivityResource));
          } else {
            openNotificationWithIcon('Update ActivityResource Fail', 'Notification', ALERT_TYPE.ERROR);
            dispatch(failUpdateActivitiesResourcesAction('Call api update fail', dispatch));
          }
        },
        () => {
          openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failUpdateActivitiesResourcesAction(INTERNAL_SERVER_ERROR), dispatch)
        }
    );
  };
};

const requestGetActivitiesAttendance = {
  type: ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE,
};

export const failGetActivitiesAttendanceAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE_FAIL,
  };
};

export const successGetActivitiesAttendanceAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ACTIVITIES_ATTENDANCE_SUCCESS,
    items: data,
  };
};

export const getActivityAttendance = (acIt, proId)=> {
  return (dispatch) => {
    dispatch(requestGetActivitiesAttendance);
    let body = `?query={ activity_attendance(activity_id: ${acIt})}`;
    if (proId) {
      body = `?query={ activity_attendance(activity_id: ${acIt}, program_id: ${proId})}`;
    }
    return getActivitiesAttendanceApi(body).then(
      (dataJson) => {
        const data = dataJson.data;
        if (dataJson && dataJson.success) {
          const items = data?.activity_attendance?.items;
          dispatch(successGetActivitiesAttendanceAction(items));
          return items;
        } else {
          openNotificationWithIcon('Get activities attendance Fail', 'Notification', ALERT_TYPE.ERROR);
          notifyFail(failGetActivitiesAttendanceAction('Get activities attendance Fail'), dispatch);
        }
      },
      () => {
        openNotificationWithIcon('Internal Server Error', 'Notification', ALERT_TYPE.ERROR);
        notifyFail(failGetActivitiesAttendanceAction(INTERNAL_SERVER_ERROR), dispatch);
      }
    );
  };
};
