import _ from 'lodash';

export const ACTION_TYPES = {
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAIL: 'GET_SETTINGS_FAIL',

  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_SETTINGS_FAIL: 'UPDATE_SETTINGS_FAIL',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
};

const initialState = {
  isLoading: false,
  listSettings: [],
};

/**
 * function reduce
 * @param {state} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_SETTINGS:
    case ACTION_TYPES.GET_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPDATE_SETTINGS_FAIL:
    case ACTION_TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listSettings: action.listSettings,
      };
    case ACTION_TYPES.UPDATE_SETTINGS_SUCCESS:
      const list = _.forEach(state.listSettings, (item) => {
        if (item.id === action.updateSettings.id) {
          item.keyword = action.updateSettings.keyword;
          item.content = action.updateSettings.content;
        }
      });
      return {
        ...state,
        isLoading: false,
        listSettings: list,
      };
    default:
      return state;
  }
}
